import { Dispatch } from 'redux';
import { TListMode } from 'components/Catalog/store/productListModeSelector';
import { dispatchType } from 'components/Catalog/store/productListModeReducer';

export function initProductListMode(payload: { listMode: TListMode }) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: dispatchType.LIST_MODE_INIT,
            payload,
        });
    };
}

export function setListMode(payload: { listMode: TListMode }) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: dispatchType.LIST_MODE_SET,
            payload,
        });
    };
}
