import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormKey from 'components/GeneralForm/Field/FormKey';
import FormList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormList/index';
import FormListItem from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormListItem/index';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/CheckboxList';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
            formParams: {
                firstname?: string;
                lastname?: string;
                personal_id?: string;
                email?: string;
                company_name?: string;
                reg_no?: string;
                account_number?: string;
                vet_licence_code?: string;
                phone?: string;
                address?: string;
                extra?: string;
                agreed_terms?: boolean;
            };
            isPersonalIdEnabled: boolean;
        };
        labels: {
            firstname: string;
            lastname: string;
            email: string;
            idCode: string;
            vetLicenceCode: string;
            role: string;
            veterinary: string;
            user: string;
            formSubmitLabel: string;
            invalidEmail: string;
            requiredField: string;
            accountNumber: string;
            regNo: string;
            companyName: string;
            phone: string;
            address: string;
            extra: string;
            agreedTermsLabel: string;
        };
    };
}

const B2BClientAccountFormRegister: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { formConfig, labels } = config;

    const formElement = useRef<HTMLFormElement>(null);

    const validationSchema = {
        firstname: Yup.string().required(labels.requiredField),
        lastname: Yup.string().required(labels.requiredField),
        email: Yup.string().email(config.labels.invalidEmail).required(config.labels.requiredField),
        company_name: Yup.string(),
        reg_no: Yup.string().required(labels.requiredField),
        account_number: Yup.string(),
        vet_licence_code: Yup.string().required(labels.requiredField),
        phone: Yup.string(),
        address: Yup.string().required(labels.requiredField),
        agreed_terms: Yup.boolean().nullable().oneOf([true], labels.requiredField),
        extra: Yup.string(),
    };

    if (formConfig.isPersonalIdEnabled) {
        Object.assign(validationSchema, { personal_id: Yup.string().required(labels.requiredField) });
    }

    const formDataValidationSchema = Yup.object().shape(validationSchema);

    const formik = useFormik({
        initialValues: {
            firstname: formConfig.formParams?.firstname ?? '',
            lastname: formConfig.formParams?.lastname ?? '',
            personal_id: formConfig.formParams?.personal_id ?? '',
            email: formConfig.formParams?.email ?? '',
            company_name: formConfig.formParams?.company_name ?? '',
            reg_no: formConfig.formParams?.reg_no ?? '',
            account_number: formConfig.formParams?.account_number ?? '',
            vet_licence_code: formConfig.formParams?.vet_licence_code ?? '',
            phone: formConfig.formParams?.phone ?? '',
            address: formConfig.formParams?.address ?? '',
            extra: formConfig.formParams?.extra ?? '',
            agreed_terms: null,
        },
        validationSchema: formDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    return (
        <form
            ref={formElement}
            action={formConfig.actionUrl}
            method="post"
            className="margt"
            onSubmit={formik.handleSubmit}
        >
            <FormKey />

            <FormList size="wide">
                <FormListItem label={labels.firstname} required={true} error={formik.errors.firstname}>
                    <ControlInput name="firstname" value={formik.values.firstname} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.lastname} required={true} error={formik.errors.lastname}>
                    <ControlInput name="lastname" value={formik.values.lastname} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.email} required={true} error={formik.errors.email}>
                    <ControlInput name="email" value={formik.values.email} onChange={formik.handleChange} />
                </FormListItem>
                {formConfig.isPersonalIdEnabled && (
                    <FormListItem label={labels.idCode} required={true} error={formik.errors.personal_id}>
                        <ControlInput
                            name="personal_id"
                            value={formik.values.personal_id}
                            onChange={formik.handleChange}
                        />
                    </FormListItem>
                )}
                <FormListItem label={labels.companyName} required={false} error={formik.errors.company_name}>
                    <ControlInput
                        name="company_name"
                        value={formik.values.company_name}
                        onChange={formik.handleChange}
                    />
                </FormListItem>
                <FormListItem label={labels.regNo} required={true} error={formik.errors.reg_no}>
                    <ControlInput name="reg_no" value={formik.values.reg_no} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.accountNumber} required={false} error={formik.errors.account_number}>
                    <ControlInput
                        name="account_number"
                        value={formik.values.account_number}
                        onChange={formik.handleChange}
                    />
                </FormListItem>
                <FormListItem label={labels.vetLicenceCode} required={true} error={formik.errors.vet_licence_code}>
                    <ControlInput
                        name="vet_licence_code"
                        value={formik.values.vet_licence_code ?? ''}
                        onChange={formik.handleChange}
                    />
                </FormListItem>
                <FormListItem label={labels.phone} required={false} error={formik.errors.phone}>
                    <div className="form-control-phone">
                        <ControlInput name={'phone'} value={formik.values.phone} onChange={formik.handleChange} />
                    </div>
                </FormListItem>
                <FormListItem label={labels.address} required={true} error={formik.errors.address}>
                    <ControlInput name="address" value={formik.values.address} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.extra} required={false} error={formik.errors.extra}>
                    <ControlInput name="extra" value={formik.values.extra} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem className="separated" error={formik.errors.agreed_terms}>
                    <CheckboxWithLabel
                        name={'agreed_terms'}
                        label={<RenderHTML html={config.labels.agreedTermsLabel} nowrapper={true} />}
                        checked={formik.values.agreed_terms ?? false}
                        onChange={formik.handleChange}
                    />
                </FormListItem>
            </FormList>
            <Buttons layout="vertical-wide">
                <Button type="submit" intent="primary" title={labels.formSubmitLabel} />
            </Buttons>
        </form>
    );
};

export default B2BClientAccountFormRegister;
