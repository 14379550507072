import React from 'react';
import Product from 'components/CartView/components/Product';
import Img from 'components/Image/Img';
import { ICartProduct } from 'components/CartView/interfaces/CartInterfaces';
import { ICartLabels } from 'components/CartView/CartView';

interface IProps {
    products: ICartProduct[];
    title: string;
    icon: string;
    labels: ICartLabels;
}

const CartProducts: React.FunctionComponent<IProps> = (props) => {
    const { products, title, icon, labels } = props;

    return (
        <div className="cart-products">
            <div className="cart-products__title">
                <h2>
                    <Img iconName={icon} alt={title} />
                    {title}
                </h2>
            </div>
            {products.map((product, i) => (
                <Product key={`${product.sku}-${i}`} product={product} labels={labels} />
            ))}
        </div>
    );
};

export default CartProducts;
