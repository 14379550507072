export default {
    overlays: {
        scrollbarWidth: 0,
        openOverlays: [],
        savedScrollPosition: 0,
    },
    pages: {
        loadedData: null,
        isFetching: false,
        isError404: false,
    },
    session: {
        loadedData: {
            userID: null,
            token: null,
            active: false,
            usedAuthMethod: null,
        },
        endReached: false,
        isFetching: false,
        isAuthenticating: false,
        error: null,
    },
};
