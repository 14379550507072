import { request } from 'data/requests/request';
import IProduct from 'components/Catalog/interfaces/IProduct';

const compareListRequest = () =>
    request({
        type: 'productCompareList',
        url: 'catalog/ajax/comparelist',
        method: 'GET',
        notApi: true,
        absolute: false,
    });

export default compareListRequest;

export interface ICompareListResponse {
    products: IProduct[];
}
