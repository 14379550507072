import React, { useRef, useState } from 'react';
import FormList from 'ekaubamaja-ui/lib/Components/FormList';
import FormListItem from 'ekaubamaja-ui/lib/Components/FormListItem';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import authenticateRequest, { IAuthenticateResponse } from 'components/SmartId/requests/AuthenticateRequest';
import { useMutation } from 'redux-query-react';
import authenticateStartRequest, {
    IAuthenticateStartResponse,
} from 'components/SmartId/requests/AuthenticateStartRequest';
import MessageManager, { IMessage } from 'components/ui/MessageManager';

export interface ISmartIdConfig {
    isEnabled: boolean;
    labels: {
        personalCode: string;
        signIn: string;
        verificationCode: string;
        close: string;
        personalCodeIsRequired: string;
        somethingWentWrong: string;
    };
}

interface IProps {
    config: ISmartIdConfig;
}

const SmartId: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { labels } = config;
    const [isPolling, setIsPolling] = useState(false);
    const [verificationCode, setVerificationCode] = useState<string>();
    const [messages, setMessages] = useState<IMessage[]>([]);
    const sessionIdRef = useRef<string>('');
    const [{}, smartIdAuthenticateStart] = useMutation(() => authenticateStartRequest());
    const [{}, smartIdAuthenticate] = useMutation((personalCode: string, sessionId?: string) =>
        authenticateRequest(personalCode, sessionId),
    );
    const [personalCode, setPersonalCode] = useState('');

    let pollTries = 0;

    const validate = (): boolean => {
        if (personalCode.length === 0) {
            setMessages([
                {
                    intent: 'danger',
                    closable: true,
                    content: labels.personalCodeIsRequired,
                },
            ]);
            return false;
        }

        return true;
    };

    const onSubmit = (): void => {
        if (!validate()) {
            return;
        }

        setIsPolling(true);
        setMessages([]);

        void smartIdAuthenticateStart().then((startResponse) => {
            setVerificationCode((startResponse.body as IAuthenticateStartResponse).verificationCode);
            poll();
        });
    };

    const poll = () => {
        void smartIdAuthenticate(personalCode, sessionIdRef.current)
            .then((authResponse) => {
                const response = authResponse.body as IAuthenticateResponse;

                if (response.status === 1) {
                    window.location.href = '/smartid/login';
                } else if (response.status === 2) {
                    sessionIdRef.current = response.sessionId;
                    setTimeout(poll, 5000);
                } else {
                    setIsPolling(false);
                    setVerificationCode(undefined);
                    setMessages([
                        {
                            intent: 'danger',
                            closable: true,
                            content: response.message,
                        },
                    ]);
                }
            })
            .catch(() => {
                pollTries++;
                if (pollTries < 5) {
                    setTimeout(poll, 5000);
                }
            });
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
            }}
        >
            {verificationCode && (
                <div className="notification-mobileid" role="alert">
                    {labels.verificationCode}: <strong>{verificationCode}</strong>
                </div>
            )}
            <FormList>
                <FormListItem label={labels.personalCode} required>
                    <ControlInput
                        name="personal_code"
                        value={personalCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPersonalCode(e.target.value)}
                    />
                </FormListItem>
            </FormList>
            <MessageManager messages={messages} labels={{ close: labels.close }} />
            <Buttons>
                <Button type="submit" intent="primary" title={labels.signIn} disabled={isPolling} />
            </Buttons>
        </form>
    );
};

export default SmartId;
