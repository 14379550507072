import React, { useState } from 'react';
import FilterDrop from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FilterDrop';
import RadioWithLabel from 'ekaubamaja-ui/lib/Components/RadioWithLabel';
import Img from 'components/Image/Img';

interface IProps {
    currentOrder: string;
    currentDirection: string;
    orders: IOrderItem[];
    onChange: (attributeCode: string, direction: 'asc' | 'desc') => void;
    labels: {
        orderBy: string;
    };
}

export interface IOrderItem {
    attributeCode: string;
    label: string;
    direction: 'asc' | 'desc';
    isCurrent: boolean;
}
const Sorter = (props: IProps) => {
    const { orders, onChange, labels } = props;
    const [currentOrder, setCurrentOrder] = useState(props.currentOrder);
    const [currentDirection, setCurrentDirection] = useState(props.currentDirection);

    return (
        <FilterDrop
            popoverClassName="product-filter-drop"
            arrow={<Img iconName="icon_arrow_down" />}
            toggler={labels.orderBy}
            isFieldSet={true}
            isList={true}
        >
            {orders.map((order) => (
                <RadioWithLabel
                    key={order.attributeCode}
                    label={order.label}
                    checked={order.attributeCode === currentOrder && order.direction === currentDirection}
                    onChange={() => {
                        setCurrentOrder(order.attributeCode);
                        setCurrentDirection(order.direction);
                        onChange(order.attributeCode, order.direction);
                    }}
                />
            ))}
        </FilterDrop>
    );
};

export default Sorter;
