import { cloneDeep, includes, pull } from 'lodash';

import initialState from 'data/reduxState';
import { OverlaysActions } from 'data/overlays/overlayHandler';
import { OverlaysState } from 'data/overlays/interfaces';
import { dispatchType } from 'data/overlays/actionTypes';
import { IStoreState } from '../../helpers/rootReducer';

const overlaysReducer = (
    state: IStoreState['overlaysReducer'] = cloneDeep(initialState.overlays),
    action: OverlaysActions,
): IStoreState['overlaysReducer'] => {
    const handleResize = () => {};

    const closeOverlayCleanup = (instant?: boolean, noScrollRestore?: boolean) => {
        document.documentElement.style.height = '';

        const roots = document.getElementsByClassName('scrollroot');
        Array.from(roots).map((root, index) => {
            if (root instanceof HTMLElement) {
                root.style.height = '';
                root.style.minHeight = '';
            }
        });

        document.documentElement.classList.remove('overlayopen');

        if (!noScrollRestore && state.savedScrollPosition > 0) {
            document.body.scrollTop = state.savedScrollPosition;
            document.documentElement.scrollTop = state.savedScrollPosition;
            window.requestAnimationFrame(() => {
                document.body.scrollTop = state.savedScrollPosition;
                document.documentElement.scrollTop = state.savedScrollPosition;
            });
        }

        if (instant) {
            window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:close'));
        } else {
            setTimeout(() => {
                window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:close'));
            }, 250);
        }
    };

    const handleOpen = (): OverlaysState => {
        if (includes(state.openOverlays, action.payload.name)) {
            return handleClose();
        } else {
            const scrollers = document.getElementsByClassName('scrollroot');

            const pos = document.documentElement.scrollTop || document.body.scrollTop;
            window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:open'));

            handleResize();

            if (state.openOverlays.length === 0) {
                // Scroll to top
                window.scrollTo(0, 0);

                // Clip window to size
                document.documentElement.classList.add('overlayopen');

                window.requestAnimationFrame(() => {
                    Array.from(scrollers).map((scroller, index) => {
                        if (scroller instanceof HTMLElement) {
                            scroller.scrollTop = pos;
                        }
                    });
                });
            }
            let arr: string[] = [];
            if (action.payload.asNew) {
                arr = [...state.openOverlays, action.payload.name];
            } else {
                arr = [action.payload.name];
            }

            let newScrollWidth = window.innerWidth - document.documentElement.clientWidth;
            if (state.scrollbarWidth && state.scrollbarWidth > newScrollWidth) {
                newScrollWidth = state.scrollbarWidth;
            }
            window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:sync'));

            return {
                ...state,
                scrollbarWidth: newScrollWidth,
                savedScrollPosition: pos,
                openOverlays: arr,
            };
        }

        return {
            ...state,
        };
    };

    const handleClose = (): OverlaysState => {
        let arr = [...state.openOverlays];
        if (arr.length > 0) {
            if (action.payload.name === 'all') {
                arr = [];
            } else if (action.payload.name) {
                pull(arr, action.payload.name);
            } else {
                arr.splice(-1, 1);
            }
            window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:sync'));
            if (document.documentElement && arr.length === 0) {
                closeOverlayCleanup(false, action.payload.noScrollRestore);
            }
        } else if (action.payload.name === 'all') {
            closeOverlayCleanup(action.payload.instant, true);
        }

        let newScrollbarWidth = state.scrollbarWidth;

        if (!document.documentElement.classList.contains('overlayopen')) {
            newScrollbarWidth = 0;
            window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:sync'));
        }

        return {
            ...state,
            scrollbarWidth: newScrollbarWidth,
            openOverlays: arr,
        };
    };

    switch (action.type) {
        case dispatchType.OVERLAYS_OPEN: {
            return handleOpen();
        }
        case dispatchType.OVERLAYS_CLOSE: {
            return handleClose();
        }
        default: {
            return state;
        }
    }
};

export default overlaysReducer;
