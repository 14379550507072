import React, { useCallback, useLayoutEffect } from 'react';
import ButtonIcon from 'ekaubamaja-ui/lib/Components/ButtonIcon';
import { productListModeSelector, TListMode } from 'components/Catalog/store/productListModeSelector';
import { useDispatch, useSelector } from 'react-redux';
import { setListMode } from 'components/Catalog/store/productListModeHandler';

interface IProps {
    defaultListMode: TListMode;
    labels: {
        gridView: string;
        listView: string;
    };
}

const ListMode: React.FunctionComponent<IProps> = (props) => {
    const { defaultListMode, labels } = props;
    const { listMode } = useSelector(productListModeSelector);
    const dispatch = useDispatch();

    const setCurrentListMode = useCallback((listMode: TListMode) => dispatch(setListMode({ listMode })), [listMode]);

    useLayoutEffect(() => {
        setCurrentListMode(defaultListMode);
    }, []);

    return (
        <div className="product-filters__view" role="group">
            <ButtonIcon
                icon="grid"
                intent={listMode === TListMode.GRID ? 'primary' : 'secondary'}
                size="medium"
                ariaConfig={{ label: labels.gridView }}
                onClick={() => setCurrentListMode(TListMode.GRID)}
            />
            <ButtonIcon
                icon="list"
                intent={listMode === TListMode.LIST ? 'primary' : 'secondary'}
                size="medium"
                ariaConfig={{ label: labels.listView }}
                onClick={() => setCurrentListMode(TListMode.LIST)}
            />
        </div>
    );
};

export default ListMode;
