import React, { useState } from 'react';
import Login from 'components/Customer/Form/Login';
import ClientSelection from 'components/Customer/Overlay/ClientSelection';
import { IAuthenticationPopupConfig } from 'components/Customer/Auth/AuthenticationPopup';

interface IProps {
    config: IAuthenticationPopupConfig;
}

const CustomerLoginWrapper: React.FunctionComponent<IProps> = (props: IProps) => {
    const { config } = props;

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    return (
        <>
            {isLoggedIn && <ClientSelection isStandalone />}
            <Login config={config} onLogin={() => setIsLoggedIn(true)} />
        </>
    );
};

export default CustomerLoginWrapper;
