import * as React from 'react';
import FrameMain from 'theme/Frames/Main';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

const MainFrameWrapper: React.FunctionComponent = () => {
    useEffect(() => {
        if (Cookies.get('form_key')) {
            Cookies.remove('form_key');
        }
    }, []);

    return <FrameMain />;
};

export default MainFrameWrapper;
