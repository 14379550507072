/* eslint-disable @typescript-eslint/no-unused-vars */
const HTTP_STATUS_CODE_CONTINUE = 100;
const HTTP_STATUS_CODE_SWITCHING_PROTOCOLS = 101;
const HTTP_STATUS_CODE_PROCESSING = 102;
const HTTP_STATUS_CODE_OK = 200;
const HTTP_STATUS_CODE_CREATED = 201;
const HTTP_STATUS_CODE_ACCEPTED = 202;
const HTTP_STATUS_CODE_NON_AUTHORITATIVE_INFORMATION = 203;
const HTTP_STATUS_CODE_NO_CONTENT = 204;
const HTTP_STATUS_CODE_RESET_CONTENT = 205;
const HTTP_STATUS_CODE_PARTIAL_CONTENT = 206;
const HTTP_STATUS_CODE_MULTI_STATUS = 207;
const HTTP_STATUS_CODE_ALREADY_REPORTED = 208;
const HTTP_STATUS_CODE_IM_USED = 226;
const HTTP_STATUS_CODE_MULTIPLE_CHOICES = 300;
const HTTP_STATUS_CODE_MOVED_PERMANENTLY = 301;
const HTTP_STATUS_CODE_FOUND = 302;
const HTTP_STATUS_CODE_SEE_OTHER = 303;
const HTTP_STATUS_CODE_NOT_MODIFIED = 304;
const HTTP_STATUS_CODE_USE_PROXY = 305;
const HTTP_STATUS_CODE_SWITCH_PROXY = 306;
const HTTP_STATUS_CODE_TEMPORARY_REDIRECT = 307;
const HTTP_STATUS_CODE_PERMANENT_REDIRECT = 308;
const HTTP_STATUS_CODE_BAD_REQUEST = 400;
const HTTP_STATUS_CODE_UNAUTHORIZED = 401;
const HTTP_STATUS_CODE_PAYMENT_REQUIRED = 402;
const HTTP_STATUS_CODE_FORBIDDEN = 403;
const HTTP_STATUS_CODE_NOT_FOUND = 404;
const HTTP_STATUS_CODE_METHOD_NOT_ALLOWED = 405;
const HTTP_STATUS_CODE_NOT_ACCEPTABLE = 406;
const HTTP_STATUS_CODE_PROXY_AUTHENTICATION_REQUIRED = 407;
const HTTP_STATUS_CODE_REQUEST_TIMEOUT = 408;
const HTTP_STATUS_CODE_CONFLICT = 409;
const HTTP_STATUS_CODE_GONE = 410;
const HTTP_STATUS_CODE_LENGTH_REQUIRED = 411;
const HTTP_STATUS_CODE_PRECONDITION_FAILED = 412;
const HTTP_STATUS_CODE_PAYLOAD_TOO_LARGE = 413;
const HTTP_STATUS_CODE_URI_TOO_LONG = 414;
const HTTP_STATUS_CODE_UNSUPPORTED_MEDIA_TYPE = 415;
const HTTP_STATUS_CODE_RANGE_NOT_SATISFIABLE = 416;
const HTTP_STATUS_CODE_EXPECTATION_FAILED = 417;
const HTTP_STATUS_CODE_I_AM_A_TEAPOT = 418;
const HTTP_STATUS_CODE_MISDIRECTED_REQUEST = 421;
const HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY = 422;
const HTTP_STATUS_CODE_LOCKED = 423;
const HTTP_STATUS_CODE_FAILED_DEPENDENCY = 424;
const HTTP_STATUS_CODE_UPGRADE_REQUIRED = 426;
const HTTP_STATUS_CODE_PRECONDITION_REQUIRED = 428;
const HTTP_STATUS_CODE_TOO_MANY_REQUESTS = 429;
const HTTP_STATUS_CODE_REQUEST_HEADER_FIELDS_TOO_LARGE = 431;
const HTTP_STATUS_CODE_UNAVAILABLE_FOR_LEGAL_REASONS = 451;
const HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR = 500;
const HTTP_STATUS_CODE_NOT_IMPLEMENTED = 501;
const HTTP_STATUS_CODE_BAD_GATEWAY = 502;
const HTTP_STATUS_CODE_SERVICE_UNAVAILABLE = 503;
const HTTP_STATUS_CODE_GATEWAY_TIMEOUT = 504;
const HTTP_STATUS_CODE_HTTP_VERSION_NOT_SUPPORTED = 505;
const HTTP_STATUS_CODE_VARIANT_ALSO_NEGOTIATES = 506;
const HTTP_STATUS_CODE_INSUFFICIENT_STORAGE = 507;
const HTTP_STATUS_CODE_LOOP_DETECTED = 508;
const HTTP_STATUS_CODE_NOT_EXTENDED = 510;
const HTTP_STATUS_CODE_NETWORK_AUTHENTICATION_REQUIRED = 511;

// noinspection JSUnusedGlobalSymbols
export enum HttpStatusCode {
    /**
     * The server has received the request headers and the client should proceed to send the request body
     * (in the case of a request for which a body needs to be sent; for example, a POST request).
     * Sending a large request body to a server after a request has been rejected for inappropriate headers would be inefficient.
     * To have a server check the request's headers, a client must send Expect: 100-continue as a header in its initial request
     * and receive a 100 Continue status code in response before sending the body. The response 417 Expectation Failed indicates the request should not be continued.
     */
    CONTINUE = HTTP_STATUS_CODE_CONTINUE,

    /**
     * The requester has asked the server to switch protocols and the server has agreed to do so.
     */
    SWITCHING_PROTOCOLS = HTTP_STATUS_CODE_SWITCHING_PROTOCOLS,

    /**
     * A WebDAV request may contain many sub-requests involving file operations, requiring a long time to complete the request.
     * This code indicates that the server has received and is processing the request, but no response is available yet.
     * This prevents the client from timing out and assuming the request was lost.
     */
    PROCESSING = HTTP_STATUS_CODE_PROCESSING,

    /**
     * Standard response for successful HTTP requests.
     * The actual response will depend on the request method used.
     * In a GET request, the response will contain an entity corresponding to the requested resource.
     * In a POST request, the response will contain an entity describing or containing the result of the action.
     */
    OK = HTTP_STATUS_CODE_OK,

    /**
     * The request has been fulfilled, resulting in the creation of a new resource.
     */
    CREATED = HTTP_STATUS_CODE_CREATED,

    /**
     * The request has been accepted for processing, but the processing has not been completed.
     * The request might or might not be eventually acted upon, and may be disallowed when processing occurs.
     */
    ACCEPTED = HTTP_STATUS_CODE_ACCEPTED,

    /**
     * SINCE HTTP/1.1
     * The server is a transforming proxy that received a 200 OK from its origin,
     * but is returning a modified version of the origin's response.
     */
    NON_AUTHORITATIVE_INFORMATION = HTTP_STATUS_CODE_NON_AUTHORITATIVE_INFORMATION,

    /**
     * The server successfully processed the request and is not returning any content.
     */
    NO_CONTENT = HTTP_STATUS_CODE_NO_CONTENT,

    /**
     * The server successfully processed the request, but is not returning any content.
     * Unlike a 204 response, this response requires that the requester reset the document view.
     */
    RESET_CONTENT = HTTP_STATUS_CODE_RESET_CONTENT,

    /**
     * The server is delivering only part of the resource (byte serving) due to a range header sent by the client.
     * The range header is used by HTTP clients to enable resuming of interrupted downloads,
     * or split a download into multiple simultaneous streams.
     */
    PARTIAL_CONTENT = HTTP_STATUS_CODE_PARTIAL_CONTENT,

    /**
     * The message body that follows is an XML message and can contain a number of separate response codes,
     * depending on how many sub-requests were made.
     */
    MULTI_STATUS = HTTP_STATUS_CODE_MULTI_STATUS,

    /**
     * The members of a DAV binding have already been enumerated in a preceding part of the (multistatus) response,
     * and are not being included again.
     */
    ALREADY_REPORTED = HTTP_STATUS_CODE_ALREADY_REPORTED,

    /**
     * The server has fulfilled a request for the resource,
     * and the response is a representation of the result of one or more instance-manipulations applied to the current instance.
     */
    IM_USED = HTTP_STATUS_CODE_IM_USED,

    /**
     * Indicates multiple options for the resource from which the client may choose (via agent-driven content negotiation).
     * For example, this code could be used to present multiple video format options,
     * to list files with different filename extensions, or to suggest word-sense disambiguation.
     */
    MULTIPLE_CHOICES = HTTP_STATUS_CODE_MULTIPLE_CHOICES,

    /**
     * This and all future requests should be directed to the given URI.
     */
    MOVED_PERMANENTLY = HTTP_STATUS_CODE_MOVED_PERMANENTLY,

    /**
     * This is an example of industry practice contradicting the standard.
     * The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect
     * (the original describing phrase was "Moved Temporarily"), but popular browsers implemented 302
     * with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307
     * to distinguish between the two behaviours. However, some Web applications and frameworks
     * use the 302 status code as if it were the 303.
     */
    FOUND = HTTP_STATUS_CODE_FOUND,

    /**
     * SINCE HTTP/1.1
     * The response to the request can be found under another URI using a GET method.
     * When received in response to a POST (or PUT/DELETE), the client should presume that
     * the server has received the data and should issue a redirect with a separate GET message.
     */
    SEE_OTHER = HTTP_STATUS_CODE_SEE_OTHER,

    /**
     * Indicates that the resource has not been modified since the version specified by the request headers If-Modified-Since or If-None-Match.
     * In such case, there is no need to retransmit the resource since the client still has a previously-downloaded copy.
     */
    NOT_MODIFIED = HTTP_STATUS_CODE_NOT_MODIFIED,

    /**
     * SINCE HTTP/1.1
     * The requested resource is available only through a proxy, the address for which is provided in the response.
     * Many HTTP clients (such as Mozilla and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.
     */
    USE_PROXY = HTTP_STATUS_CODE_USE_PROXY,

    /**
     * No longer used. Originally meant "Subsequent requests should use the specified proxy."
     */
    SWITCH_PROXY = HTTP_STATUS_CODE_SWITCH_PROXY,

    /**
     * SINCE HTTP/1.1
     * In this case, the request should be repeated with another URI; however, future requests should still use the original URI.
     * In contrast to how 302 was historically implemented, the request method is not allowed to be changed when reissuing the original request.
     * For example, a POST request should be repeated using another POST request.
     */
    TEMPORARY_REDIRECT = HTTP_STATUS_CODE_TEMPORARY_REDIRECT,

    /**
     * The request and all future requests should be repeated using another URI.
     * 307 and 308 parallel the behaviors of 302 and 301, but do not allow the HTTP method to change.
     * So, for example, submitting a form to a permanently redirected resource may continue smoothly.
     */
    PERMANENT_REDIRECT = HTTP_STATUS_CODE_PERMANENT_REDIRECT,

    /**
     * The server cannot or will not process the request due to an apparent client error
     * (e.g., malformed request syntax, too large size, invalid request message framing, or deceptive request routing).
     */
    BAD_REQUEST = HTTP_STATUS_CODE_BAD_REQUEST,

    /**
     * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet
     * been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the
     * requested resource. See Basic access authentication and Digest access authentication. 401 semantically means
     * "unauthenticated",i.e. the user does not have the necessary credentials.
     */
    UNAUTHORIZED = HTTP_STATUS_CODE_UNAUTHORIZED,

    /**
     * Reserved for future use. The original intention was that this code might be used as part of some form of digital
     * cash or micro payment scheme, but that has not happened, and this code is not usually used.
     * Google Developers API uses this status if a particular developer has exceeded the daily limit on requests.
     */
    PAYMENT_REQUIRED = HTTP_STATUS_CODE_PAYMENT_REQUIRED,

    /**
     * The request was valid, but the server is refusing action.
     * The user might not have the necessary permissions for a resource.
     */
    FORBIDDEN = HTTP_STATUS_CODE_FORBIDDEN,

    /**
     * The requested resource could not be found but may be available in the future.
     * Subsequent requests by the client are permissible.
     */
    NOT_FOUND = HTTP_STATUS_CODE_NOT_FOUND,

    /**
     * A request method is not supported for the requested resource;
     * for example, a GET request on a form that requires data to be presented via POST, or a PUT request on a read-only resource.
     */
    METHOD_NOT_ALLOWED = HTTP_STATUS_CODE_METHOD_NOT_ALLOWED,

    /**
     * The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request.
     */
    NOT_ACCEPTABLE = HTTP_STATUS_CODE_NOT_ACCEPTABLE,

    /**
     * The client must first authenticate itself with the proxy.
     */
    PROXY_AUTHENTICATION_REQUIRED = HTTP_STATUS_CODE_PROXY_AUTHENTICATION_REQUIRED,

    /**
     * The server timed out waiting for the request.
     * According to HTTP specifications:
     * "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
     */
    REQUEST_TIMEOUT = HTTP_STATUS_CODE_REQUEST_TIMEOUT,

    /**
     * Indicates that the request could not be processed because of conflict in the request,
     * such as an edit conflict between multiple simultaneous updates.
     */
    CONFLICT = HTTP_STATUS_CODE_CONFLICT,

    /**
     * Indicates that the resource requested is no longer available and will not be available again.
     * This should be used when a resource has been intentionally removed and the resource should be purged.
     * Upon receiving a 410 status code, the client should not request the resource in the future.
     * Clients such as search engines should remove the resource from their indices.
     * Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
     */
    GONE = HTTP_STATUS_CODE_GONE,

    /**
     * The request did not specify the length of its content, which is required by the requested resource.
     */
    LENGTH_REQUIRED = HTTP_STATUS_CODE_LENGTH_REQUIRED,

    /**
     * The server does not meet one of the preconditions that the requester put on the request.
     */
    PRECONDITION_FAILED = HTTP_STATUS_CODE_PRECONDITION_FAILED,

    /**
     * The request is larger than the server is willing or able to process. Previously called "Request Entity Too Large".
     */
    PAYLOAD_TOO_LARGE = HTTP_STATUS_CODE_PAYLOAD_TOO_LARGE,

    /**
     * The URI provided was too long for the server to process. Often the result of too much data being encoded as a query-string of a GET request,
     * in which case it should be converted to a POST request.
     * Called "Request-URI Too Long" previously.
     */
    URI_TOO_LONG = HTTP_STATUS_CODE_URI_TOO_LONG,

    /**
     * The request entity has a media type which the server or resource does not support.
     * For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
     */
    UNSUPPORTED_MEDIA_TYPE = HTTP_STATUS_CODE_UNSUPPORTED_MEDIA_TYPE,

    /**
     * The client has asked for a portion of the file (byte serving), but the server cannot supply that portion.
     * For example, if the client asked for a part of the file that lies beyond the end of the file.
     * Called "Requested Range Not Satisfiable" previously.
     */
    RANGE_NOT_SATISFIABLE = HTTP_STATUS_CODE_RANGE_NOT_SATISFIABLE,

    /**
     * The server cannot meet the requirements of the Expect request-header field.
     */
    EXPECTATION_FAILED = HTTP_STATUS_CODE_EXPECTATION_FAILED,

    /**
     * This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol,
     * and is not expected to be implemented by actual HTTP servers. The RFC specifies this code should be returned by
     * teapots requested to brew coffee. This HTTP status is used as an Easter egg in some websites, including Google.com.
     */
    I_AM_A_TEAPOT = HTTP_STATUS_CODE_I_AM_A_TEAPOT,

    /**
     * The request was directed at a server that is not able to produce a response (for example because a connection reuse).
     */
    MISDIRECTED_REQUEST = HTTP_STATUS_CODE_MISDIRECTED_REQUEST,

    /**
     * The request was well-formed but was unable to be followed due to semantic errors.
     */
    UNPROCESSABLE_ENTITY = HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY,

    /**
     * The resource that is being accessed is locked.
     */
    LOCKED = HTTP_STATUS_CODE_LOCKED,

    /**
     * The request failed due to failure of a previous request (e.g., a PROPPATCH).
     */
    FAILED_DEPENDENCY = HTTP_STATUS_CODE_FAILED_DEPENDENCY,

    /**
     * The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.
     */
    UPGRADE_REQUIRED = HTTP_STATUS_CODE_UPGRADE_REQUIRED,

    /**
     * The origin server requires the request to be conditional.
     * Intended to prevent "the 'lost update' problem, where a client
     * GETs a resource's state, modifies it, and PUTs it back to the server,
     * when meanwhile a third party has modified the state on the server, leading to a conflict."
     */
    PRECONDITION_REQUIRED = HTTP_STATUS_CODE_PRECONDITION_REQUIRED,

    /**
     * The user has sent too many requests in a given amount of time. Intended for use with rate-limiting schemes.
     */
    TOO_MANY_REQUESTS = HTTP_STATUS_CODE_TOO_MANY_REQUESTS,

    /**
     * The server is unwilling to process the request because either an individual header field,
     * or all the header fields collectively, are too large.
     */
    REQUEST_HEADER_FIELDS_TOO_LARGE = HTTP_STATUS_CODE_REQUEST_HEADER_FIELDS_TOO_LARGE,

    /**
     * A server operator has received a legal demand to deny access to a resource or to a set of resources
     * that includes the requested resource. The code 451 was chosen as a reference to the novel Fahrenheit 451.
     */
    UNAVAILABLE_FOR_LEGAL_REASONS = HTTP_STATUS_CODE_UNAVAILABLE_FOR_LEGAL_REASONS,

    /**
     * A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
     */
    INTERNAL_SERVER_ERROR = HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR,

    /**
     * The server either does not recognize the request method, or it lacks the ability to fulfill the request.
     * Usually this implies future availability (e.g., a new feature of a web-service API).
     */
    NOT_IMPLEMENTED = HTTP_STATUS_CODE_NOT_IMPLEMENTED,

    /**
     * The server was acting as a gateway or proxy and received an invalid response from the upstream server.
     */
    BAD_GATEWAY = HTTP_STATUS_CODE_BAD_GATEWAY,

    /**
     * The server is currently unavailable (because it is overloaded or down for maintenance).
     * Generally, this is a temporary state.
     */
    SERVICE_UNAVAILABLE = HTTP_STATUS_CODE_SERVICE_UNAVAILABLE,

    /**
     * The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.
     */
    GATEWAY_TIMEOUT = HTTP_STATUS_CODE_GATEWAY_TIMEOUT,

    /**
     * The server does not support the HTTP protocol version used in the request
     */
    HTTP_VERSION_NOT_SUPPORTED = HTTP_STATUS_CODE_HTTP_VERSION_NOT_SUPPORTED,

    /**
     * Transparent content negotiation for the request results in a circular reference.
     */
    VARIANT_ALSO_NEGOTIATES = HTTP_STATUS_CODE_VARIANT_ALSO_NEGOTIATES,

    /**
     * The server is unable to store the representation needed to complete the request.
     */
    INSUFFICIENT_STORAGE = HTTP_STATUS_CODE_INSUFFICIENT_STORAGE,

    /**
     * The server detected an infinite loop while processing the request.
     */
    LOOP_DETECTED = HTTP_STATUS_CODE_LOOP_DETECTED,

    /**
     * Further extensions to the request are required for the server to fulfill it.
     */
    NOT_EXTENDED = HTTP_STATUS_CODE_NOT_EXTENDED,

    /**
     * The client needs to authenticate to gain network access.
     * Intended for use by intercepting proxies used to control access to the network (e.g., "captive portals" used
     * to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).
     */
    NETWORK_AUTHENTICATION_REQUIRED = HTTP_STATUS_CODE_NETWORK_AUTHENTICATION_REQUIRED,
}
