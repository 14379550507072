import React, { useState } from 'react';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';
import useCartSummary from '../../../hooks/useCartSummary';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import CartSideBarRow from 'components/CartView/components/CartSideBar/CartSideBarRow';

interface IProps {
    isMobileSticky?: boolean;
    checkoutUrl: string;
    labels: {
        cartSummaryLabel: string;
        sumLabel: string;
        checkoutLabel: string;
    };
}
const CartSideBar: React.FunctionComponent<IProps> = (props) => {
    const { isMobileSticky, checkoutUrl, labels } = props;
    const [expanded, setExpanded] = useState(false);
    const { cartTotals } = useCartSummary();

    const toggleDetailedCart = () => {
        setExpanded(!expanded);
    };

    return cartTotals ? (
        <div className={`layout-cart__sidebar ${isMobileSticky ? 'mobile-sticky' : ''}`}>
            <div className="layout-cart__sidebar-details">
                <div className="layout-cart__sidebar-icon"></div>
                <h2>{labels.cartSummaryLabel}</h2>
                <ul className="cart-prices">
                    <CartSideBarRow total={cartTotals.subTotal} />
                    <CartSideBarRow total={cartTotals.vatTotal} />
                    <CartSideBarRow total={cartTotals.discountTotal} />
                </ul>
            </div>
            <p className="layout-cart__sidebar-total">
                {labels.sumLabel}:
                <span className="text-accented">{formatPriceLocale(cartTotals.grandTotal.value ?? 0)}</span>
                <button
                    onClick={() => {
                        toggleDetailedCart();
                    }}
                    className="layout-cart__sidebar-toggle"
                >
                    <Icon kind={expanded ? 'arrow5-down' : 'arrow5-up'} width={12} height={12} />
                </button>
            </p>
            <Buttons layout={'default'}>
                <Button
                    layout={'default'}
                    size={'large'}
                    intent={'primary'}
                    title={labels.checkoutLabel}
                    type={'anchor'}
                    href={checkoutUrl}
                />
            </Buttons>
        </div>
    ) : (
        <></>
    );
};

export default CartSideBar;
