import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormKey from 'components/GeneralForm/Field/FormKey';
import FormList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormList/index';
import FormListItem from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormListItem/index';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/CheckboxList';
import RadioWithLabel from 'ekaubamaja-ui/lib/Components/RadioWithLabel';
import { B2BAccountRoleEnum } from 'components/B2BClient/enums/B2BAccountRoleEnum';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import SystemNotifications from 'ekaubamaja-ui/lib/Components/SystemNotifications';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
        };
        labels: {
            firstname: string;
            lastname: string;
            email: string;
            idCode: string;
            user: string;
            formSubmitLabel: string;
            invalidEmail: string;
            requiredField: string;
            checkFieldsLabel: string;
        };
    };
}

const B2BClientAccountFormCreate: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { formConfig, labels } = config;

    const formElement = useRef<HTMLFormElement>(null);
    const formDataValidationSchema = Yup.object().shape({
        firstname: Yup.string().nullable().required(labels.requiredField),
        lastname: Yup.string().nullable().required(labels.requiredField),
        email: Yup.string().email(config.labels.invalidEmail).required(config.labels.requiredField),
        personal_id: Yup.string().nullable().required(labels.requiredField),
    });

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            personal_id: '',
            email: '',
        },
        validationSchema: formDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    const hasErrors = () => {
        return Object.values(formik.errors).length !== 0;
    };

    return (
        <form
            ref={formElement}
            action={formConfig.actionUrl}
            method="post"
            className="margt"
            onSubmit={formik.handleSubmit}
        >
            <FormKey />

            {hasErrors() && (
                <SystemNotifications inline={true} compact={true}>
                    <SystemNotification intent="danger">
                        <p>{config.labels.checkFieldsLabel}</p>
                    </SystemNotification>
                </SystemNotifications>
            )}

            <FormList size="wide">
                <FormListItem label={labels.firstname} required={true} error={formik.errors.firstname}>
                    <ControlInput name="firstname" value={formik.values.firstname} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.lastname} required={true} error={formik.errors.lastname}>
                    <ControlInput name="lastname" value={formik.values.lastname} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.idCode} required={true} error={formik.errors.personal_id}>
                    <ControlInput name="personal_id" value={formik.values.personal_id} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.email} required={true} error={formik.errors.email}>
                    <ControlInput name="email" value={formik.values.email} onChange={formik.handleChange} />
                </FormListItem>
            </FormList>
            <Buttons layout="vertical-wide">
                <Button type="submit" intent="primary" title={labels.formSubmitLabel} />
            </Buttons>
        </form>
    );
};

export default B2BClientAccountFormCreate;
