import React, { useEffect, useState } from 'react';
import Img from 'components/Image/Img';
import IPasswordStrengthConfig from 'components/GeneralForm/Interfaces/IPasswordStrengthConfig';

export interface IControlPasswordProps {
    id?: string;
    name?: string;
    value: string;
    onChange?: (event) => void;
    showPasswordButtonLabel: string;
    passwordStrengthConfig?: IPasswordStrengthConfig;
    autoComplete?: 'on' | 'off' | 'new-password';
}

export default function ControlPassword(props: IControlPasswordProps): JSX.Element {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState({
        score: 0,
        label: '',
        className: '',
    });

    useEffect(() => {
        if (!props.passwordStrengthConfig?.isEnabled || password.length < props.passwordStrengthConfig?.minLength) {
            return;
        }

        let className = '';
        let label = '';

        setPasswordStrength((passwordStrength) => {
            return { ...passwordStrength, label, className };
        });
    }, [password]);

    return (
        <React.Fragment>
            <div className="form-control-password">
                <input
                    type={isShowPassword ? 'text' : 'password'}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    autoComplete={props.autoComplete ?? ''}
                    onChange={(e) => {
                        setPassword(e.target.value);

                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                />
                <button type="button" onClick={() => setIsShowPassword(!isShowPassword)}>
                    <Img iconName="icon_eye" alt={props.showPasswordButtonLabel} />
                    {props.showPasswordButtonLabel}
                </button>
            </div>
            {passwordStrength.className && passwordStrength.label && (
                <p className={'password-strength ' + passwordStrength.className}>
                    {props.passwordStrengthConfig?.labels.passwordStrengthLabel}:
                    <span className="bold">{passwordStrength.label}</span>
                </p>
            )}
        </React.Fragment>
    );
}
