import { request } from 'data/requests/request';

export interface IAddToCartRequestData {
    sku: string;
    qty: number;
}

const AddToCartRequest = (data: IAddToCartRequestData) =>
    request({
        type: 'addToCartRequest',
        url: 'basket/ajax/addtocart',
        method: 'POST',
        notApi: true,
        data,
    });

export default AddToCartRequest;
