import React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { includes } from 'lodash';
import { closeOverlay } from 'data/actions/overlays';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'data/reducers/root';
import SignWithSmartIdForm from 'components/Checkout/components/signing/SignWithSmartIdForm';
import RenderTabs from 'ekaubamaja-ui/lib/Components/Tabs';
import SignWithMobileIdForm from 'components/Checkout/components/signing/SignWithMobileIdForm';
import SignWithIdButton from 'components/Checkout/components/signing/SignWithIdButton';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';

interface IProps {
    submitOrder: () => void;
    submitLabel: string;
    cancelLabel: string;
    downloadFile: () => void;
    confirmationLabels: {
        overLayTitle: string;
        confirmSubmitting: string;
        smartId: string;
        mobileId: string;
        phoneNumber: string;
        personalCode: string;
        sign: string;
        downloadFile: string;
        verificationCode: string;
        signingFailed: string;
        idCard: string;
        signOrder: string;
    };
}

const ConfirmationOverlay: React.FunctionComponent<IProps> = (props) => {
    const { submitOrder, submitLabel, cancelLabel, confirmationLabels, downloadFile } = props;

    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);
    const dispatch = useDispatch();

    const closeConfirmOverlay = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    return (
        <>
            <Overlay
                layout="dialog"
                className="epharma-dialog"
                isOpen={includes(openOverlays, 'signingOverlay')}
                doClose={closeConfirmOverlay}
                customHeader={
                    <div className="epharma-dialog-heading">
                        <h2>{confirmationLabels.signOrder}</h2>
                        <button onClick={() => closeConfirmOverlay()}>
                            <Icon width={16} height={16} kind={'close'} />
                        </button>
                    </div>
                }
            >
                <RenderTabs
                    className="elevated"
                    tabs={{
                        tab01: {
                            title: confirmationLabels.smartId,
                            mobileTitle: <h2>{confirmationLabels.smartId}</h2>,
                            content: (
                                <SignWithSmartIdForm
                                    signLabel={confirmationLabels.sign}
                                    downloadFileLabel={confirmationLabels.downloadFile}
                                    personalCodeLabel={confirmationLabels.personalCode}
                                    verificationCodeLabel={confirmationLabels.verificationCode}
                                    signingFailedLabel={confirmationLabels.signingFailed}
                                    downloadFile={downloadFile}
                                    submitOrder={submitOrder}
                                />
                            ),
                        },
                        tab02: {
                            title: confirmationLabels.mobileId,
                            mobileTitle: <h2>{confirmationLabels.mobileId}</h2>,
                            content: (
                                <SignWithMobileIdForm
                                    signLabel={confirmationLabels.sign}
                                    phoneNumberLabel={confirmationLabels.phoneNumber}
                                    downloadFileLabel={confirmationLabels.downloadFile}
                                    personalCodeLabel={confirmationLabels.personalCode}
                                    verificationCodeLabel={confirmationLabels.verificationCode}
                                    signingFailedLabel={confirmationLabels.signingFailed}
                                    downloadFile={downloadFile}
                                    submitOrder={submitOrder}
                                />
                            ),
                        },
                        tab03: {
                            title: confirmationLabels.idCard,
                            mobileTitle: <h2>{confirmationLabels.idCard}</h2>,
                            content: (
                                <SignWithIdButton
                                    signLabel={confirmationLabels.sign}
                                    downloadFileLabel={confirmationLabels.downloadFile}
                                    personalCodeLabel={confirmationLabels.personalCode}
                                    verificationCodeLabel={confirmationLabels.verificationCode}
                                    signingFailedLabel={confirmationLabels.signingFailed}
                                    idCardLabel={confirmationLabels.idCard}
                                    downloadFile={downloadFile}
                                    submitOrder={submitOrder}
                                />
                            ),
                        },
                    }}
                    activeTab="tab01"
                    disableHash={true}
                />
            </Overlay>
            <Overlay
                isOpen={includes(openOverlays, 'basketSubmitConfirmOverlay')}
                doClose={closeConfirmOverlay}
                layout="dialog"
                size="small"
                title={confirmationLabels.overLayTitle}
                canEscapeKeyClose
                dialogConfig={{
                    buttons: [
                        {
                            title: submitLabel,
                            intent: 'primary',
                            onClick: submitOrder,
                        },
                        {
                            title: cancelLabel,
                            onClick: closeConfirmOverlay,
                        },
                    ],
                }}
            />
        </>
    );
};

export default ConfirmationOverlay;
