import * as React from 'react';
import { useEffect, useState } from 'react';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML/index';

interface IProps {
    config: {
        title: string;
    };
}

export const Title = (props: IProps) => {
    const [pageTitle, setPageTitle] = useState(props.config.title);

    const effect = (e: CustomEvent) => {
        if (e.detail && e.detail.pageTitle) {
            setPageTitle(e.detail?.pageTitle);
            document.title = e.detail?.pageTitle;
        }
    };

    useEffect(() => {
        window.addEventListener('page-changed', (e: Event) => {
            effect(e as CustomEvent);
        });
    }, []);

    return (
        <span>
            <RenderHTML html={pageTitle} nowrapper={true} />
        </span>
    );
};
