import * as React from 'react';
import HeadingWithButtons from 'ekaubamaja-ui/lib/Components/HeadingWithButtons';
import TablePager from 'components/ui/TablePager';
import { useState } from 'react';
import InvoiceRow from 'components/Customer/components/InvoiceRow';

interface IProps {
    config: {
        invoices: IInvoice[];
        showPerPage: number;
        showAmounts: number[];
        labels: {
            latestOrders: string;
            orderNumber: string;
            orderDate: string;
            orderCustomers: string;
            sum: string;
            rows: string;
            show: string;
            perPage: string;
            previous: string;
            next: string;
            reOrder: string;
            invoiceStatus: string;
            awaitingFulfillment: string;
            invoiced: string;
            notPaid: string;
            paid: string;
            debt: string;
        };
    };
}

export interface IInvoice {
    orderNumber: string;
    orderDate: string;
    sum: string;
    detailLink: string;
    reorderLink: string;
    status: InvoiceStatusEnum;
    orderCustomers: IOrderCustomer[];
}

interface IOrderCustomer {
    firstname: string;
    lastname: string;
    email: string;
}

export enum InvoiceStatusEnum {
    PAID = 'PAID',
    NOT_PAID = 'NOT_PAID',
    INVOICED = 'INVOICED',
    DEBT = 'DEBT',
}

const clientOrders: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { labels } = config;

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(config.showPerPage);
    const getInvoices = () => {
        const startPoint = Math.max((currentPage - 1) * perPage - 1, 0);
        return config.invoices.slice(startPoint, startPoint + perPage);
    };

    const getMaxPage = () => {
        return Math.ceil(config.invoices.length / perPage);
    };

    return (
        <React.Fragment>
            <section>
                <HeadingWithButtons title={labels.latestOrders} />
                <table className="data01 responsive">
                    <thead>
                        <tr>
                            <th>{labels.orderNumber}</th>
                            <th>{labels.orderDate}</th>
                            <th>{labels.orderCustomers}</th>
                            <th>{labels.sum}</th>
                            <th>{labels.invoiceStatus}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getInvoices().map((invoice) => (
                            <InvoiceRow key={invoice.orderNumber} invoice={invoice} labels={labels} />
                        ))}
                    </tbody>
                </table>
                <TablePager
                    config={{
                        activePage: currentPage,
                        showPerPage: perPage,
                        pageCount: getMaxPage(),
                        totalCount: config.invoices.length,
                        showAmounts: config.showAmounts,
                        labels: {
                            rows: labels.rows,
                            previous: labels.previous,
                            show: labels.show,
                            perPage: labels.perPage,
                            next: labels.next,
                        },
                    }}
                    changeCurrentPage={setCurrentPage}
                    changePerPage={setPerPage}
                />
            </section>
        </React.Fragment>
    );
};

export default clientOrders;
