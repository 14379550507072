interface IData {
    [key: string]: any;
}

export default function formDataRequest(
    url: string,
    data: IData,
    callback: (error: string | null, response?: string) => void,
): void {
    const XHR = new XMLHttpRequest();
    const FD = new FormData();

    for (const [name, value] of Object.entries(data)) {
        FD.append(name, value);
    }

    XHR.onload = function () {
        if (XHR.status !== 200) {
            callback(XHR.statusText);
        } else {
            callback(null, XHR.response);
        }
    };
    XHR.onerror = function () {
        callback(XHR.response);
    };

    XHR.open('POST', url);

    XHR.send(FD);
}
