import React, { useMemo, useState } from 'react';
import Img from 'components/Image/Img';
import Filters from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/Filters';
import FilterDrop from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FilterDrop';
import ControlRange from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/ControlRange';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/CheckboxList';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel';
import { IFilter } from 'components/LayeredNavigation/LayeredNavigation';
import { ISelectedFilters } from 'components/LayeredNavigation/store/selectedFiltersSelector';

interface IProps {
    config: {
        isOverlay: boolean;
        minPrice: number;
        maxPrice: number;
        labels: {
            showAll: string;
            showLess: string;
            minimum: string;
            maximum: string;
        };
    };
    addFilter: (attributeCode: string, item: { label: string; value: string }, replace?: boolean) => void;
    removeFilter: (attributeCode: string, item: { label: string; value: string }) => void;
    filters: IFilter[];
    selectedFilters: ISelectedFilters;
}

const FiltersWrapper = (props: IProps) => {
    const { config, filters, selectedFilters, addFilter, removeFilter } = props;
    const { minPrice, maxPrice, labels } = config;
    const [price, setPrice] = useState<[number, number]>([minPrice, maxPrice]);

    const isAttributeOptionSelected = useMemo(() => {
        return (attributeCode: string, optionValue: string) => {
            if (!selectedFilters[attributeCode]) {
                return false;
            }

            return selectedFilters[attributeCode].some((option) => option.value === optionValue);
        };
    }, [selectedFilters]);

    return (
        <div className="product-filters__form">
            <Filters labelShowAll={labels.showAll} labelShowLess={labels.showLess} isOverlay={config.isOverlay}>
                {filters &&
                    filters.map((filter) => (
                        <FilterDrop
                            key={filter.attributeCode}
                            popoverClassName="product-filter-drop"
                            arrow={<Img iconName="icon_arrow_down" />}
                            toggler={filter.label}
                            isFieldSet={true}
                        >
                            {filter.attributeCode === 'price' && (
                                <ControlRange
                                    min={minPrice}
                                    max={maxPrice}
                                    value={price}
                                    stepSize={Math.ceil((maxPrice - minPrice) / 200)}
                                    onRelease={(range) => {
                                        const rangeValue = range.toString().replace(',', '-');

                                        addFilter(
                                            filter.attributeCode,
                                            { label: `${rangeValue} €`, value: rangeValue },
                                            true,
                                        );
                                        setPrice(range);
                                    }}
                                    onChange={(range) => {
                                        setPrice(range);
                                    }}
                                    labelMax={labels.maximum}
                                    labelMin={labels.minimum}
                                />
                            )}
                            {filter.attributeCode !== 'price' && (
                                <CheckboxList>
                                    {filter.items.map((item) => (
                                        <CheckboxWithLabel
                                            key={item.value}
                                            label={`${item.label} (${item.productCount})`}
                                            checked={isAttributeOptionSelected(filter.attributeCode, item.value)}
                                            onChange={() => {
                                                if (!isAttributeOptionSelected(filter.attributeCode, item.value)) {
                                                    addFilter(filter.attributeCode, item);
                                                } else {
                                                    removeFilter(filter.attributeCode, item);
                                                }
                                            }}
                                        />
                                    ))}
                                </CheckboxList>
                            )}
                        </FilterDrop>
                    ))}
            </Filters>
        </div>
    );
};

export default FiltersWrapper;
