import React from 'react';
import { times } from 'lodash';
import Img from 'components/Image/Img';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import formDataRequest from 'data/requests/formDataRequest';
import useProductCompareList from 'components/ProductCompare/hooks/useProductCompareList';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import IProduct from 'components/Catalog/interfaces/IProduct';

interface IProps {
    config: {
        productCompareUrl: string;
        labels: {
            addProductToCompare: string;
            removedProductFromCompare: string;
            failedToRemoveProductFromCompare: string;
            compare: string;
            remove: string;
        };
    };
}

const COMPARABLE_ITEMS_COUNT = 3;

const ProductCompareWidget: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { labels } = config;

    const { reloadProductCompareList, productCompareList } = useProductCompareList();
    const products = productCompareList?.products ?? [];
    const placeHolderCount = COMPARABLE_ITEMS_COUNT - products.length;

    const removeProduct = (product: IProduct) => {
        formDataRequest(
            '/catalog/product_compare/remove',
            { form_key: window.form_key, product: product.id, isAjax: true },
            (error) => {
                if (error) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: labels.failedToRemoveProductFromCompare,
                    });
                } else {
                    Toaster.addToast({
                        intent: 'success',
                        text: labels.removedProductFromCompare.replace('%1', product.name),
                    });
                }

                window.setTimeout(reloadProductCompareList, 250);
            },
        );
    };

    if (products.length === 0) {
        return <></>;
    }

    return (
        <div className="product-compare">
            {products.map((product) => (
                <div className="product-compare__item" key={product.id}>
                    <a href={product.url}>
                        <img src={product.image} alt={product.name} />
                        <span>{product.name}</span>
                    </a>
                    <button
                        className="product-compare__remove"
                        aria-label={labels.remove}
                        onClick={() => removeProduct(product)}
                    >
                        <Img iconName="sprite_remove02" alt={labels.remove} />
                    </button>
                </div>
            ))}

            {times(placeHolderCount, (i) => (
                <div className="product-compare__placeholder" key={i}>
                    <Img iconName="placeholder" />
                    <span>{labels.addProductToCompare}</span>
                </div>
            ))}

            <Button type="anchor" href={config.productCompareUrl} intent="secondary" title={labels.compare} />
        </div>
    );
};

export default ProductCompareWidget;
