import React from 'react';
import Img from 'components/Image/Img';
import classNames from 'classnames';

export interface ILabel {
    label: string;
    theme: string;
    icon?: string;
}

interface IProps {
    labels: ILabel[];
}

const ProductLabels: React.FunctionComponent<IProps> = (props) => {
    const { labels } = props;

    return (
        <div className="product-badges__group">
            {labels.map((label) => (
                <div className={classNames('product-badges__badge-square', label.theme)}>
                    {label.icon && <Img iconName={label.icon} />}
                    {label.label}
                </div>
            ))}
        </div>
    );
};

export default ProductLabels;
