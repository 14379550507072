import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import customerAuthStatusRequest, { ICustomerAuthStatusResponse } from 'components/Customer/requests/AuthStatusRequest';

const mapStateToProps = (state) => {
    return {
        customerStatus: state.entities.customerAuthStatusRequest as ICustomerAuthStatusResponse,
    };
};

const useAuth = () => {
    const [{ isFinished }] = useRequest(customerAuthStatusRequest());
    const { customerStatus } = useSelector(mapStateToProps);
    const isLoggedIn = customerStatus?.isLoggedIn;
    const customer = customerStatus?.customer;

    return {
        isFinished,
        isLoggedIn,
        customer,
    };
};

export default useAuth;
