import * as React from 'react';
import { useMutation } from 'redux-query-react';
import authRequest from 'data/requests/auth/authRequest';
import { useCallback, useEffect, useRef } from 'react';

interface IProps {
    config: {
        isLoggedIn: boolean;
    };
}
export const JWT = 'jwt';
export const IS_LOGGED_IN = 'is_logged_in';

const AuthorizationHandler = (props: IProps) => {
    const { isLoggedIn } = props.config;
    const [{}, authRequestHandler] = useMutation(() => authRequest());
    const requestDone = useRef(false);

    const getToken = useCallback(async () => {
        const response = await authRequestHandler();
        if (response.status === 200) {
            return response.body.token;
        }
        return undefined;
    }, [authRequestHandler]);

    useEffect(() => {
        localStorage.setItem(IS_LOGGED_IN, isLoggedIn ? 'true' : 'false');
        if (!requestDone.current && isLoggedIn && !localStorage.getItem(JWT)) {
            requestDone.current = true;
            getToken().then((token) => {
                if (token) {
                    localStorage.setItem(JWT, token);
                }
            });
        }
        if (!isLoggedIn && localStorage.getItem(JWT)) {
            localStorage.removeItem(JWT);
        }
    }, [authRequestHandler]);
    return <React.Fragment />;
};
export default AuthorizationHandler;
