import Img from 'components/Image/Img';
import React from 'react';
import { useMutation } from 'redux-query-react';
import { HttpStatusCode } from '../../../enums/HttpStatusCode';
import CartProductQuantity from 'components/CartView/components/CartProductQuantity';
import DeleteFromCartRequest from 'components/CartView/requests/DeleteFromCartRequest';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import UpdateCartRequest from 'components/CartView/requests/UpdateCartRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import CartProductPricing from 'components/CartView/components/CartProductPricing';
import { ICartProduct } from 'components/CartView/interfaces/CartInterfaces';

interface IProps {
    product: ICartProduct;
    labels: {
        quantityLabel: string;
        updateLabel: string;
        totalLabel: string;
        removeLabel: string;
        invalidQuantity: string;
    };
}

const CartProductActions: React.FunctionComponent<IProps> = (props) => {
    const { product, labels } = props;
    const { totalLabel, removeLabel } = labels;

    const [{}, deleteFromCart] = useMutation((sku: string) => DeleteFromCartRequest(sku));
    const [{}, updateCart] = useMutation((sku: string, qty: number) => UpdateCartRequest(sku, qty));

    const removeProduct = () => {
        void deleteFromCart(product.sku).then((res) => {
            if (res.status === HttpStatusCode.OK) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
                Toaster.addToast({ text: res.body.message, intent: 'success' });
            } else {
                Toaster.addToast({ text: res.body.message, intent: 'danger' });
            }
        });
    };

    const updateProduct = (qty: number) => {
        void updateCart(product.sku, qty).then((res) => {
            if (res.status === HttpStatusCode.OK) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
                Toaster.addToast({ text: res.body.message, intent: 'success' });
            } else {
                Toaster.addToast({ text: res.body.message, intent: 'danger' });
            }
        });
    };

    return (
        <div className="box-product__actions">
            <div className="box-product__actions-group">
                <CartProductPricing price={product.price} specialPrice={product.specialPrice} />
                <CartProductQuantity product={product} updateProduct={updateProduct} labels={labels} />
            </div>
            <div className="box-product__actions-group">
                <div className="box-product__total">
                    <p>
                        <span className="box-product__total-prices">
                            <span className="text-accented">
                                {`${totalLabel}: ${formatPriceLocale(product.rowTotal)}`}
                            </span>
                        </span>
                    </p>
                </div>
                {!product.isSpecialOffer && (
                    <button className="box-product__remove" onClick={() => removeProduct()}>
                        <Img iconName={'icon_trash'} />
                        {removeLabel}
                    </button>
                )}
            </div>
        </div>
    );
};

export default CartProductActions;
