import React from 'react';
import { IShippingMethod } from 'components/Checkout/interfaces/IShippingMethod';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import RadioWithLabel from 'ekaubamaja-ui/lib/Components/RadioWithLabel';
import RadioList from 'ekaubamaja-ui/lib/Applications/Checkout/Components/RadioList';

interface IProps {
    selectedShippingMethod: IShippingMethod;
    setSelectedShippingMethod: (method: IShippingMethod) => void;
    shippingMethods: IShippingMethod[];
}

const ShippingMethods: React.FunctionComponent<IProps> = (props) => {
    const { shippingMethods, selectedShippingMethod, setSelectedShippingMethod } = props;

    const isSelectedShipping = (methodCode: string): boolean => {
        return selectedShippingMethod.methodCode === methodCode;
    };

    return (
        <RadioList>
            {shippingMethods.map((method: IShippingMethod) => {
                return (
                    <RadioWithLabel
                        key={method.methodCode}
                        label={method.methodLabel}
                        checked={isSelectedShipping(method.methodCode)}
                        onChange={() => setSelectedShippingMethod(method)}
                        description={formatPriceLocale(method.price)}
                    />
                );
            })}
        </RadioList>
    );
};

export default ShippingMethods;
