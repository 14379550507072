import React from 'react';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';

interface IProps {
    loadingLabel: string;
}

const AssortmentOverlayContents = (props: IProps) => {
    const { loadingLabel } = props;

    const [{ isFinished }] = useRequest(
        request({ type: 'assortmentRequest', url: 'pricelist/assortment/index', notApi: true }),
    );

    if (isFinished) {
        window.location.reload();
    }

    return (
        <>
            <div className="epharma-dialog-content">
                <p className={'center'}>{loadingLabel}</p>
                <LoadingContent layout={'inline'} size={'large'} />
            </div>
        </>
    );
};

export default AssortmentOverlayContents;
