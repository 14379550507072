import * as React from 'react';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import Img from 'components/Image/Img';
import { IOrderDetailProps } from 'components/Customer/Client/interfaces/OrderDetailInterfaces';

const orderDetail: React.FunctionComponent<IOrderDetailProps> = (props) => {
    const { config } = props;
    const { labels, invoice } = config;

    return (
        <>
            <h2>
                {labels.order} <span className="text-highlighted">{config.invoice.orderNumber}</span>
            </h2>
            <section>
                <div className="layout-grid">
                    <div className="layout-grid__container wide">
                        <div className="layout-grid__columns align-v-top">
                            <div className="layout-grid__column">
                                <div className="content-card compact inline dark">
                                    <p>
                                        {labels.orderDate} <strong>{config.invoice.orderDate}</strong>
                                    </p>
                                    {!!invoice.orderCustomers.length && (
                                        <p>
                                            {labels.orderCustomers}:{' '}
                                            <strong>
                                                {invoice.orderCustomers
                                                    .map((customer) => `${customer.firstname} ${customer.lastname}`)
                                                    .join(',')}
                                            </strong>
                                        </p>
                                    )}
                                    {config.invoice.pdfUrl && (
                                        <>
                                            <p className="bold">
                                                {labels.invoice} {config.invoice.orderNumber}
                                            </p>
                                            <p>
                                                <button className="button intent-secondary muted size-small">
                                                    <Img iconName={'icon_print'} />
                                                    <a href={config.invoice.pdfUrl} target={'_blank'}>
                                                        {labels.printOrder}
                                                    </a>
                                                </button>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="layout-grid__column right">
                                <a href={config.reorderUrl} className="button button-secondary">
                                    {labels.repeatOrder}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="data01 responsive">
                    <thead>
                        <tr>
                            <th className="w100p" colSpan={2}>
                                {labels.product}
                            </th>
                            <th>{labels.sku}</th>
                            <th className="right nowrap">{labels.priceWithVat}</th>
                            <th className="right">{labels.quantity}</th>
                            <th className="right nowrap">{labels.sumWithVat}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {config.invoiceItems.map((invoiceItem) => {
                            return (
                                <tr className="valign-center" key={invoiceItem.sku}>
                                    <td className="w1p">
                                        <img
                                            src={invoiceItem.imageUrl}
                                            alt={invoiceItem.name}
                                            height="50"
                                            className="img-left"
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            {invoiceItem.name}
                                            <br />
                                            <small>
                                                {labels.productFullPrice} {formatPriceLocale(invoiceItem.currentPrice)}
                                            </small>
                                        </span>
                                    </td>
                                    <td className="nowrap">{invoiceItem.sku}</td>
                                    <td className="right bold nowrap">
                                        {formatPriceLocale(invoiceItem.singleItemSum)}
                                    </td>
                                    <td className="right nowrap">{invoiceItem.quantity}</td>
                                    <td className="right bold nowrap">{formatPriceLocale(invoiceItem.totalSum)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tbody>
                        <tr className="total">
                            <td colSpan={6} className="right">
                                <a href={config.reorderUrl} className="button button-secondary">
                                    {labels.repeatOrder}
                                </a>
                            </td>
                        </tr>
                        <tr className="total">
                            <td colSpan={5} className="right bold">
                                {labels.sumWithoutVat}
                            </td>
                            <td className="right bold">
                                {formatPriceLocale(config.invoice.sumBeforeVat, undefined, true, true)}
                            </td>
                        </tr>
                        <tr className="total">
                            <td colSpan={5} className="right">
                                {labels.vatSum}
                            </td>
                            <td className="right">{formatPriceLocale(config.invoice.vatSum, undefined, true, true)}</td>
                        </tr>
                        <tr className="total">
                            <td colSpan={5} className="right bold">
                                {labels.sumWithVat}
                            </td>
                            <td className="right bold">
                                {formatPriceLocale(config.invoice.sum, undefined, true, true)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </>
    );
};

export default orderDetail;
