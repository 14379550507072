import React from 'react';
import Img from 'components/Image/Img';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { ISelectedFilters } from 'components/LayeredNavigation/store/selectedFiltersSelector';
import ListMode from 'components/Catalog/Product/List/ListMode';
import { TListMode } from 'components/Catalog/store/productListModeSelector';

interface IProps {
    config: {
        clearAllUrl: string;
        labels: {
            remove: string;
            clearAll: string;
            selectedFilters: string;
        };
    };
    selectedFilters: ISelectedFilters;
    removeFilter: (attributeCode: string, item: { label: string; value: string }) => void;
    defaultListMode: TListMode;
}

const SelectedFiltersWrapper: React.FunctionComponent<IProps> = (props) => {
    const { config, selectedFilters, removeFilter, defaultListMode } = props;
    const { clearAllUrl, labels } = config;

    return (
        <div className="product-filters__applied">
            {!!Object.keys(selectedFilters).length && (
                <>
                    <p>
                        <span className="product-filters__applied-title">{labels.selectedFilters}: </span>
                        {Object.keys(selectedFilters).map((attributeCode) =>
                            selectedFilters[attributeCode].map((selectedFilterItem) => (
                                <span
                                    className="product-filters__tag"
                                    key={`${attributeCode}-${selectedFilterItem.value}`}
                                >
                                    {selectedFilterItem.label}
                                    <button onClick={() => removeFilter(attributeCode, selectedFilterItem)}>
                                        <span className="icon">
                                            <Img iconName="sprite_remove" />
                                        </span>
                                        {labels.remove}
                                    </button>
                                </span>
                            )),
                        )}
                    </p>

                    <Button
                        type="anchor"
                        intent="secondary"
                        size="xsmall"
                        href={clearAllUrl}
                        title={labels.clearAll}
                        icon="remove"
                    />
                </>
            )}

            <ListMode defaultListMode={defaultListMode} labels={{ gridView: 'Grid', listView: 'List' }} />
        </div>
    );
};

export default SelectedFiltersWrapper;
