import { request } from 'data/requests/request';

const authenticateRequest = (authToken: string) =>
    request({
        type: 'idCardAuthenticateRequest',
        url: 'idcard/authenticate',
        data: {
            authToken,
        },
        method: 'POST',
        notApi: true,
    });

export default authenticateRequest;

export interface IAuthenticateResponse {
    status: number;
    message: string;
}
