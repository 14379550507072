import React, { useState } from 'react';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import CartShareLinkInput from 'components/CartShare/CartShareLinkInput';
import { pick } from 'lodash';

export interface ICartShareConfig {
    generateLinkUrl: string;
    labels: {
        copyLink: string;
        copiedToClipboard: string;
        shareCart: string;
        exportCsv: string;
    };
}

interface IProps {
    config: ICartShareConfig;
    generateAndDownloadCsv: () => void;
}

const CartShare: React.FunctionComponent<IProps> = (props) => {
    const { config, generateAndDownloadCsv } = props;
    const { generateLinkUrl, labels } = config;

    const [showLinkInput, setShowLinkInput] = useState(false);

    return (
        <>
            {!showLinkInput && (
                <Buttons>
                    <Button
                        intent="secondary-muted"
                        size="small"
                        icon="share02"
                        title={labels.shareCart}
                        onClick={() => setShowLinkInput(true)}
                    />
                    <Button
                        intent="secondary-muted"
                        size="small"
                        title={labels.exportCsv}
                        onClick={() => generateAndDownloadCsv()}
                    />
                </Buttons>
            )}
            {showLinkInput && (
                <CartShareLinkInput generateLinkUrl={generateLinkUrl} labels={pick(labels, ['copyLink'])} />
            )}
        </>
    );
};

export default CartShare;
