import React from 'react';
import { IInvoice, InvoiceStatusEnum } from 'components/Customer/Client/clientOrders';

interface IProps {
    invoice: IInvoice;
    reorderLabel: string;
    awaitingFulfillmentLabel: string;
    processingLabel: string;
}

const InvoiceAction = (props: IProps) => {
    const { invoice, awaitingFulfillmentLabel, processingLabel, reorderLabel } = props;

    if (invoice.status === InvoiceStatusEnum.PAID) {
        return <a href={invoice.reorderLink}>{reorderLabel}</a>;
    }

    if (invoice.status === InvoiceStatusEnum.DEBT || invoice.status === InvoiceStatusEnum.INVOICED) {
        return <>{processingLabel}</>;
    }

    return <>{awaitingFulfillmentLabel}</>;
};

export default InvoiceAction;
