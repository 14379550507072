import React from 'react';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import IProduct from 'components/Catalog/interfaces/IProduct';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';

interface IProps {
    product: IProduct;
    notifyInStockLabel: string;
    alreadyHasStockNotificationLabel: string;
}

const ProductStockAlert = (props: IProps) => {
    const { product, notifyInStockLabel, alreadyHasStockNotificationLabel } = props;

    if (!product.hasStockAlert && !product.isSaleable && product.canAddToCart) {
        return (
            <Button
                intent="secondary-muted"
                size="small"
                icon="epharma-bell"
                title={notifyInStockLabel}
                layout="none"
                onClick={() => (window.location.href = product.stockAlertUrl)}
            />
        );
    }

    if (product.hasStockAlert && !product.isSaleable && product.canAddToCart) {
        return (
            <span className="button button-discrete size-small">
                <Icon width={16} height={16} kind={'epharma-check02'} />
                {alreadyHasStockNotificationLabel}
            </span>
        );
    }

    return <></>;
};

ProductStockAlert.displayName = 'Button';

export default ProductStockAlert;
