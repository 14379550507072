import { Dispatch } from 'redux';
import { ISelectedFilterItem, ISelectedFilters } from 'components/LayeredNavigation/store/selectedFiltersSelector';
import { dispatchType } from 'components/LayeredNavigation/store/selectedFiltersReducer';

export function initSelectedFilters(payload: { selectedFilters: ISelectedFilters }) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: dispatchType.FILTERS_INIT,
            payload,
        });
    };
}

export function addFilter(payload: { attributeCode: string; item: ISelectedFilterItem }, replace = false) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: dispatchType.FILTERS_ADD,
            payload: {
                ...payload,
                replace,
            },
        });
    };
}

export function removeFilter(payload: { attributeCode: string; item: ISelectedFilterItem }) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: dispatchType.FILTERS_REMOVE,
            payload,
        });
    };
}

export function updateFoundProductsCount(payload: { foundProductsCount: number }) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: dispatchType.UPDATE_FOUND_PRODUCTS_COUNT,
            payload,
        });
    };
}
