import { useCallback, useEffect } from 'react';
import { useRequest } from 'redux-query-react';
import GetCartRequest from 'components/CartView/requests/getCartRequest';

const CartEventListeners = () => {
    const [{}, summaryRequest] = useRequest(GetCartRequest);
    const reloadCartSummary = () => summaryRequest();

    const effect = useCallback(() => {
        void reloadCartSummary();
    }, [reloadCartSummary]);

    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    }, []);

    return null;
};
export default CartEventListeners;
