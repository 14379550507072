import { request } from 'data/requests/request';

const generateLinkRequest = (url: string) =>
    request({
        type: 'cartShareGenerateLinkRequest',
        url,
        method: 'GET',
        force: true,
        notApi: true,
        absolute: true,
    });

export default generateLinkRequest;

export interface ICartShareGenerateLinkResponse {
    error: number;
    shareLink?: string;
}
