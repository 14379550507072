import React from 'react';
import FormList from 'ekaubamaja-ui/lib/Components/FormList';
import FormListItem from 'ekaubamaja-ui/lib/Components/FormListItem';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import ControlPassword from 'components/GeneralForm/Field/ControlPassword';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { useMutation } from 'redux-query-react';
import loginRequest from 'components/Customer/requests/LoginRequest';
import * as Yup from 'yup';
import { useFormik } from 'formik';

interface IProps {
    onLogin: () => void;
    invalidEmailLabel: string;
    requiredFieldLabel: string;
    emailLabel: string;
    passwordLabel: string;
    showPasswordLabel: string;
    loginlabel: string;
    registerLabel: string;
    formConfig: {
        registerUrl: string;
        forgotPasswordUrl: string;
        imageUrl: string;
    };
    forgotPasswordLabel: string;
}

const EmailAuthForm = (props: IProps) => {
    const {
        invalidEmailLabel,
        requiredFieldLabel,
        emailLabel,
        passwordLabel,
        showPasswordLabel,
        loginlabel,
        registerLabel,
        forgotPasswordLabel,
        formConfig,
        onLogin,
    } = props;
    const [{}, userLoginRequest] = useMutation((email: string, password: string) => loginRequest(email, password));

    const FormDataValidationSchema = Yup.object().shape({
        email: Yup.string().email(invalidEmailLabel).nullable().required(requiredFieldLabel),
        password: Yup.string().nullable().required(requiredFieldLabel),
    });

    const formikEmail = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: FormDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: (formValues) => loginUser(formValues.email, formValues.password),
    });
    const loginUser = (email, password) => {
        void userLoginRequest(email, password).then((response) => {
            if (response.status === 400 || response.body.errors) {
                formikEmail.setFieldError('email', response.body.message);
            } else {
                onLogin();
            }
        });
    };

    return (
        <>
            <form onSubmit={formikEmail.handleSubmit}>
                <FormList className="form-list">
                    <FormListItem label={emailLabel} required={true} error={formikEmail.errors.email}>
                        <ControlInput
                            name="email"
                            value={formikEmail.values.email ?? ''}
                            onChange={formikEmail.handleChange}
                        />
                    </FormListItem>
                    <React.Fragment>
                        <FormListItem label={passwordLabel} required={true} error={formikEmail.errors.password}>
                            <ControlPassword
                                name="password"
                                value={formikEmail.values.password ?? ''}
                                onChange={formikEmail.handleChange}
                                showPasswordButtonLabel={showPasswordLabel}
                            />
                        </FormListItem>
                    </React.Fragment>
                </FormList>

                <Buttons>
                    <Button type="submit" intent="primary" title={loginlabel} />
                </Buttons>
                <ul className="buttons mobile-inline">
                    <li>
                        <a href={formConfig.registerUrl} className={'button button-discrete02'}>
                            {registerLabel}
                        </a>
                    </li>
                    <li>
                        <a href={formConfig.forgotPasswordUrl} className={'button button-discrete02'}>
                            {forgotPasswordLabel}
                        </a>
                    </li>
                </ul>
            </form>
        </>
    );
};

export default EmailAuthForm;
