import { request } from 'data/requests/request';

interface IData {
    signatureHex: string;
    sessionId: string;
}

const IdCardFinishRequest = (data: IData) =>
    request({
        type: 'idCardSignFinishRequest',
        url: '/digidoc/document/idCardSignFinish',
        method: 'GET',
        absolute: true,
        notApi: true,
        data,
    });

export default IdCardFinishRequest;
