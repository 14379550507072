import { request } from 'data/requests/request';

const saveCookieRequest = (cookies: Array<{ id: number; isChecked: boolean }>) =>
    request({
        type: 'saveCookieRequest',
        url: 'rpdg/cookieconsent/save',
        data: cookies,
        method: 'POST',
        notApi: true,
    });

export default saveCookieRequest;
