import React, { useEffect, useState } from 'react';
import FormListItem from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormListItem/index';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/CheckboxList/index';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel/index';

interface IProps {
    config: ICookieConsentForm;
}

export interface ICookieConsentForm {
    isGdprEnabled: boolean;
    gpdrBlockTitle?: string;
    gdprBlockDescription?: string;
    gdprCookies: IGdprCookie[];
}

export interface IGdprCookie {
    id: number;
    name: string;
    isRequired: boolean;
    isChecked: boolean;
}

const CookieConsent = (props: IProps): JSX.Element => {
    const { config } = props;

    const [cookieConsentStatus, setCookieConsentStatus] = useState<{ [key: number]: string }>({});

    useEffect(() => {
        const consentStatus = {};
        config.gdprCookies.forEach((cookie) => {
            consentStatus[cookie.id] = cookie.isChecked ? '1' : '0';
        });

        setCookieConsentStatus(consentStatus);
    }, [config.gdprCookies]);

    return (
        <FormListItem label={config.gpdrBlockTitle} isFieldSet={true} isCheckboxes={true} isSeparated={true}>
            <CheckboxList>
                {config.gdprCookies.map((cookie) => (
                    <React.Fragment key={cookie.id}>
                        <input type="hidden" name={`cookie[${cookie.id}]`} value={cookie.isRequired ? 1 : 0} />
                        <CheckboxWithLabel
                            label={cookie.name}
                            name={`cookie[${cookie.id}]`}
                            value={cookieConsentStatus[cookie.id]}
                            disabled={cookie.isRequired}
                            checked={cookieConsentStatus[cookie.id] === '1'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setCookieConsentStatus({
                                    ...cookieConsentStatus,
                                    [cookie.id]: e.target.checked ? '1' : '0',
                                });
                            }}
                        />
                    </React.Fragment>
                ))}
            </CheckboxList>
            {config.gdprBlockDescription && <RenderHTML html={config.gdprBlockDescription} />}
        </FormListItem>
    );
};

export default CookieConsent;
