import React, { useEffect, useRef, useState } from 'react';
import { pick } from 'lodash';
import classNames from 'classnames';
import IProduct from 'components/Catalog/interfaces/IProduct';
import Pager from 'components/ui/Pager';
import IProductListLabels from 'components/Catalog/interfaces/IProductListLabels';
import BoxProduct from 'components/Catalog/Product/List/BoxProduct';
import { productListModeSelector, TListMode } from 'components/Catalog/store/productListModeSelector';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'redux-query-react';
import ProductListRequest, { IProductListResponse } from 'components/Catalog/Product/requests/ProductListRequest';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent';
import ProductListHandler from '../../../helpers/productListHandler';
import { selectedFiltersSelector } from 'components/LayeredNavigation/store/selectedFiltersSelector';
import { updateFoundProductsCount } from 'components/LayeredNavigation/store/selectedFiltersHandler';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { useScrollRestoration, restoreScrollPosManually } from '../../../hooks/useScrollRestoration';

interface IProps {
    config: {
        products: IProduct[];
        defaultListMode: TListMode;
        currentCategoryId?: number;
        ajaxUrl: string;
        labels: IProductListLabels;
    };
}

const pageSize = 60;

const ProductList: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { currentCategoryId, labels } = config;

    const initialProducts = useRef<IProduct[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [{ isPending, isFinished }, productListRequest] = useMutation((url: string) => ProductListRequest(url));
    const { listMode } = useSelector(productListModeSelector);
    const { selectedFilters } = useSelector(selectedFiltersSelector);
    const dispatch = useDispatch();

    useScrollRestoration();

    const onPageChange = (pageNum: number) => {
        setPage(pageNum);
        handlePageHistoryChange(pageNum);

        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const updateTotalPageCount = (totalPageCount: number) => {
        setTotalPages(totalPageCount);

        //if total page count changes we have to update current page based on url param.
        const currentPage = new URLSearchParams(window.location.search).get('p') ?? '1';
        setPage(parseInt(currentPage));
    };

    const onPopState = () => {
        const currentPage = new URLSearchParams(window.location.search).get('p') ?? '1';

        setPage(parseInt(currentPage));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onFiltersUpdate = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const order = urlParams.get('product_list_order');
        const direction = urlParams.get('product_list_dir');

        if (Object.keys(selectedFilters).length) {
            setProducts(() => {
                const productHandler = new ProductListHandler(initialProducts.current);
                productHandler.applyFilters(selectedFilters);

                if (order && direction) {
                    productHandler.applySort(order, direction);
                }

                const filteredProducts = productHandler.getProducts();
                updateTotalPageCount(Math.ceil(filteredProducts.length / pageSize));

                window.dispatchEvent(
                    new CustomEvent('products-filtered', {
                        detail: {
                            products: filteredProducts,
                        },
                    }),
                );

                return filteredProducts;
            });
        } else {
            setProducts(() => {
                const productHandler = new ProductListHandler(initialProducts.current);

                if (order && direction) {
                    productHandler.applySort(order, direction);
                }

                window.dispatchEvent(
                    new CustomEvent('products-filtered', {
                        detail: {
                            products: productHandler.getProducts(),
                        },
                    }),
                );

                updateTotalPageCount(Math.ceil(productHandler.getProducts().length / pageSize));
                return productHandler.getProducts();
            });
        }
    };

    const handlePageHistoryChange = (pageNum: number) => {
        const pagerUrl = new URL(window.location.href);
        pagerUrl.searchParams.set('p', pageNum.toString());

        window.history.pushState({}, '', pagerUrl.href);
    };

    const loadProducts = () => {
        const currentUrl = new URL(window.location.href);
        const ajaxUrl = new URL(config.ajaxUrl);
        ajaxUrl.search = window.location.search;
        ajaxUrl.searchParams.delete('p');

        if (currentCategoryId) {
            ajaxUrl.searchParams.set('categoryId', currentCategoryId.toString());
        }

        void productListRequest(ajaxUrl.toString())
            .then((result) => {
                const response: IProductListResponse = result.body;

                setTotalPages(Math.ceil(response.products.length / pageSize));

                if (currentUrl.searchParams.has('p')) {
                    setPage(parseInt(currentUrl.searchParams.get('p') as string));
                }

                if (response.isDiscountCollectionTruncated) {
                    Toaster.addToast({ text: labels.discountCollectionTruncated, intent: 'danger' });
                }

                setProducts(() => response.products.sort((a, b) => Number(b.isSaleable) - Number(a.isSaleable)));
                initialProducts.current = response.products;
            })
            .then(() => {
                onFiltersUpdate();
                setTimeout(() => {
                    restoreScrollPosManually();
                }, 500);
            });
    };

    useEffect(() => {
        dispatch(updateFoundProductsCount({ foundProductsCount: products.length }));
    }, [products]);

    useEffect(() => {
        if (page > totalPages) {
            setPage(1);
        }
    }, [page]);

    useEffect(() => {
        loadProducts();

        window.addEventListener('popstate', onPopState);
        window.addEventListener('layered-navigation:filtersUpdated', onFiltersUpdate);
        return () => {
            window.removeEventListener('popstate', onPopState);
            window.removeEventListener('layered-navigation:filtersUpdated', onFiltersUpdate);
        };
    }, []);

    return (
        <>
            {isFinished && products.length === 0 && (
                <div className="generic-message">
                    <div className="generic-message__limiter">
                        <div className="generic-message__title">{labels.didntFindAnything}</div>
                        <div className="generic-message__description">{labels.trySomethingElse}</div>
                    </div>
                </div>
            )}
            {isPending && <LoadingContent layout="flex" size="large" />}
            {!isPending && (
                <>
                    <div
                        id="products"
                        className={classNames('layout-products', { horizontal: listMode === TListMode.LIST })}
                    >
                        <div className="layout-products__list light">
                            {products.slice((page - 1) * pageSize, page * pageSize).map((product) => (
                                <div className="layout-products__container" key={product.id}>
                                    <BoxProduct
                                        product={product}
                                        labels={pick(labels, [
                                            'addedToCart',
                                            'logInForPrices',
                                            'startingFrom',
                                            'addToCart',
                                            'quantity',
                                            'readMore',
                                            'outOfStock',
                                        ])}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {totalPages > 1 && (
                        <Pager
                            config={{
                                currentPage: page,
                                lastPage: totalPages,
                                pageParam: 'p',
                                labels: pick(labels, ['next', 'previous', 'page']),
                            }}
                            onChange={onPageChange}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ProductList;
