import React, { useState } from 'react';
import FormList from 'ekaubamaja-ui/lib/Components/FormList';
import FormListItem from 'ekaubamaja-ui/lib/Components/FormListItem';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { useMutation } from 'redux-query-react';
import MessageManager, { IMessage } from 'components/ui/MessageManager';
import authenticateStartRequest, {
    IAuthenticateStartResponse,
} from 'components/MobileId/requests/AuthenticateStartRequest';
import authenticateRequest, { IAuthenticateResponse } from 'components/MobileId/requests/AuthenticateRequest';
import ControlPhone from 'components/GeneralForm/Field/ControlPhone';

export interface IMobileIdConfig {
    isEnabled: boolean;
    labels: {
        personalCode: string;
        phoneNo: string;
        signIn: string;
        verificationCode: string;
        close: string;
        personalCodeIsRequired: string;
        phoneNoIsRequired: string;
        somethingWentWrong: string;
    };
}

interface IProps {
    config: IMobileIdConfig;
}

const MobileId: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { labels } = config;

    const [isPolling, setIsPolling] = useState(false);
    const [verificationCode, setVerificationCode] = useState<string>();
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [personalCode, setPersonalCode] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [{}, mobileIdAuthenticateStart] = useMutation(() => authenticateStartRequest());
    const [{}, mobileIdAuthenticate] = useMutation((personalCode: string, phoneNo: string) =>
        authenticateRequest(personalCode, phoneNo),
    );

    const validate = (): boolean => {
        if (personalCode.length === 0) {
            setMessages([
                {
                    intent: 'danger',
                    closable: true,
                    content: labels.personalCodeIsRequired,
                },
            ]);
            return false;
        }
        if (phoneNo.length === 0) {
            setMessages([
                {
                    intent: 'danger',
                    closable: true,
                    content: labels.phoneNoIsRequired,
                },
            ]);
            return false;
        }

        return true;
    };

    const onSubmit = (): void => {
        if (!validate()) {
            return;
        }

        void mobileIdAuthenticateStart()?.then((startResponse) => {
            setVerificationCode((startResponse.body as IAuthenticateStartResponse).verificationCode);

            void mobileIdAuthenticate(personalCode, phoneNo)?.then((authResponse) => {
                if (authResponse.status === 200) {
                    const response = authResponse.body as IAuthenticateResponse;

                    if (response.status === 1) {
                        window.location.href = '/mobileid/login';
                    } else {
                        setIsPolling(false);
                        setVerificationCode(undefined);
                        setMessages([
                            {
                                intent: 'danger',
                                closable: true,
                                content: response.message,
                            },
                        ]);
                    }
                } else {
                    setIsPolling(false);
                    setVerificationCode(undefined);
                    setMessages([
                        {
                            intent: 'danger',
                            closable: true,
                            content: labels.somethingWentWrong,
                        },
                    ]);
                }
            });
        });
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
            }}
        >
            {verificationCode && (
                <div className="notification-mobileid" role="alert">
                    {labels.verificationCode}: <strong>{verificationCode}</strong>
                </div>
            )}
            <FormList>
                <FormListItem label={labels.phoneNo} required>
                    <ControlPhone
                        name="phone_no"
                        phoneNo={''}
                        phoneNoPrefix={window.generalConfig?.defaultPhonePrefix ?? ''}
                        phonePrefixes={window.generalConfig?.phonePrefixes ?? []}
                        onChange={(result) => {
                            setPhoneNo(`${result.phoneNoPrefix}${result.phoneNo}`);
                        }}
                    />
                </FormListItem>
                <FormListItem label={labels.personalCode} required>
                    <ControlInput
                        name="personal_code"
                        value={personalCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPersonalCode(e.target.value)}
                    />
                </FormListItem>
            </FormList>
            <MessageManager messages={messages} labels={{ close: labels.close }} />
            <Buttons>
                <Button type="submit" intent="primary" title={labels.signIn} disabled={isPolling} />
            </Buttons>
        </form>
    );
};

export default MobileId;
