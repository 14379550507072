import React, { useState } from 'react';
import * as webeid from '@web-eid/web-eid-library/web-eid.js';
import Img from 'components/Image/Img';
import { useMutation } from 'redux-query-react';
import authenticateStartRequest, {
    IAuthenticateStartResponse,
} from 'components/IdCard/requests/AuthenticateStartRequest';
import authenticateRequest, { IAuthenticateResponse } from 'components/IdCard/requests/AuthenticateRequest';
import MessageManager, { IMessage } from 'components/ui/MessageManager';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';

export interface IIdCardConfig {
    isEnabled: boolean;
    labels: {
        idCard: string;
        close: string;
        somethingWentWrong: string;
    };
}

interface IProps {
    config: IIdCardConfig;
}

const IdCard: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { labels } = config;
    const [isPolling, setIsPolling] = useState(false);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [{}, idCardAuthenticateStart] = useMutation(() => authenticateStartRequest());
    const [{}, idCardAuthenticate] = useMutation((authToken: string) => authenticateRequest(authToken));

    const onAuthenticate = () => {
        setIsPolling(true);
        setMessages([]);

        void idCardAuthenticateStart().then((challengeResponse) => {
            const { nonce } = challengeResponse.body as IAuthenticateStartResponse;

            void webeid.authenticate(nonce, { lang: window.LOCALE ?? 'en' }).then((authToken) => {
                void idCardAuthenticate(JSON.stringify(authToken)).then((authResponse) => {
                    if (authResponse.status === 200) {
                        const response = authResponse.body as IAuthenticateResponse;

                        if (response.status === 1) {
                            window.location.href = '/idcard/login';
                        } else {
                            setIsPolling(false);
                            setMessages([
                                {
                                    intent: 'danger',
                                    closable: true,
                                    content: response.message,
                                },
                            ]);
                        }
                    } else {
                        setIsPolling(false);
                        setMessages([
                            {
                                intent: 'danger',
                                closable: true,
                                content: labels.somethingWentWrong,
                            },
                        ]);
                    }
                });
            });
        });
    };

    return (
        <>
            <Buttons>
                <Button
                    type="button"
                    className="button-idcard"
                    layout="none"
                    title={
                        <>
                            <Img iconName="icon_idcard" alt={labels.idCard} /> {labels.idCard}
                        </>
                    }
                    onClick={onAuthenticate}
                    disabled={isPolling}
                />
            </Buttons>
            <MessageManager messages={messages} labels={{ close: labels.close }} />
        </>
    );
};

export default IdCard;
