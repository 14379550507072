import React from 'react';
import SystemNotifications from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotifications/index';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification/index';
import useCartSummary from '../../../hooks/useCartSummary';
import ProductCampaign from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/ProductCampaign';

interface IProps {
    config: {
        cartNotification: ICartNotificationConfig;
        labels: {
            cartNotification: string;
            noProductsInCart: string;
            goToCheckout: string;
            enterStore: string;
            startShopping: string;
            here: string;
        };
    };
}

interface ICartNotificationConfig {
    checkoutUrl: string;
    cartLogoUrl: string;
    noProductsNotificationImage: string;
}

const DashBoardNotifications: React.FunctionComponent<IProps> = (props) => {
    const { products } = useCartSummary();
    const { config } = props;
    const { labels, cartNotification } = config;

    return (
        <>
            {!!products.length && (
                <SystemNotifications inline compact>
                    <SystemNotification
                        intent="success"
                        icon={{ file: cartNotification.cartLogoUrl }}
                        className="valign-center"
                        actions={[
                            {
                                intent: 'secondary',
                                title: labels.goToCheckout,
                                onClick: () => window.location.assign(cartNotification.checkoutUrl),
                            },
                        ]}
                    >
                        {labels.cartNotification.replace('%1', products.length.toString())}
                    </SystemNotification>
                </SystemNotifications>
            )}
            {!products.length && (
                <ProductCampaign
                    theme="info"
                    img={cartNotification.noProductsNotificationImage}
                    button={{
                        intent: 'primary',
                        type: 'anchor',
                        href: '/',
                        title: labels.enterStore,
                    }}
                >
                    <h2>{labels.noProductsInCart}</h2>
                    <p>
                        {`${labels.startShopping} `}
                        <a href="/">{labels.here}</a>
                    </p>
                </ProductCampaign>
            )}
        </>
    );
};

export default DashBoardNotifications;
