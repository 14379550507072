import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import * as React from 'react';
import Img from 'components/Image/Img';

interface IProductActionsProps {
    outOfStockLabel: string;
}

const OutOfStockButton: React.FunctionComponent<IProductActionsProps> = (props) => {
    const { outOfStockLabel } = props;

    return (
        <div className="product-actions">
            <div className="product-actions__row align-left">
                <Buttons>
                    <button className="button button-discrete">
                        <span>
                            <span className="icon">
                                <Img iconName="icon_lock" />
                            </span>
                            {outOfStockLabel}
                        </span>
                    </button>
                </Buttons>
            </div>
        </div>
    );
};

export default OutOfStockButton;
