import { request } from 'data/requests/request';

const UpdateCartRequest = (sku: string, qty: number) => {
    return request({
        type: 'cartMutationRequest',
        method: 'POST',
        url: 'basket/ajax/updateCartItem',
        data: { sku, qty },
        notApi: true,
    });
};

export default UpdateCartRequest;
