import { combineReducers } from 'redux';
import { entitiesReducer, queriesReducer } from 'redux-query';
import { OverlaysState } from 'data/overlays/interfaces';
import overlaysReducer from 'data/overlays/overlaysReducer';
import selectedFiltersReducer from 'components/LayeredNavigation/store/selectedFiltersReducer';
import { ISelectedFiltersState } from 'components/LayeredNavigation/store/selectedFiltersSelector';
import productListModeReducer from 'components/Catalog/store/productListModeReducer';
import { IProductListModeState } from 'components/Catalog/store/productListModeSelector';

export interface IStoreState {
    overlaysReducer: OverlaysState;
    selectedFiltersReducer: ISelectedFiltersState;
    productListModeReducer: IProductListModeState;
}

const combineRootReducer = combineReducers({
    entities: entitiesReducer,
    queries: queriesReducer,
    overlaysReducer,
    selectedFiltersReducer,
    productListModeReducer,
});

export default combineRootReducer;
