import { request } from 'data/requests/request';

export interface ISmartIdStartSignData {
    idCode: string;
}

const smartIdRequest = (data: ISmartIdStartSignData) =>
    request({
        type: 'smartIdSignRequest',
        url: '/digidoc/document/smartIdSign',
        method: 'POST',
        absolute: true,
        notApi: true,
        data,
    });

export default smartIdRequest;
