import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeOverlay, openOverlay } from 'data/actions/overlays';
import { IStoreState } from 'data/reducers/root';
import { includes } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import clientRequest from 'components/Customer/requests/ClientRequest';
import ClientSelectOverlay from 'components/Customer/Overlay/ClientSelectOverlay';
import AssortmentOverlay from 'components/Customer/Overlay/AssortmentOverlay';
import PriceListLoadingWrapper from 'components/Customer/Overlay/PriceListLoadingWrapper';

export interface IResponse {
    config: {
        welcomeLabel: string;
        clientCountLabel: string;
        clientSearchLabel: string;
        selectLabel: string;
        clientSelectFailedLabel: string;
        loadingLabel: string;
        loadingFailedLabel: string;
        loadingSuccessLabel: string;
        clients: IClient[];
        hasActiveClient: boolean;
        isPriceListLoaded: boolean;
        redirectUrl?: string;
    };
}

export interface IClient {
    id: string;
    name: string;
    code: string;
}

interface IProps {
    isStandalone?: boolean;
}

const mapStateToProps = (state): IResponse => {
    return {
        config: state.entities.clientRequest?.config,
    };
};

const ClientSelection: React.FunctionComponent<IProps> = (props) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);
    const [{ isFinished }, reloadClients] = useRequest(clientRequest());

    const { config } = useSelector(mapStateToProps);

    const [clientSelected, setClientSelected] = useState(false);
    const [isSwitchingClient, setIsSwitchingClient] = useState(false);
    const [forceOverlay, setForceOverlay] = useState(false);

    useEffect(() => {
        if (isFinished && (!config?.hasActiveClient || isSwitchingClient)) {
            dispatch(closeOverlay({ name: 'all' }));
            dispatch(openOverlay({ name: 'clientSelect' }));
        }

        if (isFinished && (config?.hasActiveClient || config?.clients?.length === 1) && !isSwitchingClient) {
            setClientSelected(true);
        }

        if (isFinished && config?.isPriceListLoaded && (includes(openOverlays, 'login') || props.isStandalone)) {
            window.location.href = config?.redirectUrl ?? window.BASE_URL;
        }
    }, [isFinished]);

    const effect = useCallback(() => {
        setIsSwitchingClient(true);
        dispatch(openOverlay({ name: 'clientSelect' }));
        reloadClients();
    }, [reloadClients]);

    useEffect(() => {
        window.addEventListener('switchClient', effect);
        return function cleanup() {
            window.removeEventListener('switchClient', effect);
        };
    }, []);

    const startClientSelect = () => {
        dispatch(closeOverlay({ name: 'clientSelect' }));
    };

    const endClientSelect = () => {
        setForceOverlay(true);
        setClientSelected(true);
    };

    const isLoadingPriceList =
        forceOverlay ||
        (((!config?.hasActiveClient && !config?.isPriceListLoaded) ||
            (config?.hasActiveClient && !config?.isPriceListLoaded)) &&
            clientSelected &&
            isFinished);

    return (
        <React.Fragment>
            {isLoadingPriceList && (
                <PriceListLoadingWrapper
                    loadingLabel={config?.loadingLabel || ''}
                    loadingFailedLabel={config?.loadingFailedLabel || ''}
                    loadingSuccessLabel={config?.loadingSuccessLabel || ''}
                    clientSelected={forceOverlay}
                />
            )}
            {config?.clients && (
                <ClientSelectOverlay
                    clients={config.clients}
                    clientCountLabel={config.clientCountLabel}
                    clientSearchLabel={config.clientSearchLabel}
                    clientSelectFailedLabel={config.clientSelectFailedLabel}
                    selectLabel={config.selectLabel}
                    welcomeLabel={config.welcomeLabel}
                    setClientSelected={endClientSelect}
                    startClientSelect={startClientSelect}
                />
            )}
            <AssortmentOverlay loadingLabel={config?.loadingLabel || ''} />
        </React.Fragment>
    );
};

export default ClientSelection;
