import * as React from 'react';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { includes } from 'lodash';
import { Simulate } from 'react-dom/test-utils';
import input = Simulate.input;
import { useKeypress } from 'ekaubamaja-ui/lib/Utilities/Generic';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { useCallback, useState } from 'react';
import Result from 'components/Catalog/Search/Results';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import classNames from 'classnames';

interface IProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config: {
        output: 'field' | 'button';
        placeholder: string;
        label: string;
        iconToggle: string;
        action: string;
        iconSearch: string;
        showAllLabel: string;
        noResultsFound: string;
        minQuery: number;
        maxQuery: number;
    };
}

const Search = (props: IProps) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);

    const openSearch = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'search-mobile' }));
    };

    const closeSearch = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    const [str, setStr] = useState(new URLSearchParams(window.location.search).get('q') ?? '');
    const [queryString, setQueryString] = useState(new URLSearchParams(window.location.search).get('q') ?? '');
    const [focused, setFocused] = useState(false);
    const [wrapperFocused, setWrapperFocused] = useState(false);

    const debouncedSave = useCallback(
        debounce((nextValue) => {
            setQueryString(nextValue.substring(0, props.config.maxQuery ?? 128));
        }, 500),
        [],
    );

    useKeypress(
        'Escape',
        () => {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
                setStr('');
                debouncedSave('');
                closeSearch();
            }
        },
        [str],
    );

    const handleFocus = () => {
        setFocused(true);
    };
    const handleBlur = () => {
        setFocused(false);
    };
    const handleWrapperFocus = () => {
        setWrapperFocused(true);
    };
    const handleWrapperBlur = () => {
        setWrapperFocused(false);
    };

    const RenderField = (autofocus: boolean, results: boolean) => {
        return (
            <div
                className={classNames('search__inner', { active: focused })}
                tabIndex={0}
                onFocus={handleWrapperFocus}
                onBlur={handleWrapperBlur}
            >
                <label>
                    <input
                        type="text"
                        autoComplete="off"
                        autoFocus={autofocus}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        name="q"
                        value={str}
                        placeholder={props.config?.placeholder}
                        onChange={(e) => {
                            setStr(e.target.value);
                            debouncedSave(e.target.value);
                        }}
                    />
                    <span className="label">{props.config?.label || ''}</span>
                    <button type="submit" className="icon search__submit" onFocus={handleFocus} onBlur={handleBlur}>
                        <img src={props.config.iconSearch || ''} alt={props.config.label || ''} />
                    </button>
                </label>
                {queryString.length >= props.config.minQuery && results && (
                    <Result
                        queryString={queryString}
                        showAllLabel={props.config.showAllLabel}
                        noResultsFound={props.config.noResultsFound}
                        isFocused={wrapperFocused}
                    />
                )}
            </div>
        );
    };

    if (props.config.output === 'button') {
        return (
            <React.Fragment>
                <a href="#" className="toggler" onClick={openSearch}>
                    <span className="icon">
                        <img src={props.config.iconToggle} alt={props.config.label} />
                    </span>
                    <span>{props.config.label}</span>
                </a>
                <Overlay
                    isOpen={includes(openOverlays, 'search-mobile')}
                    doClose={closeSearch}
                    layout="focusview"
                    autoFocus={false}
                    size="max"
                    className="site-menu-mobile overlay-search"
                    customHeader={
                        <div className="site-menu-mobile-heading right">
                            <button onClick={closeSearch}>
                                <Icon kind="close03" width={16} height={16} />
                            </button>
                        </div>
                    }
                >
                    <div className="search-mobile-content">
                        <form className="search" action={props.config.action} method="get" role="search">
                            {RenderField(true, true)}
                        </form>
                    </div>
                </Overlay>
            </React.Fragment>
        );
    }

    if (props.config.output === 'field') {
        return RenderField(false, true);
    }

    return null;
};

export default Search;
