export enum dispatchType {
    // Overlays

    OVERLAYS_OPEN = 'OVERLAYS_OPEN',
    OVERLAYS_CLOSE = 'OVERLAYS_CLOSE',

    // Session

    LOGOUT = 'LOGOUT',
    LOGIN_START = 'LOGIN_START',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_CANCEL_SUCCESS = 'LOGIN_CANCEL_SUCCESS',

    // Available profiles

    PROFILES_REQUEST = 'PROFILES_REQUEST',
    PROFILES_LOAD_SUCCESS = 'PROFILES_LOAD_SUCCESS',
    PROFILES_EDIT_SUCCESS = 'PROFILES_EDIT_SUCCESS',

    // Settings

    SETTINGS_REQUEST = 'SETTINGS_REQUEST',
    SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS',
    SETTINGS_EDIT_SUCCESS = 'SETTINGS_EDIT_SUCCESS',

    // Pages

    PAGE_REQUEST = 'PAGE_REQUEST',
    PAGE_LOAD_SUCCESS = 'PAGE_LOAD_SUCCESS',
}
