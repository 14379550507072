import { default as React, useState } from 'react';

import Button from 'ekaubamaja-ui/lib/Components/Button';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import FormListItem from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormListItem/index';
import FormList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormList/index';
import { useMutation } from 'redux-query-react';
import ControlPhone from 'components/GeneralForm/Field/ControlPhone';
import mobileIdRequest, { IMobileIdStartSignData } from 'components/Checkout/requests/signing/general/mobileIdRequest';
import { closeOverlay, openOverlay } from 'data/actions/overlays';
import { useDispatch } from 'react-redux';
import mobileIdFinishRequest from 'components/Checkout/requests/signing/general/mobileIdFinishRequest';
import { HttpStatusCode } from '../../../../enums/HttpStatusCode';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import useDownloader from 'react-use-downloader';

interface ISignFinishProps {
    idCode: string;
    phoneNumber: string;
    sessionId: string;
}

interface IProps {
    downloadFile: () => void;
    personalCodeLabel: string;
    signLabel: string;
    downloadFileLabel: string;
    phoneNumberLabel: string;
    verificationCodeLabel: string;
    signingFailedLabel: string;
    submitOrder: () => void;
}

const SignWithMobileIdForm: React.FunctionComponent<IProps> = (props) => {
    const {
        downloadFile,
        personalCodeLabel,
        signLabel,
        downloadFileLabel,
        phoneNumberLabel,
        verificationCodeLabel,
        signingFailedLabel,
        submitOrder,
    } = props;

    const [{}, mobileIdSignRequest] = useMutation((data: IMobileIdStartSignData) => mobileIdRequest(data));
    const [{}, mobileIdSignFinishRequest] = useMutation((data: ISignFinishProps) => mobileIdFinishRequest(data));

    const [idCode, setIdCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isSigning, setIsSigning] = useState(false);

    const dispatch = useDispatch();
    const { download } = useDownloader();

    const sign = async () => {
        setIsSigning(true);

        await mobileIdSignRequest({ idCode: idCode, phoneNumber: phoneNumber }).then((res) => {
            if (res.status === HttpStatusCode.OK) {
                setVerificationCode(res?.body?.verificationCode);

                void mobileIdSignFinishRequest({
                    idCode: idCode,
                    phoneNumber: phoneNumber,
                    sessionId: res?.body?.sessionId,
                }).then((res) => {
                    if (res.status === HttpStatusCode.OK) {
                        download(res?.body?.downloadUrl, res?.body?.fileName);

                        dispatch(closeOverlay({ name: 'all' }));
                        submitOrder();
                    } else {
                        setVerificationCode('');
                        Toaster.addToast({ text: res?.body?.message || signingFailedLabel, intent: 'danger' });
                        setIsSigning(false);
                    }
                });
            } else {
                setVerificationCode('');
                Toaster.addToast({ text: res?.body?.message || signingFailedLabel, intent: 'danger' });
                setIsSigning(false);
            }
        });
    };

    return (
        <React.Fragment>
            {verificationCode !== '' && (
                <div className="notification-mobileid" role="alert">
                    {verificationCodeLabel}: <strong>{verificationCode}</strong>
                </div>
            )}
            <FormList>
                <FormListItem label={personalCodeLabel} required={true}>
                    <ControlInput
                        value={idCode}
                        onChange={(e) => {
                            setIdCode(e.target.value);
                        }}
                    />
                </FormListItem>
                <FormListItem label={phoneNumberLabel} required={true}>
                    <ControlPhone
                        phoneNo={''}
                        phoneNoPrefix={window.generalConfig?.defaultPhonePrefix ?? ''}
                        phonePrefixes={window.generalConfig?.phonePrefixes ?? []}
                        onChange={(result) => {
                            setPhoneNumber(`${result.phoneNoPrefix}${result.phoneNo}`);
                        }}
                    />
                </FormListItem>
            </FormList>
            <Buttons>
                <Button intent="primary" title={signLabel} onClick={sign} disabled={isSigning} loading={isSigning} />
                <Button title={downloadFileLabel} icon="download" layout="link" size="xsmall" onClick={downloadFile} />
            </Buttons>
        </React.Fragment>
    );
};

export default SignWithMobileIdForm;
