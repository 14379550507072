import { cloneDeep } from 'lodash';
import {
    ISelectedFiltersState,
    ISelectedFilters,
    ISelectedFilterItem,
} from 'components/LayeredNavigation/store/selectedFiltersSelector';

export enum dispatchType {
    FILTERS_INIT = 'FILTERS_INIT',
    FILTERS_ADD = 'FILTERS_ADD',
    FILTERS_REMOVE = 'FILTERS_REMOVE',
    UPDATE_FOUND_PRODUCTS_COUNT = 'UPDATE_FOUND_PRODUCTS_COUNT',
}

const initialState: ISelectedFiltersState = {
    selectedFilters: <ISelectedFilters>{},
    foundProductsCount: 0,
};

interface ISelectedFiltersAction {
    type: dispatchType;
    payload: any;
}

const selectedFiltersReducer = (
    state: ISelectedFiltersState = cloneDeep(initialState),
    action: ISelectedFiltersAction,
): ISelectedFiltersState => {
    const handleInit = (payload: { selectedFilters: ISelectedFilters }) => {
        state.selectedFilters = payload.selectedFilters;

        return {
            ...state,
        };
    };

    const handleAddFilter = (payload: { attributeCode: string; item: ISelectedFilterItem; replace?: boolean }) => {
        const attributeCode = payload.attributeCode;
        const item = payload.item;
        let selectedOptions: ISelectedFilterItem[] = [];

        if (state.selectedFilters[attributeCode] && !payload.replace) {
            selectedOptions = state.selectedFilters[attributeCode];
        }

        selectedOptions.push(item);

        state.selectedFilters[attributeCode] = selectedOptions;

        return {
            ...state,
        };
    };

    const handleRemoveFilter = (payload: { attributeCode: string; item: ISelectedFilterItem }) => {
        const attributeCode = payload.attributeCode;
        const item = payload.item;

        state.selectedFilters[attributeCode] = state.selectedFilters[attributeCode].filter((selectedFilterItem) => {
            return selectedFilterItem.value !== item.value;
        });

        if (!state.selectedFilters[attributeCode].length) {
            delete state.selectedFilters[attributeCode];
        }

        return {
            ...state,
        };
    };

    const updateFoundProductsCount = (payload: { foundProductsCount: number }) => {
        state.foundProductsCount = payload.foundProductsCount;

        return {
            ...state,
        };
    };

    switch (action.type) {
        case dispatchType.FILTERS_INIT: {
            return handleInit(action.payload);
        }
        case dispatchType.FILTERS_ADD: {
            return handleAddFilter(action.payload);
        }
        case dispatchType.FILTERS_REMOVE: {
            return handleRemoveFilter(action.payload);
        }
        case dispatchType.UPDATE_FOUND_PRODUCTS_COUNT: {
            return updateFoundProductsCount(action.payload);
        }
        default: {
            return state;
        }
    }
};

export default selectedFiltersReducer;
