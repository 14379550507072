import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRequest } from 'redux-query-react';
import consentRequest, { ICustomerConsentResponse } from 'components/Gdpr/requests/consentRequest';
import { useSelector } from 'react-redux';
import { CookieType } from 'components/Gdpr/enums/CookieType';
import useAuth from '../../hooks/useAuth';

const mapStateToProps = (state) => {
    return {
        customerConsent: state.entities.customerConsentRequest as ICustomerConsentResponse,
    };
};

const ConsentHandler = (): JSX.Element => {
    const gdprCookieToken = Cookies.get('gdpr_cookie_token');
    const [{ isFinished, status }] = useRequest(consentRequest(gdprCookieToken ?? ''));
    const { customer } = useAuth();
    const { customerConsent } = useSelector(mapStateToProps);

    useEffect(() => {
        if (isFinished && status === 200 && !customerConsent.errors) {
            const gdprScriptNodes = document.querySelectorAll('script[type="text/x-magento-gdpr"]');

            gdprScriptNodes.forEach((scriptElement) => {
                const cookieCode = scriptElement.getAttribute('data-consent');

                if (hasConsent(cookieCode as CookieType)) {
                    const approvedScriptElement = document.createElement('script');
                    approvedScriptElement.setAttribute('type', 'text/javascript');
                    approvedScriptElement.innerHTML = scriptElement.innerHTML;

                    scriptElement.after(approvedScriptElement);

                    if (scriptElement.parentElement) {
                        scriptElement.parentElement.removeChild(scriptElement);
                    }
                }
            });
        }
    }, [isFinished, status]);

    const hasConsent = (cookieCode: CookieType) => {
        const consent = customerConsent.data.find((consent) => consent.code === cookieCode);

        return consent?.status;
    };

    return <React.Fragment />;
};

export default ConsentHandler;
