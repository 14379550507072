import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormKey from 'components/GeneralForm/Field/FormKey';
import FormList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormList/index';
import FormListItem from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormListItem/index';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons/index';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
        };
        labels: {
            requiredField: string;
            yourEmail: string;
            resetMyPassword: string;
        };
    };
}

const ForgotPassword = (props: IProps): JSX.Element => {
    const { config } = props;

    const formElement = useRef<HTMLFormElement>(null);
    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object().shape({
            email: Yup.string().nullable().required(config.labels.requiredField),
        }),
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    return (
        <form action={config.formConfig.actionUrl} method="post" ref={formElement} onSubmit={formik.handleSubmit}>
            <FormKey />
            <FormList separated>
                <FormListItem label={config.labels.yourEmail} required={true} error={formik.errors.email}>
                    <ControlInput name="email" value={formik.values.email} onChange={formik.handleChange} />
                </FormListItem>
            </FormList>

            <Buttons layout={'vertical-wide'}>
                <Button type="submit" intent="primary" title={config.labels.resetMyPassword} />
            </Buttons>
        </form>
    );
};

export default ForgotPassword;
