import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import Img from 'components/Image/Img';
import { closeOverlay, openOverlay } from 'data/actions/overlays';

interface ILoginAreaProps {
    config: {
        registerUrl: string;
        labels: {
            enterLabel: string;
            loginLabel: string;
            registerLabel: string;
        };
    };
}

const LoginArea: React.FunctionComponent<ILoginAreaProps> = (props) => {
    const { labels } = props.config;
    const dispatch = useDispatch();

    const openLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'login' }));
    };

    return (
        <React.Fragment>
            <div className="login-area__inner">
                <p>
                    <span className="icon">
                        <Img iconName="icon_user01" alt="" />
                    </span>
                    <span>{labels.enterLabel}</span>
                </p>

                <Buttons>
                    <Button intent="primary" icon="lock" title={labels.loginLabel} onClick={openLogin} />
                    <Button
                        type="anchor"
                        intent="secondary"
                        title={labels.registerLabel}
                        href={props.config.registerUrl}
                    />
                </Buttons>
            </div>
        </React.Fragment>
    );
};

export default LoginArea;
