import { request } from 'data/requests/request';

interface IData {
    idCode: string;
    sessionId: string;
}

const smartIdFinishRequest = (data: IData) =>
    request({
        type: 'smartIdFinishRequest',
        url: '/digidoc/document/smartIdFinish',
        method: 'GET',
        absolute: true,
        notApi: true,
        data,
    });

export default smartIdFinishRequest;
