import React from 'react';
import classNames from 'classnames';
import IProductBadge from 'components/Catalog/interfaces/IProductBadge';

interface IProps {
    badges: IProductBadge[];
}

const ProductBadges: React.FunctionComponent<IProps> = (props) => {
    const { badges } = props;

    return (
        <div className="product-badges">
            <div className="product-badges__group">
                {badges.map((badge) => (
                    <div className={classNames('product-badges__badge-circle', badge.theme)}>{badge.label}</div>
                ))}
            </div>
        </div>
    );
};

export default ProductBadges;
