import React from 'react';
import { IPersonalOffersConfig } from 'components/Customer/SelfService/DashBoardSidebar';
import Button from 'ekaubamaja-ui/lib/Components/Button';

interface IProps {
    personalOffer: IPersonalOffersConfig;
    personalOffersLabel: string;
    lookAtOffersLabel: string;
}

const DashBoardPersonalOffers: React.FunctionComponent<IProps> = (props) => {
    const { personalOffer, personalOffersLabel, lookAtOffersLabel } = props;

    return (
        <div
            className={`content-card margt ${personalOffer.image ? 'has-background' : 'campaign03'}`}
            style={{ backgroundImage: `url(${personalOffer.image ?? ''})` }}
        >
            <div className="content">
                <h2>{personalOffersLabel}</h2>
                <p>{personalOffer.text}</p>
                <Button intent="primary" title={lookAtOffersLabel} type="anchor" href={personalOffer.url} />
            </div>
        </div>
    );
};

export default DashBoardPersonalOffers;
