import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeOverlay, openOverlay } from 'data/actions/overlays';
import { IStoreState } from 'data/reducers/root';
import Img from 'components/Image/Img';

export interface IProps {
    config: {
        loginLabel: string;
    };
}

const LoginButton = (props: IProps) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const openLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'login' }));
    };

    return (
        <button className="toggler" onClick={openLogin}>
            <span className="icon">
                <Img iconName={'icon_lock'} />
            </span>
            <span className="label">
                <span className="account-title">{props.config.loginLabel}</span>
            </span>
        </button>
    );
};

LoginButton.displayName = 'LoginButtonHeader';

export default LoginButton;
