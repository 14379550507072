import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    ICartProduct,
    ICartSummary,
    ICartTotals,
    SubmitActionEnum,
} from 'components/CartView/interfaces/CartInterfaces';

const mapStateToProps = (state) => {
    return {
        cartSummary: state.entities?.cartSummary as ICartSummary,
    };
};

const useCartSummary = (): ICartSummary => {
    const { cartSummary } = useSelector(mapStateToProps);
    const [products, setProducts] = useState<ICartProduct[]>([]);
    const [cartTotals, setCartTotals] = useState<ICartTotals>();
    const [orderNumber, setOrderNumber] = useState('');
    const [submitAction, setSubmitAction] = useState(SubmitActionEnum.NOT_ALLOWED_TO_SUBMIT);
    const [isFinished, setIsFinished] = useState(false);
    const [minOrderSum, setMinOrderSum] = useState(0);

    useEffect(() => {
        if (cartSummary) {
            setProducts(cartSummary.products);
            setCartTotals(cartSummary.cartTotals);
            setOrderNumber(cartSummary.orderNumber);
            setSubmitAction(cartSummary.submitAction);
            setIsFinished(true);
            setMinOrderSum(cartSummary.minOrderSum);
        }
    }, [cartSummary]);

    return {
        products,
        cartTotals,
        orderNumber,
        submitAction,
        isFinished,
        minOrderSum,
    };
};

export default useCartSummary;
