import * as React from 'react';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent/index';
import SearchResultsList from 'components/Catalog/Search/SearchResultsList';

export interface IProps {
    queryString: string;
    showAllLabel: string;
    noResultsFound: string;
    isFocused: boolean;
}

export type ISearchResultItemType = 'category' | 'product' | 'product_attribute';

interface SearchResponse {
    results: SearchResponseItem[];
}

export interface SearchResponseItem {
    title: string;
    url: string;
    price?: number;
    specialPrice?: number;
    image: string;
    type: ISearchResultItemType;
    label?: string;
    attribute_label?: string;
    categories?: string[];
}

const mapStateToProps = (state): SearchResponse => {
    return {
        results: state.entities.quickSearch,
    };
};
const Results = (props: IProps) => {
    const { queryString } = props;
    const [{ isFinished }] = useRequest(
        request({ type: 'quickSearch', url: `search/ajax/suggest?q=${queryString}`, notApi: true, force: true }),
    );
    const { results } = useSelector(mapStateToProps);

    return (
        <React.Fragment>
            {isFinished && (
                <SearchResultsList
                    isFocused={props.isFocused}
                    results={results}
                    noResultsFound={props.noResultsFound}
                    showAllLabel={props.showAllLabel}
                />
            )}
            {!isFinished && <LoadingContent layout="absolute" />}
        </React.Fragment>
    );
};

export default Results;
