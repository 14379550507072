import Img from 'components/Image/Img';
import * as React from 'react';
import { ProductMetaAttribute } from 'components/Catalog/Product/ProductDetailView';

interface IProductAttributesProps {
    packageType?: string;
    meta: ProductMetaAttribute[];
}

const ProductMetaAttributes: React.FunctionComponent<IProductAttributesProps> = (props) => {
    const { packageType, meta } = props;

    return (
        <React.Fragment>
            {packageType && (
                <p className="product-meta">
                    <Img iconName="icon_meta01" alt={packageType} />
                    {packageType}
                </p>
            )}
            <ul className="layout-product__meta02">
                {meta.map((meta, i) => (
                    <li key={i}>
                        {meta.label} <strong>{meta.value}</strong>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
};

export default ProductMetaAttributes;
