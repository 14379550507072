import React, { useState } from 'react';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect';

interface IProps {
    name?: string;
    onChange?: (output: { phoneNoPrefix: string; phoneNo: string }) => void;
    phoneNoPrefix: string;
    phoneNo: string;
    phonePrefixes: string[];
    testId?: string;
}

const ControlPhone: React.FunctionComponent<IProps> = (props) => {
    const { name, phonePrefixes } = props;

    const [phoneNo, setPhoneNo] = useState(props.phoneNo);
    const [phoneNoPrefix, setPhoneNoPrefix] = useState(props.phoneNoPrefix);

    return (
        <div className="form-control-phone">
            <ControlSelect
                value={phoneNoPrefix}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setPhoneNoPrefix(e.target.value);

                    if (props.onChange) {
                        props.onChange({ phoneNoPrefix: e.target.value, phoneNo });
                    }
                }}
            >
                {phonePrefixes.map((prefix) => (
                    <option key={prefix} value={prefix}>
                        {prefix}
                    </option>
                ))}
            </ControlSelect>
            <ControlInput
                name={name}
                value={phoneNo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPhoneNo(e.target.value);

                    if (props.onChange) {
                        props.onChange({ phoneNoPrefix, phoneNo: e.target.value });
                    }
                }}
                testId={props.testId}
            />
        </div>
    );
};

export default ControlPhone;
