import Button from 'ekaubamaja-ui/lib/Components/Button';
import React, { useEffect, useState } from 'react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import { ICartProduct } from 'components/CartView/interfaces/CartInterfaces';

interface IProps {
    product: ICartProduct;
    updateProduct: (number) => void;
    labels: {
        quantityLabel: string;
        updateLabel: string;
        invalidQuantity: string;
    };
}

const CartProductQuantity: React.FunctionComponent<IProps> = (props) => {
    const { product, updateProduct, labels } = props;
    const { quantityLabel, updateLabel } = labels;
    const [qty, setQty] = useState<number>(product.quantity);

    useEffect(() => {
        setQty(product.quantity);
    }, [product.quantity]);

    return (
        <div className="box-product__quantity-wrapper">
            <div className="box-product__quantity">
                <label>{quantityLabel}:</label>
                <input
                    type="number"
                    value={qty > 0 ? qty : ''}
                    step="1"
                    aria-label={quantityLabel}
                    onChange={(e) => {
                        setQty(parseInt(e.target.value));
                    }}
                    disabled={product.isSpecialOffer}
                />
            </div>
            {product.quantity !== qty && (
                <Button
                    intent={'secondary-muted'}
                    size={'xsmall'}
                    title={updateLabel}
                    onClick={(e) => {
                        e.preventDefault();
                        if (qty && qty > 0) {
                            updateProduct(qty);
                        } else {
                            setQty(product.quantity);
                            Toaster.addToast({ text: labels.invalidQuantity, intent: 'danger' });
                        }
                    }}
                />
            )}
        </div>
    );
};

export default CartProductQuantity;
