import IProduct from 'components/Catalog/interfaces/IProduct';
import { remove } from 'lodash';
import { ISelectedFilterItem, ISelectedFilters } from 'components/LayeredNavigation/store/selectedFiltersSelector';

export default class ProductListHandler {
    products: IProduct[];
    mappedAttributes: { [key: string]: string } = {
        discount_percent: 'discountPercent',
        active_substance: 'activeSubstance',
        created_at: 'createdAt',
    };

    constructor(products: IProduct[]) {
        this.products = [...products];
    }

    applyFilters(selectedFilters: ISelectedFilters): ProductListHandler {
        Object.keys(selectedFilters).forEach((filterAttribute) => {
            const filters: ISelectedFilterItem[] = selectedFilters[filterAttribute];

            filters.forEach((filter) => {
                switch (filterAttribute) {
                    case 'cat':
                        remove(this.products, (product) => !product.categoryIds.includes(parseInt(filter.value)));
                        break;
                    case 'brand':
                        remove(this.products, (product) => product.brandId !== parseInt(filter.value));
                        break;
                    case 'active_substance':
                        remove(this.products, (product) => product.activeSubstanceId !== parseInt(filter.value));
                        break;
                    case 'producer':
                        remove(this.products, (product) => product.producerId !== parseInt(filter.value));
                        break;
                    case 'price':
                        {
                            const priceFilter = filter.value.split('-');
                            const minPrice = parseFloat(priceFilter[0]);
                            const maxPrice = parseFloat(priceFilter[1]);

                            remove(this.products, (product) => {
                                return product.price < minPrice || product.price > maxPrice;
                            });
                        }
                        break;
                }
            });
        });

        return this;
    }

    applySort(order: string, direction: string): ProductListHandler {
        const orderBy = this.mappedAttributes[order] ?? order;

        switch (orderBy) {
            case 'price':
                this.products.sort((a, b) => {
                    if (direction === 'desc') {
                        return Number(b.isSaleable) - Number(a.isSaleable) || (a.price > b.price ? -1 : 1);
                    }

                    return Number(b.isSaleable) - Number(a.isSaleable) || (a.price > b.price ? 1 : -1);
                });
                break;
            case 'discountPercent':
                this.products.sort((a, b) => {
                    if (direction === 'desc') {
                        return (
                            Number(b.isSaleable) - Number(a.isSaleable) ||
                            (a.discountPercent > b.discountPercent ? -1 : 1)
                        );
                    }

                    return (
                        Number(b.isSaleable) - Number(a.isSaleable) || (a.discountPercent > b.discountPercent ? 1 : -1)
                    );
                });
                break;
            case 'createdAt':
                this.products.sort((a, b) => {
                    const aDate = new Date(a.createdAt);
                    const bDate = new Date(b.createdAt);

                    if (direction === 'desc') {
                        return Number(b.isSaleable) - Number(a.isSaleable) || bDate.getTime() - aDate.getTime();
                    }

                    return Number(b.isSaleable) - Number(a.isSaleable) || aDate.getTime() - bDate.getTime();
                });
                break;
            default:
                this.products.sort((a, b) => {
                    if (a[orderBy] && b[orderBy]) {
                        if (direction === 'desc') {
                            return Number(b.isSaleable) - Number(a.isSaleable) || b[orderBy].localeCompare(a[orderBy]);
                        }

                        return Number(b.isSaleable) - Number(a.isSaleable) || a[orderBy].localeCompare(b[orderBy]);
                    }

                    return 0;
                });
        }

        return this;
    }

    getProducts(): IProduct[] {
        return this.products;
    }
}
