import React from 'react';
import classNames from 'classnames';
import IProductBadge from 'components/Catalog/interfaces/IProductBadge';

interface IProps {
    badges: IProductBadge[];
}

const ProductBadges: React.FunctionComponent<IProps> = (props) => {
    const { badges } = props;

    return (
        <div className="box-product__badges box-product__badges-circle">
            {badges.map((badge) => (
                <div className={classNames('box-product__badge-circle', badge.theme)}>{badge.label}</div>
            ))}
        </div>
    );
};

export default ProductBadges;
