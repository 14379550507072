import React from 'react';
import classNames from 'classnames';
import Img from 'components/Image/Img';
import { ILabel } from '../../DetailView/ProductLabels';

interface IProps {
    labels: ILabel[];
}

const ProductLabels: React.FunctionComponent<IProps> = (props) => {
    const { labels } = props;

    return (
        <div className="box-product__badges box-product__badges-square">
            {labels.map((label) => (
                <div className={classNames('box-product__badge-square', label.theme)}>
                    {label.icon && <Img iconName={label.icon} />}
                    {label.label}
                </div>
            ))}
        </div>
    );
};

export default ProductLabels;
