export const exceptionHandler = (store) => (next) => (action) => {
    try {
        return next(action);
    } catch (err) {
        /* tslint:disable */
        // noinspection TsLint
        console.error('Exception handler middleware: Caught an exception!', err);
        /* Raven.captureException(err, {
            extra: {
                action,
                state: store.getState(),
            },
        }); */
        /* tslint:enable */
        throw err;
    }
};
