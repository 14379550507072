interface IStoreWithSelectedFilters {
    selectedFiltersReducer: ISelectedFiltersState;
}

export interface ISelectedFiltersState {
    selectedFilters: ISelectedFilters;
    foundProductsCount: number;
}

export interface ISelectedFilters {
    [key: string]: ISelectedFilterItem[];
}

export interface ISelectedFilterItem {
    label: string;
    value: string;
}

export const selectedFiltersSelector = (store: IStoreWithSelectedFilters) => {
    return store.selectedFiltersReducer;
};
