import { request } from 'data/requests/request';

const clientRequest = () =>
    request({
        type: 'clientRequest',
        url: 'clients/client/index',
        method: 'GET',
        notApi: true,
    });

export default clientRequest;
