import { request } from 'data/requests/request';

interface IData {
    idCode: string;
    phoneNumber: string;
    sessionId: string;
}

const mobileIdFinishRequest = (data: IData) =>
    request({
        type: 'mobileIdFinishRequest',
        url: '/digidoc/document/mobileIdFinish',
        method: 'GET',
        absolute: true,
        notApi: true,
        data,
    });

export default mobileIdFinishRequest;
