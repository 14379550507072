import * as React from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { request } from 'data/requests/request';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { useEffect } from 'react';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML/index';

interface IProps {
    loadingLabel: string;
    loadingFailedLabel: string;
    loadingSuccessLabel: string;
}

interface IPriceListIndexResponse {
    success: boolean;
    forceRefresh?: boolean;
    positionInQueue?: string;
    queueLabel?: string;
    retryInSeconds?: number;
}

const mapStateToProps = (state) => {
    return {
        priceListResponse: state.entities.priceListIndex as IPriceListIndexResponse,
    };
};

const PriceListLoadingNotification: React.FunctionComponent<IProps> | null = (props) => {
    const [{ isFinished, status }, reloadPriceList] = useRequest(
        request({ type: 'priceListIndex', url: `pricelist/pricelist/index`, notApi: true, force: true }),
    );
    const { priceListResponse } = useSelector(mapStateToProps);

    useEffect(() => {
        const reTryPriceList = setTimeout(
            () => {
                if (priceListResponse?.retryInSeconds !== undefined) {
                    reloadPriceList();
                }
            },
            (priceListResponse?.retryInSeconds || 10) * 1000,
        );

        return () => clearTimeout(reTryPriceList);
    }, [isFinished]);

    if (status && status !== 200) {
        Toaster.addToast({
            intent: 'danger',
            text: props.loadingFailedLabel,
        });
        return <></>;
    }

    if (!priceListResponse) {
        return null;
    }

    if (priceListResponse.forceRefresh) {
        window.location.reload();
        return null;
    }

    if (!priceListResponse.success) {
        return (
            <div className="global-callout intent-info">
                <p>{props.loadingLabel}</p>
            </div>
        );
    }

    if (priceListResponse.success) {
        return (
            <div className="global-callout intent-success">
                <p>
                    <RenderHTML html={props.loadingSuccessLabel} nowrapper={true} />
                </p>
            </div>
        );
    }

    return null;
};

export default PriceListLoadingNotification;
