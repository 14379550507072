interface IGoogleRecaptchaObject {
    ready: (callback) => void;
    execute: (site_key: string, config) => Promise<string>;
}

interface IRecaptchaResponse {
    'g-recaptcha-response': string;
}

declare global {
    interface Window {
        grecaptcha?: IGoogleRecaptchaObject;
        grecaptchaSiteKey?: string;
    }
}

export const setCaptchaToken = async (data?: any): Promise<IRecaptchaResponse> => {
    return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
            try {
                window.grecaptcha.ready(() => {
                    if (window.grecaptcha && window.grecaptchaSiteKey) {
                        void window.grecaptcha.execute(window.grecaptchaSiteKey, { action: 'submit' }).then((token) => {
                            if (data) {
                                Object.assign(data, { 'g-recaptcha-response': token });
                            }

                            resolve({ 'g-recaptcha-response': token });
                        });
                    } else {
                        reject();
                    }
                });
            } catch (e) {
                reject();
            }
        } else {
            // recaptcha is not enabled, resolve to an empty token
            resolve({ 'g-recaptcha-response': '' });
        }
    });
};

export const getCaptchaToken = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
            try {
                window.grecaptcha.ready(() => {
                    if (window.grecaptcha && window.grecaptchaSiteKey) {
                        void window.grecaptcha.execute(window.grecaptchaSiteKey, { action: 'submit' }).then((token) => {
                            resolve(token);
                        });
                    } else {
                        reject();
                    }
                });
            } catch (e) {
                reject();
            }
        } else {
            // recaptcha is not enabled, resolve to an empty token
            resolve('');
        }
    });
};
