export interface IStoreWithProductListMode {
    productListModeReducer: IProductListModeState;
}

export enum TListMode {
    GRID = 'grid',
    LIST = 'list',
}

export interface IProductListModeState {
    listMode?: TListMode;
}

export const productListModeSelector = (store: IStoreWithProductListMode) => {
    return store.productListModeReducer;
};
