import React from 'react';
import classNames from 'classnames';
import Img from 'components/Image/Img';

interface IProps {
    config: IConfig;
    changeCurrentPage: (pageNum: number) => void;
    changePerPage: (perPageNum: number) => void;
}

interface IConfig {
    activePage: number;
    showPerPage: number;
    pageCount: number;
    totalCount: number;
    showAmounts: number[];
    labels: {
        rows: string;
        previous: string;
        show: string;
        perPage: string;
        next: string;
    };
}

const Pager: React.FunctionComponent<IProps> = (props) => {
    const { config, changeCurrentPage, changePerPage } = props;
    const { labels } = config;

    const onChange = (value) => {
        changeCurrentPage(1);
        changePerPage(value);
    };

    const getPages = () => {
        if (config.activePage === 1) {
            return [config.activePage, config.activePage + 1, config.activePage + 2];
        }

        if (config.activePage === config.pageCount) {
            return [config.activePage - 2, config.activePage - 1, config.activePage];
        }

        return [config.activePage - 1, config.activePage, config.activePage + 1];
    };

    return (
        <>
            <div className="table-tools">
                <p className="table-tools__amount">
                    {config.totalCount} {labels.rows}
                </p>
                <nav role="navigation" className="paging">
                    <ul>
                        <li
                            className={classNames('paging-item paging-item__previous', {
                                disabled: config.activePage === 1,
                            })}
                        >
                            <a
                                href="#"
                                onClick={() => {
                                    changeCurrentPage(config.activePage - 1);
                                }}
                            >
                                <Img iconName="icon_arrow_left02" alt={labels.previous} />
                                {labels.previous}
                            </a>
                        </li>
                        {getPages().map((page, i) => {
                            return (
                                <li className="paging-item" key={i}>
                                    <a
                                        href="#"
                                        className={classNames({ current: page === config.activePage })}
                                        onClick={() => {
                                            changeCurrentPage(page);
                                        }}
                                    >
                                        {page}
                                    </a>
                                </li>
                            );
                        })}
                        <li
                            className={classNames('paging-item paging-item__next', {
                                disabled: config.activePage === config.pageCount,
                            })}
                        >
                            <a
                                href="#"
                                onClick={() => {
                                    changeCurrentPage(config.activePage + 1);
                                }}
                            >
                                <Img iconName="icon_arrow_right02" alt={labels.next} />
                                {labels.next}
                            </a>
                        </li>
                    </ul>
                </nav>
                <label className="table-tools__per-page">
                    {labels.show}
                    <select className="form-control" onChange={(e) => onChange(parseInt(e.target.value))}>
                        {config.showAmounts.map((value, i) => {
                            return <option key={i}>{value}</option>;
                        })}
                    </select>
                    {labels.perPage}
                </label>
            </div>
        </>
    );
};

export default Pager;
