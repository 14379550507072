import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormKey from 'components/GeneralForm/Field/FormKey';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons/index';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import FormList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormList/index';
import FormListItem from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormListItem/index';
import ControlPassword from 'components/GeneralForm/Field/ControlPassword';
import IPasswordStrengthConfig from 'components/GeneralForm/Interfaces/IPasswordStrengthConfig';
import IPasswordConfig from 'components/GeneralForm/Interfaces/IPasswordConfig';
import validatePasswordMinCharacterSets from '../../../helpers/passwordValidator';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
        };
        passwordConfig: IPasswordConfig;
        passwordStrengthConfig: IPasswordStrengthConfig;
        labels: {
            requiredField: string;
            formSubmitLabel: string;
        };
    };
}

const ResetForgottenPasswordForm = (props: IProps): JSX.Element => {
    const { config } = props;
    const { passwordConfig } = config;
    const formElement = useRef<HTMLFormElement>(null);
    const formik = useFormik({
        initialValues: { password: '', password_confirmation: '' },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .nullable()
                .required(config.labels.requiredField)
                .min(config.passwordConfig.minLength, passwordConfig.labels.minLengthLabel)
                .test('password-min-character-sets', passwordConfig.labels.minCharacterSetLabel, (value) =>
                    value ? validatePasswordMinCharacterSets(value, passwordConfig.requiredCharacterClasses) : true,
                ),
            password_confirmation: Yup.string()
                .nullable()
                .required(config.labels.requiredField)
                .oneOf([Yup.ref('password'), null], passwordConfig.labels.passwordMatchLabel),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    return (
        <form ref={formElement} action={config.formConfig.actionUrl} method="post" onSubmit={formik.handleSubmit}>
            <FormKey />
            <FormList separated={true}>
                <FormListItem
                    required={true}
                    label={passwordConfig.labels.newPasswordLabel}
                    error={formik.errors.password}
                >
                    <ControlPassword
                        name="password"
                        value={formik.values.password ?? ''}
                        onChange={formik.handleChange}
                        showPasswordButtonLabel={passwordConfig.labels.showPasswordLabel}
                        passwordStrengthConfig={config.passwordStrengthConfig}
                    />
                </FormListItem>
                <FormListItem
                    required={true}
                    label={passwordConfig.labels.confirmPasswordLabel}
                    error={formik.errors.password_confirmation}
                >
                    <ControlPassword
                        name="password_confirmation"
                        value={formik.values.password_confirmation ?? ''}
                        onChange={formik.handleChange}
                        showPasswordButtonLabel={passwordConfig.labels.showPasswordLabel}
                    />
                </FormListItem>
            </FormList>
            <Buttons layout={'vertical-wide'}>
                <Button type="submit" intent="primary" title={config.labels.formSubmitLabel} />
            </Buttons>
        </form>
    );
};

export default ResetForgottenPasswordForm;
