import React, { useState } from 'react';
import { SearchResponseItem } from 'components/Catalog/Search/Results';
import classNames from 'classnames';
import Result from 'components/Catalog/Search/Result';

interface IProps {
    isFocused: boolean;
    results: SearchResponseItem[];
    noResultsFound: string;
    showAllLabel: string;
}

const SearchResultsList = (props: IProps) => {
    const { isFocused, results } = props;
    const getResultTypeLabel = (item: SearchResponseItem): string => {
        return item.label || item.attribute_label || '';
    };

    const groupSearchResults = () => {
        const grouped = {};

        results.forEach((item) => {
            if (!grouped[item.type]) {
                grouped[item.type] = {
                    items: [],
                };
            }

            grouped[item.type]['items'].push(item);
        });

        return grouped;
    };

    const [searchResultsGrouped, setSearchResultsGrouped] = useState<{
        [key: string]: { items: SearchResponseItem[] };
    }>(groupSearchResults());

    return (
        <div className={classNames('search__results has-results', { open: isFocused })}>
            <ul className="search__results__list">
                {Object.keys(searchResultsGrouped).map((type) => (
                    <React.Fragment key={type}>
                        {searchResultsGrouped[type]['items'].map((item) => (
                            <Result
                                key={item.title}
                                name={item.title}
                                imageUrl={item.image}
                                href={item.url}
                                type={{ code: item.type, label: getResultTypeLabel(item) }}
                                categories={item.categories}
                                price={item.price}
                                specialPrice={item.specialPrice}
                            />
                        ))}
                        <li className="search__results__separator" />
                    </React.Fragment>
                ))}
                <li className="search__results__actions">
                    <a className="button layout-default intent-secondary">
                        <span className="button-inner">
                            <button type={'submit'} className="text">
                                {Object.keys(searchResultsGrouped).length === 0
                                    ? props.noResultsFound
                                    : props.showAllLabel}
                            </button>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default SearchResultsList;
