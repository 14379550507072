import * as React from 'react';
import { formatPriceLocale } from '../../../../helpers/priceUtils';

interface IProductPriceProps {
    finalPrice: number;
    price: number;
    productUnit?: string;
    pricePerUnit?: number;
    priceAsLowAsLabel?: string;
}

const ProductPrice: React.FunctionComponent<IProductPriceProps> = (props) => {
    const { finalPrice, price, productUnit, pricePerUnit, priceAsLowAsLabel } = props;
    const asLowAs = priceAsLowAsLabel ? <span className="product-pricing__price-prefix">{priceAsLowAsLabel}</span> : '';
    return (
        <div className="product-pricing">
            <div className="product-pricing__price special">
                <p>
                    <span className="product-pricing__price-value">
                        {asLowAs}
                        <span className="product-pricing__price-number">{formatPriceLocale(finalPrice)}</span>
                    </span>
                </p>
            </div>
            <div className="product-pricing__price discount">
                {finalPrice < price && (
                    <p>
                        <span className="product-pricing__price-value">
                            {asLowAs}
                            <span className="product-pricing__price-number">{formatPriceLocale(price)}</span>/
                        </span>
                    </p>
                )}
                {productUnit && pricePerUnit && (
                    <p className="product-pricing__price-perunit">
                        {formatPriceLocale(pricePerUnit)}/{productUnit}
                    </p>
                )}
            </div>
        </div>
    );
};

export default ProductPrice;
