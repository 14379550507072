import { request } from 'data/requests/request';

const getCartRequest = request({
    type: 'cartSummary',
    url: 'basket/basket/index',
    method: 'GET',
    notApi: true,
});

export default getCartRequest;
