import React from 'react';
import { IAuthenticationPopupConfig } from 'components/Customer/Auth/AuthenticationPopup';
import RenderTabs from 'ekaubamaja-ui/lib/Components/Tabs';
import SmartId from 'components/SmartId/SmartId';
import MobileId from 'components/MobileId/MobileId';
import IdCard from 'components/IdCard/IdCard';
import EmailAuthForm from 'components/Customer/Form/EmailAuthForm';

interface IProps {
    config: IAuthenticationPopupConfig;
    onLogin: () => void;
}

const Login: React.FunctionComponent<IProps> = (props) => {
    const { config, onLogin } = props;
    const { smartId, mobileId, idCard, email, labels } = config;

    const tabs: { [key: string]: { title: string; content: JSX.Element } } = {};

    if (smartId?.isEnabled) {
        tabs['tab01'] = {
            title: labels.smartId,
            content: <SmartId config={smartId} />,
        };
    }

    if (mobileId?.isEnabled) {
        tabs['tab02'] = {
            title: labels.mobileId,
            content: <MobileId config={mobileId} />,
        };
    }

    if (idCard?.isEnabled) {
        tabs['tab03'] = {
            title: labels.idCard,
            content: <IdCard config={idCard} />,
        };
    }

    if (email.isEnabled) {
        tabs['tab04'] = {
            title: labels.email,
            content: (
                <EmailAuthForm
                    onLogin={onLogin}
                    invalidEmailLabel={labels.invalidEmail}
                    requiredFieldLabel={labels.requiredField}
                    emailLabel={labels.email}
                    passwordLabel={labels.password}
                    showPasswordLabel={labels.showPassword}
                    loginlabel={labels.login}
                    registerLabel={labels.register}
                    formConfig={config.formConfig}
                    forgotPasswordLabel={labels.forgotPassword}
                />
            ),
        };
    }

    return <RenderTabs className="style-minimal" activeTab={Object.keys(tabs).pop() ?? ''} tabs={tabs} />;
};

export default Login;
