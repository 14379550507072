import * as React from 'react';
import Img from 'components/Image/Img';

interface IProps {
    config: {
        switchClientLabel: string;
    };
}

const CustomerClientSwitcher: React.FunctionComponent<IProps> = (props) => {
    const triggerSwitchEvent = () => {
        window.dispatchEvent(new CustomEvent('switchClient'));
    };

    return (
        <button className="button-switch" onClick={triggerSwitchEvent} tabIndex={0}>
            <Img iconName={'icon_switch'} alt={props.config.switchClientLabel} />
            {props.config.switchClientLabel}
        </button>
    );
};

export default CustomerClientSwitcher;
