import { useMutation } from 'redux-query-react';
import { default as React, useState } from 'react';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import * as webeid from '@web-eid/web-eid-library/web-eid.js';
import IdCardRequest, { IIdCardStartSignData } from 'components/Checkout/requests/signing/general/IdCardRequest';
import IdCardFinishRequest from 'components/Checkout/requests/signing/general/IdCardFinishRequest';
import { HttpStatusCode } from '../../../../enums/HttpStatusCode';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import { closeOverlay } from 'data/actions/overlays';
import { useDispatch } from 'react-redux';
import useDownloader from 'react-use-downloader';

interface IFinishData {
    signatureHex: string;
    sessionId: string;
}

interface IProps {
    downloadFile: () => void;
    personalCodeLabel: string;
    signLabel: string;
    downloadFileLabel: string;
    verificationCodeLabel: string;
    signingFailedLabel: string;
    idCardLabel: string;
    submitOrder: () => void;
}

const SignWithIdButton: React.FunctionComponent<IProps> = (props) => {
    const { downloadFile, signingFailedLabel, downloadFileLabel, idCardLabel, submitOrder } = props;

    const [isSigning, setIsSigning] = useState(false);

    const [{}, idCardSignRequest] = useMutation((data: IIdCardStartSignData) => IdCardRequest(data));
    const [{}, idCardSignFinishRequest] = useMutation((data: IFinishData) => IdCardFinishRequest(data));

    const dispatch = useDispatch();
    const { download } = useDownloader();

    const sign = async () => {
        setIsSigning(true);

        await webeid
            .getSigningCertificate({ lang: window.LOCALE ?? 'en' })
            .then((certificate) => {
                void idCardSignRequest({ signatureHex: certificate.certificate }).then((res) => {
                    if (res.status === HttpStatusCode.OK) {
                        void webeid
                            .sign(certificate.certificate, res?.body?.hex, res?.body?.signatureHashType, {
                                lang: window.LOCALE ?? 'en',
                            })
                            .then((signResponse) => {
                                void idCardSignFinishRequest({
                                    signatureHex: signResponse.signature,
                                    sessionId: res?.body?.sessionId,
                                }).then((res) => {
                                    if (res.status === HttpStatusCode.OK) {
                                        download(res?.body?.downloadUrl, res?.body?.fileName);

                                        dispatch(closeOverlay({ name: 'all' }));
                                        submitOrder();
                                    } else {
                                        setIsSigning(false);
                                        Toaster.addToast({
                                            text: res?.body?.message || signingFailedLabel,
                                            intent: 'danger',
                                        });
                                    }
                                });
                            })
                            .catch(() => {
                                setIsSigning(false);
                            });
                    } else {
                        Toaster.addToast({ text: res?.body?.message || signingFailedLabel, intent: 'danger' });
                        setIsSigning(false);
                    }
                });
            })
            .catch(() => {
                //something failed, user cancelled
                setIsSigning(false);
            });
    };

    return (
        <>
            <Buttons>
                <Button
                    title={idCardLabel}
                    intent={'primary'}
                    onClick={sign}
                    disabled={isSigning}
                    loading={isSigning}
                />
                <Button title={downloadFileLabel} icon="download" layout="link" size="xsmall" onClick={downloadFile} />
            </Buttons>
        </>
    );
};

export default SignWithIdButton;
