import compareListRequest, { ICompareListResponse } from 'components/ProductCompare/requests/compareListRequest';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        productCompareList: state.entities.productCompareList as ICompareListResponse,
    };
};

interface IProductCompareList {
    isFinished: boolean;
    reloadProductCompareList: () => void;
    productCompareList: ICompareListResponse;
}

export default function useProductCompareList(): IProductCompareList {
    const [{ isFinished }, reloadProductCompareList] = useRequest(compareListRequest());
    const { productCompareList } = useSelector(mapStateToProps);

    return {
        isFinished,
        reloadProductCompareList,
        productCompareList,
    };
}
