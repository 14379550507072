import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import MainMenu from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/MainMenu';
import { IStoreState } from '../../../helpers/rootReducer';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';

export interface IMainMenuWrapperProps {
    /* DOM to parse */
    dom: string | object[];
    /** Unsafe config */
    config?: any;
    componentMapper: (maybeComponent: string, maybeContent: string, maybeConfig) => React.Component;
}

const MainMenuWrapper: React.FunctionComponent<IMainMenuWrapperProps> = (props) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    // Main output
    return (
        <MainMenu
            componentMapper={props.componentMapper}
            dom={props.dom}
            config={props.config}
            openOverlays={openOverlays}
            closeOverlay={() => {
                dispatch(closeOverlay({ name: 'all' }));
            }}
            openOverlay={() => {
                dispatch(openOverlay({ name: 'mobilemenu' }));
            }}
        />
    );
};

MainMenuWrapper.displayName = 'MainMenuWrapper';

export default MainMenuWrapper;
