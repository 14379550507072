import { cloneDeep } from 'lodash';
import { IProductListModeState, TListMode } from 'components/Catalog/store/productListModeSelector';

export enum dispatchType {
    LIST_MODE_INIT = 'LIST_MODE_INIT',
    LIST_MODE_SET = 'LIST_MODE_SET',
}

const initialState: IProductListModeState = {
    listMode: undefined,
};

interface IListModeAction {
    type: dispatchType;
    payload: IListModePayload;
}

interface IListModePayload {
    listMode: TListMode;
}

const productListModeReducer = (
    state: IProductListModeState = cloneDeep(initialState),
    action: IListModeAction,
): IProductListModeState => {
    const handleInit = (payload: { listMode: TListMode }) => {
        state.listMode = payload.listMode;

        return {
            ...state,
        };
    };

    const handleSetListMode = (payload: { listMode: TListMode }) => {
        state.listMode = payload.listMode;

        return {
            ...state,
        };
    };

    switch (action.type) {
        case dispatchType.LIST_MODE_INIT: {
            return handleInit(action.payload);
        }
        case dispatchType.LIST_MODE_SET: {
            return handleSetListMode(action.payload);
        }
        default: {
            return state;
        }
    }
};

export default productListModeReducer;
