import React, { useEffect } from 'react';
import PriceListLoadingNotification from 'components/Customer/Overlay/PriceListLoadingNotification';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useDispatch, useSelector } from 'react-redux';
import { openOverlay } from 'data/actions/overlays';

interface IProps {
    loadingLabel: string;
    loadingFailedLabel: string;
    loadingSuccessLabel: string;
    clientSelected: boolean;
}

const mapStateToProps = (state) => {
    return {
        needsAssortment: state.entities.hasPriceList?.needsAssortment as boolean,
    };
};

const PriceListLoadingWrapper = (props: IProps) => {
    const [{ isFinished }] = useRequest(
        request({ type: 'hasPriceList', url: `pricelist/pricelist/haspricelist`, notApi: true, force: true }),
    );

    const { loadingLabel, loadingFailedLabel, loadingSuccessLabel, clientSelected } = props;
    const { needsAssortment } = useSelector(mapStateToProps);
    const dispatch = useDispatch();

    useEffect(() => {
        if (needsAssortment) {
            dispatch(openOverlay({ name: 'assortmentOverlay' }));
        }
    }, [needsAssortment]);

    if (!isFinished) {
        return <></>;
    }

    //if new client is selected and we just need to update pricelist then we have to reload.
    if (!needsAssortment && clientSelected) {
        window.location.reload();
        return <></>;
    }

    if (!needsAssortment) {
        return (
            <>
                <PriceListLoadingNotification
                    loadingLabel={loadingLabel}
                    loadingFailedLabel={loadingFailedLabel}
                    loadingSuccessLabel={loadingSuccessLabel}
                />
            </>
        );
    }

    return <></>;
};
export default PriceListLoadingWrapper;
