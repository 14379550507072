import { request } from 'data/requests/request';

const getPdfRequest = () =>
    request({
        type: 'pdfRequest',
        url: `pdf/pdf/orderPdf`,
        method: 'POST',
        notApi: true,
    });

export default getPdfRequest;
