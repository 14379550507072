import * as React from 'react';
import { includes } from 'lodash';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'data/reducers/root';
import { closeOverlay } from 'data/actions/overlays';
import AssortmentOverlayContents from 'components/Customer/Overlay/AssortmentOverlayContents';

interface IProps {
    loadingLabel: string;
}

const AssortmentOverlay: React.FunctionComponent<IProps> = (props) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const { loadingLabel } = props;

    const overlayOpen = includes(openOverlays, 'assortmentOverlay');

    return (
        <Overlay
            isOpen={overlayOpen}
            doClose={() => dispatch(closeOverlay({ name: 'all' }))}
            layout="dialog"
            className="epharma-dialog"
            canEscapeKeyClose={false}
        >
            <>{overlayOpen && <AssortmentOverlayContents loadingLabel={loadingLabel} />}</>
        </Overlay>
    );
};

export default AssortmentOverlay;
