import React from 'react';
import ClientManagerInfo from 'components/Customer/SelfService/Components/ClientManagerInfo';
import DashBoardBanner from 'components/Customer/SelfService/Components/DashBoardBanner';
import DashBoardPersonalOffers from 'components/Customer/SelfService/Components/DashBoardPersonalOffers';

export interface IClientManagerInfo {
    name: string;
    phoneNumber: string;
    email: string;
}

export interface IBannerConfig {
    enabled: boolean;
    url?: string;
    image?: string;
}

export interface IPersonalOffersConfig {
    enabled: boolean;
    url?: string;
    image?: string;
    text?: string;
}

interface IProps {
    config: {
        clientManager?: IClientManagerInfo;
        banner?: IBannerConfig;
        personalOffers: IPersonalOffersConfig;
        labels: {
            clientManagerLabel: string;
            personalOffersLabel: string;
            lookAtOffersLabel: string;
        };
    };
}

const DashBoardSidebar: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { labels } = config;

    return (
        <>
            {config.clientManager && (
                <ClientManagerInfo
                    clientManagerLabel={labels.clientManagerLabel}
                    clientManager={config.clientManager}
                />
            )}
            {config.banner?.enabled && <DashBoardBanner banner={config.banner} />}
            {config.personalOffers?.enabled && (
                <DashBoardPersonalOffers
                    personalOffer={config.personalOffers}
                    personalOffersLabel={labels.personalOffersLabel}
                    lookAtOffersLabel={labels.lookAtOffersLabel}
                />
            )}
        </>
    );
};

export default DashBoardSidebar;
