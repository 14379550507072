import React from 'react';
import CartProductActions from 'components/CartView/components/CartProductActions';
import ProductBadges from 'components/Catalog/Product/List/BoxProduct/ProductBadges';
import { ICartProduct } from 'components/CartView/interfaces/CartInterfaces';
import { ICartLabels } from 'components/CartView/CartView';
import ProductLabels from 'components/Catalog/Product/List/BoxProduct/ProductLabels';

interface IProps {
    product: ICartProduct;
    labels: ICartLabels;
}

const Product: React.FunctionComponent<IProps> = (props) => {
    const { product, labels } = props;

    return (
        <div className="cart-products__item">
            <div className="box-product">
                <div className="box-product__main">
                    <a href={product.url} className="box-product__link">
                        {product.name}
                    </a>
                    <div className="box-product__image">
                        {!!product.badges.length && <ProductBadges badges={product.badges} />}
                        <span>
                            <img src={product.thumbnail} alt={product.name} />
                        </span>
                    </div>
                    <div className="box-product__content">
                        <div className="box-product__text">
                            <div className="box-product__title">{product.name}</div>
                            <div className="box-product__description"></div>
                        </div>
                    </div>
                </div>
                <CartProductActions product={product} labels={labels} />
            </div>
        </div>
    );
};

export default Product;
