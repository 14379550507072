import React from 'react';
import { InvoiceStatusEnum } from 'components/Customer/Client/clientOrders';
import classNames from 'classnames';

interface IProps {
    invoiceStatus: InvoiceStatusEnum;
    invoicedLabel: string;
    notPaidLabel: string;
    paidLabel: string;
    debtLabel: string;
}

const InvoiceRowStatusLabel = (props: IProps) => {
    const { invoiceStatus, invoicedLabel, notPaidLabel, paidLabel, debtLabel } = props;

    const statusClassName = (): string => {
        switch (invoiceStatus) {
            case InvoiceStatusEnum.INVOICED:
            case InvoiceStatusEnum.PAID:
                return 'success';
            case InvoiceStatusEnum.NOT_PAID:
                return 'warn';
            case InvoiceStatusEnum.DEBT:
                return 'danger';
            default:
                return '';
        }
    };

    const statusInvoiceLabel = (): string => {
        switch (invoiceStatus) {
            case InvoiceStatusEnum.PAID:
                return paidLabel;
            case InvoiceStatusEnum.NOT_PAID:
                return notPaidLabel;
            case InvoiceStatusEnum.INVOICED:
                return invoicedLabel;
            case InvoiceStatusEnum.DEBT:
                return debtLabel;
            default:
                return '';
        }
    };

    return <span className={classNames('tag', statusClassName())}>{statusInvoiceLabel()}</span>;
};

export default InvoiceRowStatusLabel;
