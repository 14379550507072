import { request } from 'data/requests/request';

const DeleteFromCartRequest = (sku: string) => {
    return request({
        type: 'cartMutationRequest',
        method: 'POST',
        url: 'basket/ajax/deleteFromCart',
        data: { sku },
        notApi: true,
    });
};

export default DeleteFromCartRequest;
