import React, { useEffect, useRef, useState } from 'react';
import { useKeypress } from 'ekaubamaja-ui/lib/Utilities/Generic';

export interface IProps {
    config: IPagerConfig;
    onChange?: (pageNum: number) => void;
}

export interface IPagerConfig {
    currentPage: number;
    lastPage: number;
    pageParam: string;
    labels: {
        page: string;
        previous: string;
        next: string;
    };
}

const Pager: React.FunctionComponent<IProps> = (props) => {
    const { config, onChange } = props;
    const { lastPage, labels } = config;
    const inputRef = useRef<HTMLInputElement>(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [currentPage, setCurrentPage] = useState(config.currentPage);

    const changePage = (pageNum: number) => {
        if (onChange) {
            onChange(pageNum);
        }
    };

    const previousPage = () => {
        const newPage = currentPage - 1;

        if (newPage >= 1) {
            changePage(newPage);
            setCurrentPage(newPage);
        }
    };

    const nextPage = () => {
        const newPage = currentPage + 1;

        if (newPage <= lastPage) {
            changePage(newPage);
            setCurrentPage(newPage);
        }
    };

    useEffect(() => {
        if (config.currentPage && config.currentPage !== currentPage) {
            setCurrentPage(config.currentPage);
        }
    }, [config]);

    useKeypress(
        'Enter',
        () => {
            if (isInputFocused) {
                inputRef.current?.blur();
            }
        },
        [isInputFocused],
    );

    return (
        <div className="paginator">
            <a
                className="paginator__prev"
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    previousPage();
                }}
            >
                <span>{labels.previous}</span>
            </a>
            <div className="paginator__counter">
                <input
                    ref={inputRef}
                    type="number"
                    value={currentPage > 0 ? currentPage : ''}
                    onChange={(e) => {
                        const newPage = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;

                        if (newPage <= lastPage) {
                            setCurrentPage(newPage);
                        }
                    }}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={(e) => {
                        setIsInputFocused(false);
                        if (!isNaN(e.target.valueAsNumber)) {
                            setCurrentPage(e.target.valueAsNumber);
                            changePage(e.target.valueAsNumber);
                        }
                    }}
                    aria-label={labels.page}
                />
                <span className="paginator__label"> / {lastPage}</span>
            </div>
            <a
                className="paginator__next"
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    nextPage();
                }}
            >
                <span>{labels.next}</span>
            </a>
        </div>
    );
};

export default Pager;
