import { request } from 'data/requests/request';

const authenticateRequest = (personalCode: string, phoneNo: string) =>
    request({
        type: 'mobileIdAuthenticateRequest',
        url: 'mobileid/authenticate/index',
        data: {
            personalCode,
            phoneNo,
        },
        method: 'POST',
        notApi: true,
    });

export default authenticateRequest;

export interface IAuthenticateResponse {
    status: number;
    message: string;
}
