import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'redux-query-react';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML/index';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/CheckboxList/index';
import { hasGdprCookieToken } from 'components/Gdpr/helpers/ConsentHandler';
import Img from 'components/Image/Img';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel/index';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import saveCookieRequest from 'components/Gdpr/requests/saveCookieRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { includes } from 'lodash';
import useAuth from '../../hooks/useAuth';

interface IProps {
    config: {
        modalTitle: string;
        introText: string;
        isEnabled: boolean;
        isVisible: boolean;
        cookieSelection: ICookieSelection[];
        labels: {
            settingsLabel: string;
            agreeToAllLabel: string;
            agreeToSelectedLabel: string;
        };
    };
}

interface ICookieSelection {
    id: number;
    code: string;
    name: string;
    description: string;
    is_required: boolean;
    isChecked: boolean;
}

interface ISelectedCookie {
    [key: number]: boolean;
}

const ConsentPopup: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { isEnabled, isVisible, cookieSelection, modalTitle, introText, backgroundImage, labels } = config;

    if (!isEnabled || !isVisible) {
        return null;
    }

    const { openOverlays } = useSelector(overlaysSelector);
    const [showSettings, setShowSettings] = useState(false);
    const [selectedCookies, setSelectedCookies] = useState<ISelectedCookie>({});
    const [{}, saveRequest] = useMutation((cookies: ICookieSelection[]) => saveCookieRequest(cookies));
    const dispatch = useDispatch();
    const customer = useAuth();

    const handleAcceptSelected = () => {
        const cookies = cookieSelection.map((cookie) => {
            return {
                id: cookie.id,
                code: cookie.code,
                isChecked: selectedCookies[cookie.id] || cookie.is_required,
            };
        });

        saveCookieSelection(cookies);
    };
    const handleAcceptAll = () => {
        const cookies = cookieSelection.map((cookie) => {
            return {
                id: cookie.id,
                code: cookie.code,
                isChecked: true,
            };
        });

        saveCookieSelection(cookies);
    };

    const saveCookieSelection = (cookies: Array<{ id: number; isChecked: boolean; code: string }>) => {
        void saveRequest(cookies).then((response) => {
            if (response.body) {
                Toaster.addToast({
                    intent: response.body.errors ? 'danger' : 'success',
                    text: response.body.message,
                    asHtml: true,
                });
                if (!response.body.errors) {
                    window.dispatchEvent(
                        new CustomEvent('cookie-consent-saved', {
                            detail: {
                                customer: customer.customer,
                                consentData: cookies.map((cookie) => {
                                    return {
                                        ...cookie,
                                        status: cookie.isChecked ? 1 : 0,
                                    };
                                }),
                            },
                        }),
                    );

                    dispatch(closeOverlay({ name: 'gdpr_consent_popup', instant: true }));
                }
            }
        });
    };

    useEffect(() => {
        if (!hasGdprCookieToken()) {
            dispatch(openOverlay({ name: 'gdpr_consent_popup', asNew: true }));
        }
    }, []);

    return (
        <Overlay
            isOpen={includes(openOverlays, 'gdpr_consent_popup')}
            doClose={() => {}}
            layout="dialog"
            className="epharma-dialog"
            customHeader={
                <div className="epharma-dialog-heading">
                    <h2>
                        <Img iconName="icon_cookie" alt="" height="25" /> {modalTitle}
                    </h2>
                </div>
            }
        >
            <div className="epharma-dialog-content">
                <RenderHTML html={introText} />

                {showSettings && (
                    <CheckboxList className="theme01">
                        {cookieSelection.map((cookie) => (
                            <CheckboxWithLabel
                                key={cookie.id}
                                checked={selectedCookies[cookie.id] || cookie.is_required}
                                value={selectedCookies[cookie.id]?.toString()}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.checked;

                                    setSelectedCookies((current) => {
                                        return {
                                            ...current,
                                            [cookie.id]: value,
                                        };
                                    });
                                }}
                                label={
                                    <React.Fragment>
                                        {cookie.name}
                                        <div className="checkboxes__description" style={{ fontWeight: 'normal' }}>
                                            <p>{cookie.description}</p>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        ))}
                    </CheckboxList>
                )}
                {!showSettings && (
                    <React.Fragment>
                        <p>
                            <Button
                                type="button"
                                intent="secondary-muted"
                                size="small"
                                title={
                                    <React.Fragment>
                                        <Img iconName="icon_settings" alt="" /> {labels.settingsLabel}
                                    </React.Fragment>
                                }
                                onClick={() => setShowSettings(true)}
                            />
                        </p>
                        <p>
                            <Button
                                type="button"
                                intent="primary"
                                title={labels.agreeToAllLabel}
                                onClick={handleAcceptAll}
                            />
                        </p>
                    </React.Fragment>
                )}
                {showSettings && (
                    <p>
                        <Button
                            type="button"
                            intent="primary"
                            title={labels.agreeToSelectedLabel}
                            onClick={handleAcceptSelected}
                        />
                    </p>
                )}
            </div>
        </Overlay>
    );
};

export default ConsentPopup;
