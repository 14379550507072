import { request } from 'data/requests/request';

export interface IReorderRequestObject {
    products: {
        sku: string;
        quantity: string;
    };
}

const reorderRequest = (data: IReorderRequestObject) =>
    request({
        method: 'POST',
        type: 'addReorderProducts',
        url: '/b2bclient/order/process',
        notApi: true,
        absolute: true,
        data,
    });

export default reorderRequest;
