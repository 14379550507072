import IProductBadge from 'components/Catalog/interfaces/IProductBadge';

export enum SubmitActionEnum {
    NO_SIGNING,
    DIGIDOC_SIGNING,
    NOT_ALLOWED_TO_SIGN,
    NOT_ALLOWED_TO_SUBMIT,
    NOT_OVER_MINIMUM_GRAND_TOTAL,
}

export interface ICartProduct {
    isMedicine: boolean;
    name: string;
    packageType: string;
    price: number;
    specialPrice?: number;
    quantity: number;
    rowTotal: number;
    sku: string;
    thumbnail: string;
    url: string;
    badges: IProductBadge[];
    isSpecialOffer: boolean;
}

export interface ICartTotals {
    grandTotal: ICartTotal;
    subTotal: ICartTotal;
    vatTotal: ICartTotal;
    discountTotal: ICartTotal;
}

export interface ICartTotal {
    key: string;
    label: string;
    value: number;
}

export interface ICartSummary {
    products: ICartProduct[];
    cartTotals?: ICartTotals;
    orderNumber: string;
    submitAction: SubmitActionEnum;
    isFinished: boolean;
    minOrderSum: number;
}
