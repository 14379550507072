import React from 'react';
import { IBannerConfig } from 'components/Customer/SelfService/DashBoardSidebar';

interface IProps {
    banner: IBannerConfig;
}

const DashBoardBanner: React.FunctionComponent<IProps> = (props) => {
    const { banner } = props;
    return (
        <div className="banners wrap">
            <div className="banner size-small">
                <a href={banner.url}>
                    <img src={banner.image} />
                </a>
            </div>
        </div>
    );
};

export default DashBoardBanner;
