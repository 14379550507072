import * as React from 'react';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import Img from 'components/Image/Img';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import ControlCheckbox from 'ekaubamaja-ui/lib/Components/ControlCheckbox';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import SystemNotifications from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotifications/index';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { HttpStatusCode } from '../../../enums/HttpStatusCode';
import { useMutation } from 'redux-query-react';
import reorderRequest, { IReorderRequestObject } from 'components/Customer/Client/requests/reorderRequest';
import { IInvoiceItem, IInvoice } from './interfaces/OrderDetailInterfaces';
import { without } from 'lodash';

interface IProps {
    config: {
        invoiceItems: IInvoiceItem[];
        invoice: IInvoice;
        isPickOrder: boolean;
        labels: {
            order: string;
            orderDate: string;
            printOrder: string;
            product: string;
            sku: string;
            price: string;
            quantity: string;
            sum: string;
            sumWithVat: string;
            invoice: string;
            addToCart: string;
            selectAll: string;
            outOfStock: string;
        };
    };
}

const orderReorder: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { labels, isPickOrder } = config;
    const [products, setProducts] = React.useState(config.invoiceItems);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [{}, request] = useMutation((data: IReorderRequestObject) => reorderRequest(data));

    const handleQuantityChange = (sku: string, newQuantity: string) => {
        const updatedProducts = products.map((product) => {
            if (product.sku === sku) {
                return { ...product, quantity: newQuantity };
            } else {
                return product;
            }
        });
        setProducts(updatedProducts);
    };

    function selectAll() {
        const productSkus = products.map((product) => product.sku);
        setSelected(productSkus);
    }

    function handleClick() {
        const selectedProducts = selected.map((sku: string) => {
            const product = products.find((p) => p.sku === sku);
            if (product) {
                return {
                    sku: product.sku,
                    quantity: product.quantity,
                };
            } else {
                return;
            }
        });

        const requestObject: IReorderRequestObject = {
            products: selectedProducts,
        };

        void request(requestObject).then((res) => {
            if (res.status === HttpStatusCode.BAD_REQUEST) {
                Toaster.addToast({
                    intent: 'danger',
                    text: res.body.message,
                });
            } else {
                window.location.href = res.body.url;
            }
        });
    }

    return (
        <>
            {!isPickOrder && (
                <h2>
                    {labels.order} <span className="text-highlighted">{config.invoice.orderNumber}</span>
                </h2>
            )}
            <section>
                <div className="layout-grid">
                    <div className="layout-grid__container wide">
                        <div className="layout-grid__columns align-v-top">
                            <div className="layout-grid__column">
                                {!isPickOrder && (
                                    <div className="content-card compact inline dark">
                                        <p>
                                            {labels.orderDate} <strong>{config.invoice.orderDate}</strong>
                                        </p>
                                        <p className="bold">
                                            {labels.invoice} {config.invoice.orderNumber}
                                        </p>
                                        <p>
                                            <button className="button intent-secondary muted size-small">
                                                <Img iconName={'icon_print'} />
                                                <a href={config.invoice.pdfUrl} target={'_blank'}>
                                                    {labels.printOrder}
                                                </a>
                                            </button>
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="layout-grid__column right">
                                <Button title={labels.addToCart} intent="primary" type="anchor" onClick={handleClick} />
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    title={labels.selectAll}
                    intent="discrete"
                    layout="link"
                    className="desktop-hide"
                    onClick={selectAll}
                />
                <table className="data01 responsive">
                    <thead>
                        <tr>
                            <th className="w100p" colSpan={3}>
                                <span>{labels.product}</span>
                                <Button title={labels.selectAll} intent="discrete" layout="link" onClick={selectAll} />
                            </th>
                            <th>{labels.sku}</th>
                            <th className="right">{labels.price}</th>
                            <th className="left">{labels.quantity}</th>
                            <th className="right">{labels.sum}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((invoiceItem, i) => {
                            return (
                                <tr className="valign-center" key={i}>
                                    <td className="w1p">
                                        <ControlCheckbox
                                            checked={
                                                !!selected.find((selectedValue) => selectedValue === invoiceItem.sku)
                                            }
                                            disabled={!invoiceItem.isSaleable}
                                            onChange={(e) => {
                                                let arr = [...selected];
                                                if (!arr.includes(invoiceItem.sku)) {
                                                    arr.push(invoiceItem.sku);
                                                } else {
                                                    arr = without(arr, invoiceItem.sku);
                                                }
                                                setSelected(arr);
                                            }}
                                        />
                                    </td>
                                    <td className="w1p">
                                        <img
                                            src={invoiceItem.imageUrl}
                                            alt={invoiceItem.name}
                                            height="50"
                                            className="img-left"
                                        />
                                    </td>
                                    <td>
                                        <div>
                                            {invoiceItem.name}
                                            {!invoiceItem.isSaleable && (
                                                <SystemNotifications inline={true} compact={true} theme="full">
                                                    <SystemNotification closable={false} intent="info">
                                                        <p>{labels.outOfStock}</p>
                                                    </SystemNotification>
                                                </SystemNotifications>
                                            )}
                                        </div>
                                    </td>
                                    <td className="nowrap">{invoiceItem.sku}</td>
                                    <td className="right bold nowrap">
                                        {formatPriceLocale(invoiceItem.singleItemSum)}
                                    </td>
                                    <td className="right nowrap">
                                        <ControlInput
                                            value={invoiceItem.quantity}
                                            type="text"
                                            onChange={(e) => handleQuantityChange(invoiceItem.sku, e.target.value)}
                                            className="size-small"
                                            disabled={!invoiceItem.isSaleable}
                                        />
                                    </td>
                                    <td className="right bold nowrap">{formatPriceLocale(invoiceItem.totalSum)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tbody>
                        <tr className="total">
                            <td colSpan={6} className="right bold">
                                {labels.sumWithVat}
                            </td>
                            <td className="right bold">{formatPriceLocale(config.invoice.sum)}</td>
                        </tr>
                        <tr className="total">
                            <td colSpan={7} className="right">
                                <Button title={labels.addToCart} intent="primary" type="anchor" onClick={handleClick} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </>
    );
};

export default orderReorder;
