import { request } from 'data/requests/request';

const SubmitBasketRequest = (data: ISubmitBasketData) =>
    request({
        type: 'submitBasketRequest',
        url: 'basket/ajax/submitbasket',
        method: 'POST',
        notApi: true,
        data,
    });

export interface ISubmitBasketData {
    shippingMethodCode: string;
    shippingComment: string;
    extraData: {
        registerNewsletter: boolean;
    };
}

export default SubmitBasketRequest;
