import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeOverlay } from 'data/overlays/overlayHandler';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { includes } from 'lodash';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';
import { IStoreState } from '../../../helpers/rootReducer';
import CTABlock from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/CTABlock/index';
import { ISmartIdConfig } from 'components/SmartId/SmartId';
import { IMobileIdConfig } from 'components/MobileId/MobileId';
import { IIdCardConfig } from 'components/IdCard/IdCard';
import Login from 'components/Customer/Form/Login';
import ClientSelection from 'components/Customer/Overlay/ClientSelection';

export interface IAuthenticationPopupConfig {
    smartId?: ISmartIdConfig;
    mobileId?: IMobileIdConfig;
    idCard?: IIdCardConfig;
    email: {
        isEnabled: boolean;
    };
    formConfig: {
        loginUlr: string;
        registerUrl: string;
        forgotPasswordUrl: string;
        imageUrl: string;
    };
    labels: {
        login: string;
        orUseForLogin: string;
        registeredClient: string;
        forgotPassword: string;
        register: string;
        email: string;
        password: string;
        showPassword: string;
        invalidEmail: string;
        invalidPassword: string;
        invalidCredentials: string;
        requiredField: string;
        noClientsLabels: string;
        idCard: string;
        mobileId: string;
        smartId: string;
    };
}

interface IProps {
    config: IAuthenticationPopupConfig;
}

const AuthenticationPopup: React.FunctionComponent<IProps> = (props: IProps) => {
    const { config } = props;
    const { labels } = config;

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const closeLogin = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    return (
        <>
            {isLoggedIn && <ClientSelection />}
            <Overlay
                isOpen={includes(openOverlays, 'login')}
                doClose={closeLogin}
                layout="dialog"
                className="epharma-dialog epharma-dialog-fullsize wide"
                customHeader={
                    <div className="epharma-dialog-heading">
                        <button onClick={closeLogin}>
                            <Icon kind="close02" width={16} height={16} />
                        </button>
                    </div>
                }
            >
                <div className="epharma-dialog-content">
                    <CTABlock link="#" image={<img src={config.formConfig.imageUrl} />} className="valign-top">
                        <h2>{labels.login}</h2>
                        <Login config={config} onLogin={() => setIsLoggedIn(true)} />
                    </CTABlock>
                </div>
            </Overlay>
        </>
    );
};

AuthenticationPopup.displayName = 'LoginModal';

export default AuthenticationPopup;
