import React from 'react';
import { IInvoice } from 'components/Customer/Client/clientOrders';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import InvoiceRowStatusLabel from 'components/Customer/components/InvoiceRowStatusLabel';
import InvoiceAction from 'components/Customer/components/InvoiceAction';

interface IProps {
    invoice: IInvoice;
    labels: {
        orderNumber: string;
        orderDate: string;
        orderCustomers: string;
        sum: string;
        reOrder: string;
        invoiceStatus: string;
        awaitingFulfillment: string;
        invoiced: string;
        notPaid: string;
        paid: string;
        debt: string;
        processing: string;
    };
}

const InvoiceRow = (props: IProps) => {
    const { invoice, labels } = props;

    return (
        <>
            <tr>
                <td aria-label={labels.orderNumber}>
                    <a href={invoice.detailLink}>{invoice.orderNumber}</a>
                </td>
                <td aria-label={labels.orderDate}>{invoice.orderDate}</td>
                <td aria-label={labels.orderCustomers}>
                    {invoice.orderCustomers.map((customer) => `${customer.firstname} ${customer.lastname}`).join(',')}
                </td>
                <td aria-label={labels.sum}>{formatPriceLocale(invoice.sum, undefined, true, true)}</td>
                <td aria-label={labels.invoiceStatus}>
                    <InvoiceRowStatusLabel
                        invoiceStatus={invoice.status}
                        invoicedLabel={labels.invoiced}
                        notPaidLabel={labels.notPaid}
                        paidLabel={labels.paid}
                        debtLabel={labels.debt}
                    />
                </td>
                <td className="actions">
                    <InvoiceAction
                        invoice={invoice}
                        reorderLabel={labels.reOrder}
                        awaitingFulfillmentLabel={labels.awaitingFulfillment}
                        processingLabel={labels.processing}
                    />
                </td>
            </tr>
        </>
    );
};

export default InvoiceRow;
