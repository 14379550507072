import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import FormList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormList/index';
import FormListItem from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormListItem/index';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/CheckboxList/index';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel/index';
import FormKey from 'components/GeneralForm/Field/FormKey';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
        };
        labels: {
            email: string;
            subscribe: string;
            requiredField: string;
            termsAndConditions: string;
            emailMustBeValidErrorMessage: string;
        };
    };
}

const NewsletterForm = (props: IProps) => {
    const { config } = props;
    const { formConfig, labels } = config;
    const [captchaToken, setCaptchaToken] = useState('');
    const formElement = useRef<HTMLFormElement>(null);

    const formik = useFormik({
        initialValues: {
            email: '',
            agreed_terms: false,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().nullable().email(labels.emailMustBeValidErrorMessage).required(labels.requiredField),
            agreed_terms: Yup.bool().oneOf([true], labels.requiredField),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: () => {
            void getCaptchaToken().then((token) => {
                setCaptchaToken(token);

                if (formElement.current) {
                    formElement.current.submit();
                }
            });
        },
    });

    return (
        <form ref={formElement} action={formConfig.actionUrl} method="post" onSubmit={formik.handleSubmit}>
            <FormKey />
            <input type="hidden" name="g-recaptcha-response" value={captchaToken} />
            <FormList>
                <FormListItem label={labels.email} required={true} error={formik.errors.email}>
                    <ControlInput
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        accessibleLabel={labels.email}
                        placeholder={labels.email}
                    />
                </FormListItem>
                <FormListItem isCheckboxes={true} error={formik.errors.agreed_terms}>
                    <CheckboxList>
                        <CheckboxWithLabel
                            label={
                                <React.Fragment>
                                    <span className="text-intent-danger">*</span>
                                    <RenderHTML html={labels.termsAndConditions} nowrapper={true} />
                                </React.Fragment>
                            }
                            checked={formik.values.agreed_terms}
                            onChange={() => {
                                void formik.setFieldValue('agreed_terms', !formik.values.agreed_terms);
                            }}
                        />
                    </CheckboxList>
                </FormListItem>
            </FormList>
            <Buttons layout="vertical-wide">
                <Button type="submit" intent="primary" title={labels.subscribe} />
            </Buttons>
        </form>
    );
};

export default NewsletterForm;
