import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeOverlay, openOverlay } from 'data/actions/overlays';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons/index';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import { IStoreState } from 'data/reducers/root';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import { includes } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'redux-query-react';
import clientSelectRequest from 'components/Customer/requests/ClientSelectRequest';
import { IClient } from 'components/Customer/Overlay/ClientSelection';

interface IProps {
    welcomeLabel: string;
    clientCountLabel: string;
    clientSearchLabel: string;
    selectLabel: string;
    clientSelectFailedLabel: string;
    clients: IClient[];
    setClientSelected: () => void;
    startClientSelect: () => void;
}

const ClientSelectOverlay: React.FunctionComponent<IProps> = (props) => {
    const { clients } = props;
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);
    const [{}, userClientSelectRequest] = useMutation((clientId: string) => clientSelectRequest(clientId));

    const closeLogin = () => dispatch(closeOverlay({ name: 'all' }));

    const [filteredClients, setFilteredClients] = useState<IClient[]>(clients);
    const [filterKeyword, setFilterKeyword] = useState<string>('');

    const filterClients = (keyword) => {
        setFilterKeyword(keyword);
        if (keyword.length > 2) {
            setFilteredClients(
                clients.filter((client) => {
                    return client.name.toLowerCase().includes(keyword.toLowerCase()) || client.code.includes(keyword);
                }),
            );
        } else {
            setFilteredClients(clients);
        }
    };

    const selectClient = (clientId: string) => {
        props.startClientSelect();
        userClientSelectRequest(clientId).then((response) => {
            if (!!response.body.success) {
                props.setClientSelected();
            } else {
                Toaster.addToast({
                    intent: 'danger',
                    text: props.clientSelectFailedLabel,
                });
            }
        });
    };

    return (
        <Overlay
            isOpen={includes(openOverlays, 'clientSelect')}
            doClose={closeLogin}
            layout="dialog"
            className="epharma-dialog"
            canEscapeKeyClose={false}
            customHeader={
                <div className="epharma-dialog-heading">
                    <h2>{props.welcomeLabel}</h2>
                    <p>
                        <RenderHTML html={props.clientCountLabel} nowrapper={true} />
                    </p>
                </div>
            }
        >
            <div className="epharma-dialog-content">
                <form action="#" className="mt10">
                    {clients.length >= 10 && (
                        <ControlInput
                            value={filterKeyword}
                            placeholder={props.clientSearchLabel}
                            onChange={(e) => {
                                filterClients(e.target.value);
                            }}
                        />
                    )}
                    <Buttons layout="vertical-wide" className="roles-selection">
                        {filteredClients &&
                            filteredClients.map((client, i) => (
                                <Button
                                    key={i}
                                    className="roles-selection__item"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        selectClient(client.id);
                                    }}
                                    title={
                                        <span className="roles-selection__item-inner">
                                            <span className="roles-selection__a">
                                                {client.name}
                                                <br />
                                                <small>{client.code}</small>
                                            </span>
                                            <span className="roles-selection__b">{props.selectLabel}</span>
                                        </span>
                                    }
                                />
                            ))}
                    </Buttons>
                </form>
            </div>
        </Overlay>
    );
};

export default ClientSelectOverlay;
