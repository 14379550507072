import { request } from 'data/requests/request';

const clientSelectRequest = (clientId: string) =>
    request({
        type: 'clientSelectRequest',
        url: 'clients/client/selectClient',
        method: 'POST',
        data: {
            clientId: clientId,
        },
        notApi: true,
    });

export default clientSelectRequest;
