import { request } from 'data/requests/request';

const authenticateStartRequest = () =>
    request({
        type: 'smartIdAuthenticateStartRequest',
        url: 'smartid/authenticate/start',
        method: 'POST',
        notApi: true,
    });

export default authenticateStartRequest;

export interface IAuthenticateStartResponse {
    verificationCode: string;
}
