import { request } from 'data/requests/request';

const authenticateRequest = (personalCode: string, sessionId?: string) =>
    request({
        type: 'smartIdAuthenticateRequest',
        url: 'smartid/authenticate/index',
        data: {
            personalCode,
            sessionId,
        },
        method: 'POST',
        notApi: true,
    });

export default authenticateRequest;

export interface IAuthenticateResponse {
    status: number;
    message: string;
    sessionId: string;
}
