import * as React from 'react';
import { formatPriceLocale } from '../../helpers/priceUtils';
import useCartSummary from '../../hooks/useCartSummary';
import { ICartProduct } from 'components/CartView/interfaces/CartInterfaces';

export interface ICartButtonProps {
    /* DOM to parse */
    dom: string;
    config: {
        href: string;
        src: string;
        label: string;
    };
}

const CartButton: React.FunctionComponent<ICartButtonProps> = (props) => {
    const { cartTotals, products } = useCartSummary();

    const totalCount = products.reduce((currentTotal, product) => product.quantity + currentTotal, 0);

    return (
        <a href={props.config.href || ''} className={'mini-cart active'}>
            <span className="icon">
                <img src={props.config.src || ''} alt={props.config.label || ''} />
                <span className="products">{totalCount}</span>
            </span>
            <span className="label">{props.config.label || ''}</span>
            {cartTotals && <span className="amount">{formatPriceLocale(cartTotals.grandTotal.value)}</span>}
        </a>
    );
};
CartButton.displayName = 'CartButton';
export default CartButton;
