import React, { useState } from 'react';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons/index';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import Img from 'components/Image/Img';

interface IProps {
    fullText: string;
    shortText?: string;
    labels: {
        readMoreLabel: string;
        readLessLabel: string;
    };
}

const TextTruncate: React.FunctionComponent<IProps> = (props) => {
    const {
        fullText,
        shortText,
        labels: { readMoreLabel, readLessLabel },
    } = props;
    const [isShortened, setIsShortened] = useState(!!shortText);
    const displayShowMoreButton = shortText && fullText.length > shortText.length;

    return (
        <React.Fragment>
            {displayShowMoreButton && (
                <React.Fragment>
                    <div className={'plain-html content'}>
                        {!isShortened && <RenderHTML html={fullText} nowrapper={true} />}
                        {isShortened && <RenderHTML html={shortText ?? ''} nowrapper={true} />}
                    </div>
                    <Buttons>
                        <Button
                            intent={'secondary-muted'}
                            size={'small'}
                            title={
                                <React.Fragment>
                                    <Img iconName={isShortened ? 'icon_arrow_down02' : 'icon_arrow_up02'} />
                                    {isShortened && readMoreLabel}
                                    {!isShortened && readLessLabel}
                                </React.Fragment>
                            }
                            onClick={() => {
                                setIsShortened(!isShortened);
                            }}
                        />
                    </Buttons>
                </React.Fragment>
            )}
            {!displayShowMoreButton && <RenderHTML html={fullText} nowrapper={true} />}
        </React.Fragment>
    );
};

export default TextTruncate;
