import { request } from 'data/requests/request';

export interface IMobileIdStartSignData {
    idCode: string;
    phoneNumber: string;
}

const mobileIdRequest = (data: IMobileIdStartSignData) =>
    request({
        type: 'mobileIdSignRequest',
        url: '/digidoc/document/mobileIdSign',
        method: 'POST',
        absolute: true,
        notApi: true,
        data,
    });

export default mobileIdRequest;
