import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import * as React from 'react';
import { useState } from 'react';
import AddToCartRequest, { IAddToCartRequestData } from 'components/Catalog/Product/requests/AddToCartRequest';
import { useMutation } from 'redux-query-react';
import { HttpStatusCode } from '../../../../enums/HttpStatusCode';
import Img from 'components/Image/Img';
import { closeOverlay, openOverlay } from 'data/actions/overlays';
import { useDispatch } from 'react-redux';

interface IProductActionsProps {
    sku: string;
    onSuccess: (message: string) => void;
    onError: (message: string) => void;
    isLoggedIn: boolean;
    quantityLabel: string;
    addToCartLabel: string;
    loginLabel: string;
    canAddToCart: boolean;
}

const ProductActions: React.FunctionComponent<IProductActionsProps> = (props) => {
    const { quantityLabel, addToCartLabel, sku, onSuccess, onError, isLoggedIn, loginLabel, canAddToCart } = props;
    const [{ isPending }, addToCartRequest] = useMutation((data: IAddToCartRequestData) => AddToCartRequest(data));
    const [qty, setQty] = useState<number>(1);
    const dispatch = useDispatch();

    const handleAddToCart = () => {
        void addToCartRequest({
            sku: sku,
            qty: qty,
        }).then((res) => {
            if (res.status === HttpStatusCode.CREATED) {
                onSuccess(res.body.message);
            } else {
                onError(res.body.message);
            }
        });
    };

    const openLogin = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'login' }));
    };

    return (
        <div className="product-actions">
            <div className="product-actions__row align-left">
                {isLoggedIn ? (
                    <>
                        {canAddToCart && (
                            <>
                                <div className="product-actions__quantity">
                                    <label>{quantityLabel}:</label>
                                    <input
                                        type="number"
                                        value={qty}
                                        aria-label={quantityLabel}
                                        onChange={(e) => {
                                            setQty(e.target.valueAsNumber);
                                        }}
                                    />
                                </div>
                                <Buttons>
                                    <Button
                                        intent={'primary'}
                                        onClick={handleAddToCart}
                                        loading={isPending}
                                        disabled={isPending}
                                        icon={'epharma-cart02'}
                                        title={addToCartLabel}
                                    />
                                </Buttons>
                            </>
                        )}
                    </>
                ) : (
                    <Buttons>
                        <button className="button button-discrete" onClick={openLogin}>
                            <span>
                                <span className="icon">
                                    <Img iconName="icon_lock" />
                                </span>
                                {loginLabel}
                            </span>
                        </button>
                    </Buttons>
                )}
            </div>
        </div>
    );
};

export default ProductActions;
