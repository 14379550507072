import React from 'react';
import { formatPriceLocale } from '../../../../helpers/priceUtils';
import { ICartTotal } from 'components/CartView/interfaces/CartInterfaces';

interface IProps {
    total: ICartTotal;
}

const CartSideBarRow: React.FunctionComponent<IProps> = (props) => {
    const { total } = props;

    return (
        <>
            {total.value !== 0 && (
                <li>
                    <span className="label">{total.label}:</span>
                    <span className="value">{formatPriceLocale(total.value, undefined, true)}</span>
                </li>
            )}
        </>
    );
};

export default CartSideBarRow;
