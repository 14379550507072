import { request } from 'data/requests/request';

const clientArchiveRequest = (clientCustomerId: string) =>
    request({
        type: 'clientArchiveRequest',
        url: 'archive/client/archive',
        method: 'POST',
        data: {
            clientCustomerId,
        },
        notApi: true,
    });

export default clientArchiveRequest;
