import React from 'react';
import HeadingWithButtons from 'ekaubamaja-ui/lib/Components/HeadingWithButtons';
import { IInvoice } from 'components/Customer/Client/clientOrders';
import SystemNotifications from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotifications';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import InvoiceRow from 'components/Customer/components/InvoiceRow';

interface IProps {
    config: {
        invoices: IInvoice[];
        labels: {
            latestOrders: string;
            noOrders: string;
            orderNumber: string;
            orderDate: string;
            orderCustomers: string;
            sum: string;
            reOrder: string;
            viewAll: string;
            invoiceStatus: string;
            awaitingFulfillment: string;
            invoiced: string;
            notPaid: string;
            paid: string;
            debt: string;
        };
    };
}

const LatestOrders: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { invoices, labels } = config;

    return (
        <section>
            <HeadingWithButtons title={labels.latestOrders} />
            <table className="data01">
                <thead>
                    <tr>
                        <th>{labels.orderNumber}</th>
                        <th>{labels.orderDate}</th>
                        <th>{labels.orderCustomers}</th>
                        <th>{labels.sum}</th>
                        <th>{labels.invoiceStatus}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {!!invoices.length &&
                        invoices.map((invoice) => (
                            <InvoiceRow key={invoice.orderNumber} invoice={invoice} labels={labels} />
                        ))}
                    {!invoices.length && (
                        <tr>
                            <td colSpan={3}>
                                <SystemNotifications inline compact>
                                    <SystemNotification intent="info">
                                        <RenderHTML html={labels.noOrders} nowrapper />
                                    </SystemNotification>
                                </SystemNotifications>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {!!invoices.length && (
                <p>
                    <RenderHTML html={labels.viewAll} nowrapper />
                </p>
            )}
        </section>
    );
};

export default LatestOrders;
