import { request } from 'data/requests/request';

export interface IIdCardStartSignData {
    signatureHex: string;
}

const IdCardRequest = (data: IIdCardStartSignData) =>
    request({
        type: 'idCardSignRequest',
        url: '/digidoc/document/idCardSignPrepare',
        method: 'POST',
        absolute: true,
        notApi: true,
        data,
    });

export default IdCardRequest;
