import React from 'react';
import { IShippingMethod } from 'components/Checkout/interfaces/IShippingMethod';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';

interface IProps {
    selectedShippingMethod: IShippingMethod;
    shippingAddressLabel: string;
}

const ShippingMethodAdditional: React.FunctionComponent<IProps> = (props) => {
    const { selectedShippingMethod, shippingAddressLabel } = props;
    return (
        <div className="longtext">
            {selectedShippingMethod.extra_data?.shipping_address && (
                <p>
                    <strong>{shippingAddressLabel}</strong>
                    <br />
                    {selectedShippingMethod.extra_data?.shipping_address}
                </p>
            )}
            {selectedShippingMethod.extra_data?.info_text && (
                <p>
                    <RenderHTML html={selectedShippingMethod.extra_data?.info_text} nowrapper={true} />
                </p>
            )}
        </div>
    );
};

export default ShippingMethodAdditional;
