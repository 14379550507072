import IProduct from 'components/Catalog/interfaces/IProduct';
import { IFilter, IFilterableAttribute, IFilterItem } from 'components/LayeredNavigation/LayeredNavigation';

export default class ProductFilterBuilder {
    filterableAttributes: IFilterableAttribute[];
    totalProductCount: number;

    constructor(filterableAttributes: IFilterableAttribute[]) {
        this.filterableAttributes = [...filterableAttributes];
        this.totalProductCount = 0;
    }

    buildFilters = (products: IProduct[]) => {
        const builtFilters: IFilter[] = [];

        if (!products.length) {
            return builtFilters;
        }

        this.totalProductCount = products.length;

        this.filterableAttributes.forEach((attribute) => {
            const items: IFilterItem[] = this.getFilterItems(attribute.code, products);
            if (items.length > 1 || attribute.code === 'price') {
                builtFilters.push({ attributeCode: attribute.code, label: attribute.label, items: items });
            }
        });
        return builtFilters;
    };

    getFilterItems = (attributeCode: string, products: IProduct[]): IFilterItem[] => {
        const filterItems: IFilterItem[] = [];

        switch (attributeCode) {
            case 'cat':
                products.forEach((product) => {
                    if (product.categories.length === 0) {
                        return;
                    }

                    product.categories.forEach((category) => {
                        if (!filterItems[category.id]) {
                            filterItems[category.id] = {
                                label: category.name,
                                value: category.id.toString(),
                                productCount: 0,
                            };
                        }
                        filterItems[category.id].productCount++;
                    });
                });
                break;
            case 'price':
                break;
            default:
                products.forEach((product) => {
                    const productFilterableAttributes = product.filterableAttributes;
                    const id = productFilterableAttributes[attributeCode]?.id;
                    const label = productFilterableAttributes[attributeCode]?.label;

                    if (id) {
                        if (!filterItems[id]) {
                            filterItems[id] = {
                                label: label,
                                value: id,
                                productCount: 0,
                            };
                        }
                        filterItems[id].productCount++;
                    }
                });
        }

        return filterItems.filter((item) => item && item.productCount !== this.totalProductCount);
    };
}
