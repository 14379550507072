import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import { domToReact, htmlToDOM } from 'html-react-parser';
import classNames from 'classnames';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import PortalPopover from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/PortalPopover/index';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import CustomerClientSwitcher from 'components/Customer/CustomerClientSwitcher';

/**
 * TODO uimove to ekaubamja-ui
 */
export interface IUserMenuProps {
    dom: string;
    config: {
        clientName: string;
        clientCode: string;
        labels: {
            switchClientLabel: string;
        };
    };
}

const UserMenu: React.FunctionComponent<IUserMenuProps> = (props) => {
    const { openOverlays } = useSelector(overlaysSelector);
    const dispatch = useDispatch();
    const { config } = props;

    const openUserMenu = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'user-menu-mobile' }));
    };

    const closeUserMenu = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };
    const toggleUserMenu = () => {
        const doc = document.documentElement;
        if (includes(openOverlays, 'user-menu-mobile')) {
            closeUserMenu();
            if (doc) {
                doc.classList.remove('mobilemenuopen');
            }
        } else {
            openUserMenu();
            if (doc) {
                doc.classList.add('mobilemenuopen');
            }
        }
        const evt = document.createEvent('UIEvents');
        evt.initEvent('resize', true, false);
        window.dispatchEvent(evt);
    };
    const target = (props.config?.target && document.getElementById(props.config?.target)) || document.body;

    // Main output
    const parserOptions = {
        replace: (domNode: any) => {
            if (domNode.name) {
                if (domNode.name === 'a' && !!domNode.next && !!domNode.next.children) {
                    const inner = domToReact(domNode.children, {});
                    const { class: className, ...rest } = domNode.attribs;
                    if (props.config?.mode && props.config.mode === 'collapse') {
                        return (
                            <PortalPopover
                                portalClassName={classNames('site-menu-portal', 'main-nav')}
                                popoverClassName={classNames('site-menu-popover')}
                                portalContainer={target}
                                onClose={closeUserMenu}
                                isOpen={includes(openOverlays, 'user-menu-mobile')}
                                content={
                                    <div className="user-menu-mobile-content">
                                        <ul className="user-menu-items">{domToReact(domNode.next.children, {})}</ul>
                                    </div>
                                }
                                target={
                                    <a
                                        {...rest}
                                        className={classNames(
                                            className,
                                            includes(openOverlays, 'user-menu-mobile') && 'active',
                                        )}
                                        onClick={toggleUserMenu}
                                    >
                                        {inner}
                                    </a>
                                }
                            />
                        );
                    } else {
                        return (
                            <React.Fragment>
                                <a href="#" className="toggler" onClick={openUserMenu}>
                                    {inner}
                                </a>
                                <Overlay
                                    isOpen={includes(openOverlays, 'user-menu-mobile')}
                                    doClose={closeUserMenu}
                                    layout="focusview"
                                    size="max"
                                    className="site-menu-mobile user-menu-mobile"
                                    customHeader={
                                        <div className="site-menu-mobile-heading right">
                                            <button onClick={closeUserMenu}>
                                                <Icon kind="close03" width={16} height={16} />
                                            </button>
                                        </div>
                                    }
                                >
                                    <div className="user-menu-mobile-content">
                                        <ul className="user-menu-items">
                                            {domToReact(domNode.next.children, {})}
                                            <li className="separated">
                                                <p className="client-name">
                                                    <span className="code">{config.clientCode}</span>
                                                    <br />
                                                    {config.clientName}
                                                </p>
                                                <CustomerClientSwitcher
                                                    config={{ switchClientLabel: config.labels.switchClientLabel }}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </Overlay>
                            </React.Fragment>
                        );
                    }
                } else {
                    return <></>;
                }
            }
            return false;
        },
    };

    // Main output

    const domTree = Array.isArray(props.dom) ? props.dom : htmlToDOM(props.dom.replace(/>\s+</g, '><'));

    return <React.Fragment>{domToReact(domTree, parserOptions)}</React.Fragment>;
    /*return (
        /!**!/
    );*/
};

UserMenu.displayName = 'UserMenu';

export default UserMenu;
