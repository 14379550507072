import * as React from 'react';

import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import { IStoreState } from 'data/reducers/root';
import { closeOverlay } from 'data/actions/overlays';

interface IProps {
    CartContent: any;
    orderDetails: string;
}

const CartOverlay = (props: IProps) => {
    const { CartContent, orderDetails } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const close = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    return (
        <Overlay
            isOpen={includes(openOverlays, 'summary')}
            layout="focusview"
            title={orderDetails}
            size="small"
            icon="cart"
            doClose={() => close()}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            buttonIcons={[
                {
                    icon: 'close',
                    intent: 'link',
                    onClick: () => close(),
                },
            ]}
        >
            {CartContent}
        </Overlay>
    );
};
export default CartOverlay;
