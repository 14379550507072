import * as React from 'react';

import Search from 'components/Catalog/Search/Search';
import Header from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/Header';
import AccessibleDrop from 'ekaubamaja-ui/lib/Components/AccessibleDrop/index';
import MainMenuWrapper from 'components/ui/MainMenuWrapper';
import { Title } from 'components/Page/Title';
import Tabs from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/Tabs/index';
import UserMenu from 'components/UserMenu';
import Carousel from 'ekaubamaja-ui/lib/Components/RenderCarousel/index';
import LoginButton from 'components/UserMenu/LoginButtonHeader';
import AuthenticationPopup from 'components/Customer/Auth/AuthenticationPopup';
import ClientSelection from 'components/Customer/Overlay/ClientSelection';
import CartButton from 'components/CartButton';
import ProductDetailView from 'components/Catalog/Product/ProductDetailView';
import LayeredNavigation from 'components/LayeredNavigation/LayeredNavigation';
import ProductList from 'components/Catalog/Product/ProductList';
import ForgotPassword from 'components/Customer/Form/ForgotPassword';
import ResetForgottenPassword from 'components/Customer/Form/ResetForgottenPassword';
import ManageAccounts from 'components/Customer/Client/manageAccounts';
import CartView from 'components/CartView/CartView';
import CartEventListeners from 'components/EventListeners/CartEventListeners';
import ProductSlider from 'components/CatalogWidget/ProductSlider';
import LoginArea from 'components/User/LoginArea';
import CustomerClientSwitcher from 'components/Customer/CustomerClientSwitcher';
import ProductCompareWidget from 'components/ProductCompare/ProductCompareWidget';
import DashBoardNotifications from 'components/Customer/SelfService/DashBoardNotifications';
import DashBoardSidebar from 'components/Customer/SelfService/DashBoardSidebar';
import CustomerAccountEditForm from 'components/Customer/SelfService/Form/Edit';
import Messages from 'components/Messages/Messages';
import ProductCompareList from 'components/ProductCompare/ProductCompareList';
import B2BClientAccountFormCreate from 'components/B2BClient/Account/Form/B2BClientAccountFormCreate';
import Checkout from 'components/Checkout/Checkout';
import ClientOrders from 'components/Customer/Client/clientOrders';
import B2BClientAccountFormRegister from 'components/B2BClient/Account/Form/B2BClientAccountFormRegister';
import OrderDetail from 'components/Customer/Client/orderDetail';
import OrderReorder from 'components/Customer/Client/orderReorder';
import LatestOrders from 'components/Customer/Client/latestOrders';
import ConsentHandler from 'components/Gdpr/ConsentHandler';
import ConsentPopup from 'components/Gdpr/ConsentPopup';
import CustomerLoginWrapper from 'components/Customer/Form/CustomerLoginWrapper';
import NewsletterForm from 'components/Newsletter/NewsletterForm';

const componentMapper = (
    maybeComponent: string,
    maybeContent: string,
    maybeConfig,
    ignoreTabs: string[] = [],
): React.Component => {
    return {
        menu: <MainMenuWrapper dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        accessibleDrop: <AccessibleDrop dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        b2bClientAccountCreate: <B2BClientAccountFormCreate config={maybeConfig} />,
        b2bClientAccountRegister: <B2BClientAccountFormRegister config={maybeConfig} />,
        cart: <CartButton dom={maybeContent} config={maybeConfig} />,
        cartView: <CartView config={maybeConfig} />,
        header: <Header dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        gdprConsentHandler: <ConsentHandler />,
        gdprConsentPopup: <ConsentPopup config={maybeConfig} />,
        userMenu: <UserMenu dom={maybeContent} config={maybeConfig} />,
        tabs: <Tabs dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        search: <Search dom={maybeContent} config={maybeConfig} />,
        title: <Title config={maybeConfig} />,
        carousel: <Carousel dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        loginButton: <LoginButton config={maybeConfig} />,
        layeredNavigationView: <LayeredNavigation config={maybeConfig} />,
        messages: <Messages config={maybeConfig} />,
        authenticationPopup: <AuthenticationPopup config={maybeConfig} />,
        product: <ProductDetailView dom={maybeContent} config={maybeConfig} />,
        productList: <ProductList config={maybeConfig} />,
        productSlider: <ProductSlider config={maybeConfig} />,
        productCompareWidget: <ProductCompareWidget config={maybeConfig} />,
        productListCompare: <ProductCompareList config={maybeConfig} />,
        clientSelection: <ClientSelection />,
        manageAccounts: <ManageAccounts config={maybeConfig} />,
        newsletterForm: <NewsletterForm config={maybeConfig} />,
        cartEventListeners: <CartEventListeners />,
        customerClientSwitcher: <CustomerClientSwitcher config={maybeConfig} />,
        loginArea: <LoginArea config={maybeConfig} />,
        dashboardNotifications: <DashBoardNotifications config={maybeConfig} />,
        dashboardSidebar: <DashBoardSidebar config={maybeConfig} />,
        customerLogin: <CustomerLoginWrapper config={maybeConfig} />,
        customerAccountEditForm: <CustomerAccountEditForm config={maybeConfig} />,
        customerForgotPasswordForm: <ForgotPassword config={maybeConfig} />,
        customerAccountResetForgottenPasswordForm: <ResetForgottenPassword config={maybeConfig} />,
        checkout: <Checkout config={maybeConfig} />,
        clientOrders: <ClientOrders config={maybeConfig} />,
        clientLatestOrders: <LatestOrders config={maybeConfig} />,
        orderDetail: <OrderDetail config={maybeConfig} />,
        orderReorder: <OrderReorder config={maybeConfig} />,
    }[(ignoreTabs.includes(maybeComponent) ? '' : maybeComponent) || ''];
};

export default componentMapper;
