import React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { includes } from 'lodash';
import { closeOverlay } from 'data/actions/overlays';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'data/reducers/root';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Block from 'ekaubamaja-ui/lib/Components/Block';

interface IProps {
    agreementContents: string;
    titleLabel: string;
    closeLabel: string;
}

const AgreementsOverlay = (props: IProps) => {
    const { agreementContents, titleLabel, closeLabel } = props;

    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const closeAgreements = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    return (
        <Overlay
            layout={'focusview'}
            isOpen={includes(openOverlays, 'agreements')}
            doClose={closeAgreements}
            size={'small'}
            title={titleLabel}
        >
            <Block>
                <RenderHTML html={agreementContents} nowrapper={true} />
                <Buttons layout="vertical-wide">
                    <Button intent="primary" layout="link" onClick={() => closeAgreements()} title={closeLabel} />
                </Buttons>
            </Block>
        </Overlay>
    );
};

export default AgreementsOverlay;
