import * as React from 'react';
import classNames from 'classnames';
import { getScrollParent, usePrevious } from 'ekaubamaja-ui/lib/Utilities/Generic';
import { scrollIntoView } from 'ekaubamaja-ui/lib/Utilities/ScrollTo';
import { ISearchResultItemType } from 'components/Catalog/Search/Results';
import { formatPriceLocale } from '../../../helpers/priceUtils';

export interface ISearchResultProps {
    /** Product name */
    name: string;
    /** Product price */
    price?: number;
    /** Product special price */
    specialPrice?: number;
    /** href to product */
    href: string;
    /* Product image */
    imageUrl: string;
    /** Highlight as active */
    active?: boolean;
    type: {
        code: ISearchResultItemType;
        label: string;
    };
    categories?: string[];
}

const Result: React.FunctionComponent<ISearchResultProps> = (props) => {
    const prevActive = usePrevious(props.active);
    const domElem = React.useRef(null);

    // If active then try to scroll into view

    React.useEffect(() => {
        if (props.active && !prevActive) {
            const d = domElem.current as unknown as HTMLElement;
            if (d) {
                const domElemParent = getScrollParent(d);
                scrollIntoView(d, domElemParent, {});
            }
        }
    }, [props.active]);

    return (
        <li className="search__results__result" ref={domElem}>
            <a href={props.href} className={classNames({ active: props.active })}>
                {props.imageUrl && <div className="image" style={{ backgroundImage: 'url(' + props.imageUrl + ')' }} />}
                <div className="text">
                    <div className="name">
                        {props.name}
                        {props.type.code === 'category' && props.categories && (
                            <React.Fragment>
                                <br />
                                <small>
                                    {props.categories.map((category) => {
                                        return <span className="secondary">{category}</span>;
                                    })}
                                </small>
                            </React.Fragment>
                        )}
                    </div>
                    {props.type.code !== 'product' && props.type.label && (
                        <div className="addOn">{props.type.label}</div>
                    )}
                    {props.type.code === 'product' && props.price && (
                        <div className="price">
                            {!!props.specialPrice && (
                                <>
                                    <del>{formatPriceLocale(props.price)}</del>
                                    <strong>{formatPriceLocale(props.specialPrice)}</strong>
                                </>
                            )}
                            {!props.specialPrice && formatPriceLocale(props.price)}
                        </div>
                    )}
                </div>
            </a>
        </li>
    );
};

export default Result;
