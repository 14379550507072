import React from 'react';
import { IClientManagerInfo } from 'components/Customer/SelfService/DashBoardSidebar';

interface IProps {
    clientManager: IClientManagerInfo;
    clientManagerLabel: string;
}

const ClientManagerInfo: React.FunctionComponent<IProps> = (props) => {
    const { clientManager, clientManagerLabel } = props;
    return (
        <div className="content-card margt dark">
            <h2>{clientManagerLabel}</h2>
            <div className="profile-card">
                <div className="profile-card__text">
                    <h3 className="profile-card__title">{clientManager.name}</h3>
                    <p>{clientManager.phoneNumber}</p>
                    <p>
                        <a href={`mailto:${clientManager.email}`}>{clientManager.email}</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ClientManagerInfo;
