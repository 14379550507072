import React from 'react';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import classNames from 'classnames';

interface IProps {
    priceValue: number;
    type?: 'special';
}

const CartProductPrice: React.FunctionComponent<IProps> = (props) => {
    const { priceValue, type } = props;

    return (
        <div className={classNames('product-pricing__price', type)}>
            <p>
                <span className="product-pricing__price-value">
                    <span className="product-pricing__price-number">{formatPriceLocale(priceValue)}</span>
                </span>
            </p>
        </div>
    );
};

export default CartProductPrice;
