import React from 'react';
import CartProductPrice from 'components/CartView/components/CartProductPrice';
import { isNumber } from 'lodash';

interface IProps {
    price: number;
    specialPrice?: number;
}

const CartProductPricing: React.FunctionComponent<IProps> = (props) => {
    const { price, specialPrice } = props;

    return (
        <div className="box-product__prices">
            <div className="box-product__pricing">
                {isNumber(specialPrice) ? (
                    <CartProductPrice priceValue={specialPrice} type="special" />
                ) : (
                    <CartProductPrice priceValue={price} />
                )}
            </div>
        </div>
    );
};

export default CartProductPricing;
