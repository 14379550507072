import { request } from 'data/requests/request';

const customerAuthStatusRequest = () =>
    request({
        type: 'customerAuthStatusRequest',
        url: 'customer/ajax/authstatus',
        method: 'GET',
        notApi: true,
        absolute: false,
    });

export default customerAuthStatusRequest;

export interface ICustomerAuthStatusResponse {
    isLoggedIn: boolean;
    customer?: {
        id: number;
        name: string;
        email: string;
    };
}
