import { request } from 'data/requests/request';

const loginRequest = (username: string, password: string) =>
    request({
        type: 'loginRequest',
        url: 'customer/ajax/login',
        data: {
            username,
            password,
        },
        method: 'POST',
        notApi: true,
    });

export default loginRequest;
