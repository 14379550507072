export default function validatePasswordMinCharacterSets(password: string, requiredCharSets: number): boolean {
    let counter = 0;

    if (/\d+/.exec(password)) {
        counter++;
    }

    if (/[a-z]+/.exec(password)) {
        counter++;
    }

    if (/[A-Z]+/.exec(password)) {
        counter++;
    }

    if (/[^a-zA-Z0-9]+/.exec(password)) {
        counter++;
    }

    return counter >= requiredCharSets;
}
