import * as React from 'react';
import RenderGallery from 'ekaubamaja-ui/lib/Components/RenderGallery';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import { useEffect, useState } from 'react';
import ProductPrice from 'components/Catalog/Product/components/ProductPrice';
import ProductActions from 'components/Catalog/Product/components/ProductActions';
import ProductMetaAttributes from 'components/Catalog/Product/components/ProductMetaAttributes';
import MessageManager, { IMessage } from 'components/ui/MessageManager';
import useAuth from '../../../hooks/useAuth';
import TextTruncate from 'components/Typography/TextTruncate';
import formDataRequest from 'data/requests/formDataRequest';
import useProductCompareList from 'components/ProductCompare/hooks/useProductCompareList';
import IProduct from 'components/Catalog/interfaces/IProduct';
import ProductAttributes from 'components/Catalog/Product/components/ProductAttributes';
import ProductBadges from 'components/Catalog/Product/DetailView/ProductBadges';
import ProductLabels from 'components/Catalog/Product/DetailView/ProductLabels';
import ProductCampaign from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/ProductCampaign/index';
import OutOfStockButton from 'components/Catalog/Product/components/OutOfStockButton';
import ProductStockAlert from 'components/Catalog/Product/DetailView/ProductStockAlert';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML/index';
import SystemNotifications from 'ekaubamaja-ui/lib/Components/SystemNotifications';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification/index';

interface IProductDetailViewProps {
    config: IProductDetailConfig;
    dom: string;
}

interface IProductDetailConfig {
    product: IProduct;
    productCompareUrl: string;
    labels: {
        productDescriptionLabel: string;
        priceAsLowAsLabel: string;
        limitedProductsLabel: string;
        quantityLabel: string;
        addToCartLabel: string;
        notifyInStockLabel: string;
        hasNotificationLabel: string;
        productCompareLabel: string;
        productAddedToCompareLabel: string;
        medicineInformationPdfLabel: string;
        closeLabel: string;
        productCompareAddSuccessLabel: string;
        productCompareAddFailureLabel: string;
        productOutOfStockLabel: string;
        loginLabel: string;
        readMoreLabel: string;
        readLessLabel: string;
        warning: string;
        warningText: string;
        unregisteredCascadeWarning: string;
    };
}

export interface ProductMetaAttribute {
    label: string;
    value: string;
}

const ProductDetailView: React.FunctionComponent<IProductDetailViewProps> = (props) => {
    const { dom, config } = props;
    const { product, labels } = config;

    const { isLoggedIn } = useAuth();
    const { isFinished: productCompareLoaded, reloadProductCompareList, productCompareList } = useProductCompareList();
    const [isProductCompared, setIsProductCompared] = useState(false);
    const [messages, setMessages] = useState<IMessage[]>([]);

    const parser = new DOMParser();
    const doc = parser.parseFromString(dom, 'text/html');
    const [gallery] = useState<string | undefined>(doc.querySelector('[data-component="gallery"]')?.innerHTML);

    const warningLabel = product.isAllowedToSell ? labels.warningText : labels.unregisteredCascadeWarning;

    const onProductAddSuccess = (message: string) => {
        window.dispatchEvent(new CustomEvent('cart-altered'));
        setMessages([
            {
                intent: 'success',
                closable: true,
                content: message,
            },
        ]);
    };

    const onProductAddError = (message: string) => {
        setMessages([
            {
                intent: 'danger',
                closable: true,
                content: message,
            },
        ]);
    };

    const onProductAddToCompare = () => {
        formDataRequest(
            '/catalog/product_compare/add',
            { form_key: window.form_key, product: product.id, isAjax: true },
            (error) => {
                if (error) {
                    setIsProductCompared(false);
                    setMessages([
                        {
                            intent: 'danger',
                            closable: true,
                            content: error,
                        },
                    ]);
                } else {
                    setIsProductCompared(true);
                    setMessages([
                        {
                            intent: 'success',
                            closable: true,
                            content: labels.productCompareAddSuccessLabel
                                .replace('%1', product.name)
                                .replace('%2', config.productCompareUrl),
                        },
                    ]);
                }
            },
        );

        window.setTimeout(reloadProductCompareList, 250);
    };

    useEffect(() => {
        if (productCompareLoaded) {
            setIsProductCompared(() =>
                productCompareList.products.some((comparedProduct) => {
                    return comparedProduct.id === product.id;
                }),
            );
        }
    }, [productCompareLoaded, productCompareList]);

    return (
        <>
            <div className="layout-product__title">
                <h1>{product.name}</h1>
            </div>
            <p className="product-meta">{product.attributeSetLabel}</p>
            <div className="layout-product__row wrap-narrow">
                <div className="layout-product__image">
                    <div className="product__badges">
                        {!!product.labels.length && <ProductLabels labels={product.labels} />}
                        {!!product.badges.length && <ProductBadges badges={product.badges} />}
                    </div>
                    <div className="product-image">
                        <RenderGallery
                            dom={gallery}
                            config={{
                                showThumbs: true,
                                showArrows: true,
                                showIndicators: false,
                                hasOverlay: true,
                            }}
                        ></RenderGallery>
                    </div>
                    <div className="mobile-hide tablet-hide">
                        <ProductAttributes productAttributes={product.productAttributes} />
                    </div>
                </div>
                <div className="layout-product__content">
                    {product.isUnregisteredMedicine && (
                        <SystemNotifications compact={true}>
                            <SystemNotification intent="info">
                                <RenderHTML html={warningLabel} nowrapper={true} />
                            </SystemNotification>
                        </SystemNotifications>
                    )}

                    <ProductMetaAttributes packageType={product.packageType} meta={product.metaAttributes} />
                    {product.isSaleable && product.isAllowedToSell && (
                        <React.Fragment>
                            {isLoggedIn && product.showPrice && (
                                <ProductPrice
                                    priceAsLowAsLabel={
                                        product.typeId === 'configurable' ? labels.priceAsLowAsLabel : undefined
                                    }
                                    finalPrice={product.specialPrice ?? product.price}
                                    price={product.originalPrice}
                                    productUnit={product.productUnit}
                                    pricePerUnit={product.pricePerUnit}
                                />
                            )}
                            <ProductActions
                                sku={product.sku}
                                onSuccess={onProductAddSuccess}
                                onError={onProductAddError}
                                quantityLabel={labels.quantityLabel}
                                addToCartLabel={labels.addToCartLabel}
                                loginLabel={labels.loginLabel}
                                isLoggedIn={!!isLoggedIn}
                                canAddToCart={product.canAddToCart}
                            />
                            <div className="desktop-hide">
                                <ProductAttributes productAttributes={product.productAttributes} />
                            </div>
                        </React.Fragment>
                    )}
                    {!product.isSaleable && product.isAllowedToSell && (
                        <OutOfStockButton outOfStockLabel={labels.productOutOfStockLabel} />
                    )}
                    <MessageManager messages={messages} labels={{ close: labels.closeLabel }} />
                    <Buttons>
                        <ProductStockAlert
                            product={product}
                            notifyInStockLabel={labels.notifyInStockLabel}
                            alreadyHasStockNotificationLabel={labels.hasNotificationLabel}
                        />
                        {isLoggedIn && !isProductCompared && (
                            <Button
                                intent={'secondary-muted'}
                                className="mobile-hide"
                                iconClassName="theme02"
                                size={'small'}
                                icon={'epharma-compare'}
                                title={labels.productCompareLabel}
                                onClick={onProductAddToCompare}
                            />
                        )}
                        {isLoggedIn && isProductCompared && (
                            <span className="button button-discrete size-small">
                                <Icon width={16} height={16} kind={'epharma-check02'} />
                                {labels.productAddedToCompareLabel}
                            </span>
                        )}
                    </Buttons>
                    {product.campaignTexts && product.campaignTexts?.length > 0 && (
                        <>
                            {product.campaignTexts.map((campaignText, i) => (
                                <ProductCampaign theme={'theme08'}>
                                    <p key={i}>{campaignText}</p>
                                </ProductCampaign>
                            ))}
                        </>
                    )}
                    {product.showDescription && (
                        <>
                            <h2>{labels.productDescriptionLabel}</h2>
                            <TextTruncate
                                fullText={product.description}
                                shortText={product.shortDescription}
                                labels={{ readMoreLabel: labels.readMoreLabel, readLessLabel: labels.readLessLabel }}
                            />
                        </>
                    )}
                    {product.pdfUrl && (
                        <Button
                            layout="link"
                            type="anchor"
                            intent="tertiary"
                            onClick={() => window.open(product.pdfUrl, '_blank')}
                            icon="epharma-infosheet"
                            title={labels.medicineInformationPdfLabel}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ProductDetailView;
