import { Dispatch } from 'redux';

import { dispatchType } from '../actionTypes';
import { OverlayDispatchAction, OverlaysState } from '../interfaces';

// Actions

interface IOverlaysOpenAction {
    type: dispatchType.OVERLAYS_OPEN;
    payload: OverlayDispatchAction;
}

function overlayOpenSuccess(payload: OverlayDispatchAction): IOverlaysOpenAction {
    return {
        type: dispatchType.OVERLAYS_OPEN,
        payload,
    };
}

interface IOverlaysCloseAction {
    type: dispatchType.OVERLAYS_CLOSE;
    payload: OverlayDispatchAction;
}

function overlayCloseSuccess(payload: OverlayDispatchAction): IOverlaysCloseAction {
    return {
        type: dispatchType.OVERLAYS_CLOSE,
        payload,
    };
}

export type OverlaysActions = IOverlaysOpenAction | IOverlaysCloseAction;

// Action Creators

export interface IOverlaysActions {
    openOverlay: (payload: OverlayDispatchAction) => void;
    closeOverlay: (payload: OverlayDispatchAction) => void;
}

export function openOverlay(payload: OverlayDispatchAction) {
    return (dispatch: Dispatch) => {
        return dispatch(overlayOpenSuccess(payload));
    };
}

export function closeOverlay(payload: OverlayDispatchAction) {
    return (dispatch: Dispatch) => {
        return dispatch(overlayCloseSuccess(payload));
    };
}
