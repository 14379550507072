import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import SystemNotifications from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotifications/index';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification/index';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML/index';

interface ICookieMessage {
    type: string;
    text: string;
}

interface INotificationProps {
    intent: 'success' | 'warn' | 'danger' | 'info' | 'discrete';
    icon: string;
}

interface IProps {
    config: {
        messages: ICookieMessage[];
    };
}

const Messages: React.FunctionComponent<IProps> = (props: IProps) => {
    const { config } = props;
    const cookieMessages = Cookies.get('mage-messages');
    const [messages, setMessages] = useState<ICookieMessage[]>(config.messages);
    Cookies.set('mage-messages', []);

    useEffect(() => {
        const inputMessages: ICookieMessage[] = [...messages];

        if (cookieMessages) {
            const mageMessages = JSON.parse(cookieMessages);

            mageMessages.forEach((message) => inputMessages.push(message));
        }

        setMessages(inputMessages);
    }, []);

    const notificationPropsByMessage = (message: ICookieMessage): INotificationProps => {
        const notificationProps: INotificationProps = {
            intent: 'success',
            icon: 'icon_check',
        };

        switch (message.type) {
            case 'error':
                notificationProps.intent = 'danger';
                notificationProps.icon = 'icon_error';
                break;
            case 'notice':
            case 'warning':
                notificationProps.intent = 'warn';
                notificationProps.icon = 'icon_warn2';
                break;
        }

        return notificationProps;
    };

    return (
        <React.Fragment>
            {!!messages.length && (
                <SystemNotifications theme="full">
                    {messages.map((message, key) => {
                        const notificationProps = notificationPropsByMessage(message);

                        return (
                            <SystemNotification
                                intent={notificationProps.intent}
                                icon={{
                                    file: `${window.assetUrl}/images/${notificationProps.icon}.svg`,
                                    size: 'small',
                                }}
                                key={key}
                            >
                                <RenderHTML html={message.text} nowrapper={true} />
                            </SystemNotification>
                        );
                    })}
                </SystemNotifications>
            )}
        </React.Fragment>
    );
};

export default Messages;
