import * as React from 'react';

interface IProps {
    iconName: string;
    height?: string;
    className?: string;
    alt?: string;
}

const Img = (props: IProps): JSX.Element => {
    const { iconName, className, alt, height } = props;
    return <img src={`${window.assetUrl}/images/${iconName}.svg`} className={className} alt={alt} height={height} />;
};
export default Img;
