import React from 'react';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { useRequest } from 'redux-query-react';
import generateLinkRequest, { ICartShareGenerateLinkResponse } from 'components/CartShare/requests/GenerateLinkRequest';
import { useSelector } from 'react-redux';

interface IProps {
    generateLinkUrl: string;
    labels: {
        copyLink: string;
    };
}

const mapStateToProps = (state) => {
    return {
        cartShare: state.entities.cartShareGenerateLinkRequest as ICartShareGenerateLinkResponse,
    };
};

const CartShareLinkInput: React.FunctionComponent<IProps> = (props) => {
    const { generateLinkUrl, labels } = props;
    const [{ isFinished }] = useRequest(generateLinkRequest(generateLinkUrl));
    const { cartShare } = useSelector(mapStateToProps);

    const copyLink = (shareLink: string) => {
        navigator.clipboard.writeText(shareLink);
    };

    return (
        <>
            {isFinished && cartShare && cartShare.shareLink && (
                <>
                    <ControlInput value={cartShare.shareLink} className="form-control-url" disabled />
                    <Buttons>
                        <Button
                            intent="secondary-muted"
                            size="small"
                            icon="link"
                            title={labels.copyLink}
                            onClick={() => copyLink(cartShare.shareLink)}
                        />
                    </Buttons>
                </>
            )}
        </>
    );
};

export default CartShareLinkInput;
