import * as React from 'react';
import Img from 'components/Image/Img';

interface IProps {
    productAttributes: IProductAttribute[];
}

export interface IProductAttribute {
    label: string;
    value: string;
    iconUrl: string;
}

const ProductAttributes: React.FunctionComponent<IProps> = (props) => {
    const { productAttributes } = props;

    return (
        <dl className="product-attributes">
            {productAttributes.map((attribute, i) => {
                return attribute.label && attribute.value ? (
                    <div key={i}>
                        <dt>
                            <img src={attribute.iconUrl} />
                            {attribute.label}
                        </dt>
                        <dd>{attribute.value}</dd>
                    </div>
                ) : (
                    <></>
                );
            })}
        </dl>
    );
};

export default ProductAttributes;
